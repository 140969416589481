import {
  Row,
  Card,
  Table,
  Tooltip,
  Tag,
  Button,
  notification,
  Space,
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  StopOutlined,
  PlayCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import classes from "./Etablissements.module.css";
import { useContext, useState, useEffect } from "react";
import SelectionModal from "./SelectionModal/SelectionModal";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import CloseModal from "./CloseModal/CloseModal";
import { useTranslation } from "react-i18next";
import { getAffaires } from "../../api/geoprod";

const Etablissements = () => {
  const navigate = useNavigate();
  const { setBrouillon } = useContext(GlobalContext);
  const [stopModal, setStopModal] = useState(false);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: t("Dénomination"),
      key: "dénomination",
      render: (_, record) =>
        record.data_form?.legal_entity_official_name ||
        record.data_form?.establishment_data_trade_name,
    },
    {
      title: t("Directeur"),
      dataIndex: "directeur",
      key: "directeur",
      render: (_, record) =>
        record.data_form.establishment_director_data_last_name +
        " " +
        record.data_form.establishment_director_data_first_name,
    },
    {
      title: t("Gouvernorat"),
      key: "gouvernorat",
      render: (_, record) => record.data_form.investor_residence_governorate,
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
      render: (_, record) => record.data_form.investor_residence_delegation,
    },
    {
      title: t("Matricule fiscale"),
      key: "matricule",
      render: (_, record) =>
        record.data_form.establishment_data_tax_number || "non renseigné",
    },
    {
      title: t("Numéro de téléphone"),
      key: "phoneNum",
      render: (_, record) => record.data_form.investor_phone_number,
    },
    {
      title: t("Nature de formation"),
      key: "natureForm",
      render: (_, record) => record.data_form.establishment_data_training_type,
    },
    {
      title: t("Statut"),
      key: "statut",
      render: (_, record) => {
        return (
          <Tag style={{ cursor: "pointer" }} color={record?.etat_background}>
            {record?.etat}
          </Tag>
        );
      },
    },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
      
          <Tooltip title={t("Modifier")}>
            <EditOutlined
              onClick={() => {
                console.log(record);

                navigate(`/etablissements/edit`);
                sessionStorage.setItem(
                  "surveyData",
                  JSON.stringify(record.data_form)
                );
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAffaires({
          id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
          id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .user.entreprise_id,
        });
        const temp = data?.aaData?.map((item, index) => {
          return {
            ...item,
            code: "EPF" + String(index + 1).padStart(3, "0"),
            data_form: JSON.parse(item.data_form),
          };
        });


        setData(temp.filter((elem) => elem.etat === "Validé"));
        let brouillon = temp.filter((elem) => elem.etat === "Brouillon");
        if (brouillon.length>0) {
          sessionStorage.setItem("id_affaire", brouillon[0]?.affaire_id);
          sessionStorage.setItem("surveyData",JSON.stringify(brouillon[0]?.data_form))
          setBrouillon(brouillon);
          // navigate("/etablissements/save")
          setLoading(false);

        }
        setLoading(false);


      } catch (error) {
        // notification.error({
        //   message: t("Error !"),
        //   description:
        //     error?.response?.data?.message ||
        //     "Une erreur s'est produite lors de la récupération des données",
        // });
      }
    }

    fetchData();
  }, []);

  const [isModalVisible, setIsModalVisible] = useState();

  const openModalSelection = () => {
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div>
      <CloseModal stopModal={stopModal} setStopModal={setStopModal} />

      <SelectionModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <Row className={classes.rowButtonCreate}>
        <Button className={classes.buttonCreate} onClick={openModalSelection}>
          <PlusOutlined />
          {t("Créer un nouvel EPF")}
        </Button>
      </Row>

      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={t("Liste de mes établissements")}
        >
          <Table
            locale={{ emptyText: t("Aucun établissement.") }}
            loading={loading}
            columns={columns}
            dataSource={data}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={false}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Etablissements;
