import { Menu } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import sigaf from "../../assets/images/logo-CIGAF-FINAL.png";
import {
  CloseOutlined,
  HomeOutlined,
  LogoutOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import GlobalContext from "../../context/GlobalContext";
import {useContext} from "react"
function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { t } = useTranslation();
  const role = sessionStorage.getItem("role");
  const navigate = useNavigate();
  const {brouillon}=useContext(GlobalContext)
  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const billing = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];
  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <img src={sigaf} style={{ width: "8rem" }} />

      <div
        className="brand"
        style={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        <h3>
          {role !== "formateur"
            ? t("Espace investisseur")
            : t("Espace formateur")}
        </h3>
      </div>
      <hr />
      {role !== "formateur" ? (
        <Menu theme="light" mode="inline">
          <Menu.Item key="1">
            <NavLink
              to={
                brouillon?.length>0
                  ? "/etablissements/save"
                  : "/etablissements"
              }
            >
              <span
                className="icon"
                style={{
                  background: page === "tables" ? color : "",
                }}
              >
                {tables}
              </span>
              <span className="label">{t("Mes établissements")}</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="2">
            <NavLink to="/demandes">
              <span
                className="icon"
                style={{
                  background: page === "billing" ? color : "",
                }}
              >
                {billing}
              </span>
              <span className="label">{t("Mes demandes")}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="3">
            <NavLink to="/brouillon">
              <span
                className="icon"
                style={{
                  background: page === "billing" ? color : "",
                }}
              >
                {billing}
              </span>
              <span className="label">{t("Brouillon")}</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="4">
            <NavLink to="/formateurs">
              <span
                className="icon"
                style={{
                  background: page === "rtl" ? color : "",
                }}
              >
                <TeamOutlined />
              </span>
              <span className="label">{t("Liste des formateurs")}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={() => {
              localStorage.clear();
              sessionStorage.clear();
              navigate("/signin?role=investisseur");
            }}
          >
            <NavLink to="/signin?role=investisseur">
              <span
                className="icon"
                style={{
                  background: page === "billing" ? color : "",
                }}
              >
                <LogoutOutlined />
              </span>
              <span className="label">{t("Se déconnecter")}</span>
            </NavLink>
          </Menu.Item>
        </Menu>
      ) : (
        <div
          className="sider-menu-container"
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Menu style={{ flex: 1 }}>
            <Menu.Item key="1">
              <NavLink to="/acceuil">
                <span
                  className="icon"
                  style={{
                    background: page === "billing" ? color : "",
                  }}
                >
                  <HomeOutlined />
                </span>
                <span className="label">{t("Acceuil")}</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="2">
              <NavLink to="/profile">
                <span
                  className="icon"
                  style={{
                    background: page === "billing" ? color : "",
                  }}
                >
                  <UserOutlined />
                </span>
                <span className="label">{t("Mon profil")}</span>
              </NavLink>
            </Menu.Item>
            {/* </Menu>
        <Menu> */}
            <Menu.Item
              key="3"
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
                navigate("/signin?role=formateur");
              }}
            >
              <NavLink to="/signin?role=formateur">
                <span
                  className="icon"
                  style={{
                    background: page === "billing" ? color : "",
                  }}
                >
                  <LogoutOutlined />
                </span>
                <span className="label">{t("Se déconnecter")}</span>
              </NavLink>
            </Menu.Item>
          </Menu>
        </div>
      )}
    </div>
  );
}

export default Sidenav;
