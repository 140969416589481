import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './i18n';
import { ConfigProvider } from "antd";
import * as Sentry from "@sentry/react";
import PackageJson from "../package.json"

ReactDOM.render(
  // <BrowserRouter>
  <ConfigProvider
  theme={{
    token: {
      colorPrimary: "#024FA8",
    },
    components: {
     
      Radio: {
        colorPrimary: "#21A8A8",
      },
      
      Modal: {
        colorBgMask: "rgba(2, 79, 168, 0.4)",
      },
      Collapse:{
        headerBg:"#EEF2FB",
        contentBg:"#EEF2FB"
      },
      Select:{
        controlHeight:40
      },
      DatePicker:{
        controlHeight:40

      }
    },
  }}
   >
    <App />
    </ConfigProvider>,
  // </BrowserRouter>,
  document.getElementById("root"),
);
Sentry.init({
    dsn: "https://d4d3187f63fc6868ee56bca8c2f65d4a@sentry.neopolis-dev.com/82",
    tracesSampleRate: 1.0,
    release:PackageJson?.version
  });
