import { useContext, useEffect, useState } from "react";
import GlobalContext from "../../../context/GlobalContext";
import { Model, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import {
  Button,
  Card,
  Collapse,
  List,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Upload,
} from "antd";
import {
  ExclamationCircleOutlined,
  FolderOutlined,
  MoreOutlined,
  SaveOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import classes from "./CreateEpf.module.css";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import "survey-core/i18n/french";

// For auto tranlsating the error messages.
// import "survey-core/survey.i18n";
import { checkCitizenStatusByCinDn } from "../../../api/extern";
import dayjs from "dayjs";
import axios from "axios";
import {
  addAffaire,
  getTrainerByNationalityAndIdentity,
  saveAffaire,
  uploadDocumentAffaire,
} from "../../../api/geoprod";
import PdfViewer from "../../PdfViewer";
import i18next from "i18next";
// import { Document, Page, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const { Panel } = Collapse;

const CreateEpf = (props) => {
  const { i18n } = useTranslation();
  const [user, setUser] = useState(
    JSON.parse(sessionStorage.getItem("-x-token-user"))
  );
  const { survey } = props;
  const [defaultValues, setDefaultValues] = useState({});
  // const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const {
    gouvernorats,
    pageN,
    porteDoc,
    setLoader,
    sousSecteur,
    spetialite_diplome,
    codes_communes,
  } = useContext(GlobalContext);
  const [fileList, setFileList] = useState([]);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const themeJson = {
    themeName: "default",
    colorPalette: "light",
    isPanelless: false,
    backgroundImage: "",
    backgroundOpacity: 1,
    backgroundImageAttachment: "scroll",
    backgroundImageFit: "cover",
    cssVariables: {
      "--sjs-corner-radius": "4px",
      "--sjs-base-unit": "8px",
      "--sjs-shadow-small": "0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
      "--sjs-font-questiontitle-weight": "400",
      "--sjs-font-questiontitle-size": "15px",
      "--sjs-font-questiondescription-size": "18px",
      "--sjs-shadow-inner": "inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)",

      "--sjs-border-default": "rgba(0, 0, 0, 0.16)",
      "--sjs-border-light": "rgba(0, 0, 0, 0.09)",
      "--sjs-general-backcolor": "rgba(255, 255, 255, 1)",
      "--sjs-general-backcolor-dark": "rgba(248, 248, 248, 1)",
      "--sjs-general-backcolor-dim-light": "rgba(249, 249, 249, 1)",
      "--sjs-general-backcolor-dim-dark": "rgba(243, 243, 243, 1)",
      "--sjs-general-forecolor": "rgba(0, 0, 0, 0.91)",
      "--sjs-general-forecolor-light": "rgba(0, 0, 0, 0.45)",
      "--sjs-general-dim-forecolor": "rgba(0, 0, 0, 0.91)",
      "--sjs-general-dim-forecolor-light": "rgba(0, 0, 0, 0.45)",
      "--sjs-secondary-backcolor": "rgba(255, 152, 20, 1)",
      "--sjs-secondary-backcolor-light": "rgba(255, 152, 20, 0.1)",
      "--sjs-secondary-backcolor-semi-light": "rgba(255, 152, 20, 0.25)",
      "--sjs-secondary-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-secondary-forecolor-light": "rgba(255, 255, 255, 0.25)",
      "--sjs-shadow-small-reset": "0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
      "--sjs-shadow-medium": "0px 2px 6px 0px rgba(0, 0, 0, 0.1)",
      "--sjs-shadow-large": "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
      "--sjs-shadow-inner-reset": "inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15)",
      "--sjs-border-inside": "rgba(0, 0, 0, 0.16)",
      "--sjs-special-red-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-green": "rgba(25, 179, 148, 1)",
      "--sjs-special-green-light": "rgba(25, 179, 148, 0.1)",
      "--sjs-special-green-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-blue": "rgba(67, 127, 217, 1)",
      "--sjs-special-blue-light": "rgba(67, 127, 217, 0.1)",
      "--sjs-special-blue-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-special-yellow": "rgba(255, 152, 20, 1)",
      "--sjs-special-yellow-light": "rgba(255, 152, 20, 0.1)",
      "--sjs-special-yellow-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-article-font-xx-large-textDecoration": "none",
      "--sjs-article-font-xx-large-fontWeight": "700",
      "--sjs-article-font-xx-large-fontStyle": "normal",
      "--sjs-article-font-xx-large-fontStretch": "normal",
      "--sjs-article-font-xx-large-letterSpacing": "0",
      "--sjs-article-font-xx-large-lineHeight": "64px",
      "--sjs-article-font-xx-large-paragraphIndent": "0px",
      "--sjs-article-font-xx-large-textCase": "none",
      "--sjs-article-font-x-large-textDecoration": "none",
      "--sjs-article-font-x-large-fontWeight": "700",
      "--sjs-article-font-x-large-fontStyle": "normal",
      "--sjs-article-font-x-large-fontStretch": "normal",
      "--sjs-article-font-x-large-letterSpacing": "0",
      "--sjs-article-font-x-large-lineHeight": "56px",
      "--sjs-article-font-x-large-paragraphIndent": "0px",
      "--sjs-article-font-x-large-textCase": "none",
      "--sjs-article-font-large-textDecoration": "none",
      "--sjs-article-font-large-fontWeight": "700",
      "--sjs-article-font-large-fontStyle": "normal",
      "--sjs-article-font-large-fontStretch": "normal",
      "--sjs-article-font-large-letterSpacing": "0",
      "--sjs-article-font-large-lineHeight": "40px",
      "--sjs-article-font-large-paragraphIndent": "0px",
      "--sjs-article-font-large-textCase": "none",
      "--sjs-article-font-medium-textDecoration": "none",
      "--sjs-article-font-medium-fontWeight": "700",
      "--sjs-article-font-medium-fontStyle": "normal",
      "--sjs-article-font-medium-fontStretch": "normal",
      "--sjs-article-font-medium-letterSpacing": "0",
      "--sjs-article-font-medium-lineHeight": "32px",
      "--sjs-article-font-medium-paragraphIndent": "0px",
      "--sjs-article-font-medium-textCase": "none",
      "--sjs-article-font-default-textDecoration": "none",
      "--sjs-article-font-default-fontWeight": "400",
      "--sjs-article-font-default-fontStyle": "normal",
      "--sjs-article-font-default-fontStretch": "normal",
      "--sjs-article-font-default-letterSpacing": "0",
      "--sjs-article-font-default-lineHeight": "28px",
      "--sjs-article-font-default-paragraphIndent": "0px",
      "--sjs-article-font-default-textCase": "none",
      "--sjs-general-backcolor-dim": "rgba(0, 0, 255, 0)",
      "--sjs-primary-backcolor": "#21A8A8",
      "--sjs-primary-backcolor-dark": "rgba(30, 153, 153, 1)",
      "--sjs-primary-backcolor-light": "rgba(33, 168, 168, 0.1)",
      "--sjs-primary-forecolor": "rgba(255, 255, 255, 1)",
      "--sjs-primary-forecolor-light": "rgba(255, 255, 255, 0.25)",
      "--sjs-special-red": "rgba(229, 10, 62, 1)",
      "--sjs-special-red-light": "rgba(229, 10, 62, 0.1)",
    },
    headerView: "basic",
  };
  const params = useLocation();

  survey.applyTheme(themeJson);

  const getDemandeReservDenomination = async (value, name) => {
    setLoader(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_GEOPROD_API}/config/proxy/numReserv/` +
          value[0]
      );

      if (data.code === "404") {
        setLoader(false);

        if (name[0]?.value === "legal_entity_reservation_number_official") {
          survey.clearValue("legal_entity_official_name");
        }

        if (name[0]?.value === "legal_entity_reservation_number_commercial") {
          survey.clearValue("legal_entity_commercial_name");
        }
        if (
          name[0]?.value === "establishment_data_reservation_number_commercial"
        ) {
          survey.clearValue("establishment_data_commercial_name");
        }
        return notification.error({
          message: "Erreur",
          description: "Numéro de réservation invalide",
          duration: 0,
        });
      }
      setLoader(false);

      if (name[0]?.value === "legal_entity_reservation_number_official") {
        survey.setValue("legal_entity_official_name", data?.nomination_LATIN);

        survey.setValue(
          "legal_entity_reservation_officielle_expiration_date",
          dayjs(data?.date_EXPIRATION).format("YYYY-MM-DD")
        );
      } else if (
        name[0]?.value === "legal_entity_reservation_number_commercial"
      ) {
        survey.setValue("legal_entity_commercial_name", data?.nomination_LATIN);
        survey.setValue(
          "legal_entity_reservation_commerciale_expiration_date",
          dayjs(data?.date_EXPIRATION).format("YYYY-MM-DD")
        );
      }

      if (
        name[0].value === "establishment_data_reservation_number_commercial"
      ) {
        survey.setValue(
          "establishment_data_commercial_name",
          data?.nomination_LATIN
        );
      }
    } catch (error) {
      setLoader(false);
      notification.error({
        message:
          "L'API est temporairement indisponible. Veuillez réessayer dans quelques instants.",
      });
    }
  };

  const getExtraitRegistreEntrepriseParId = async (value, name) => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_GEOPROD_API}/config/proxy/registreEntrepriseParId/${value[0]}`
      )
      .then((data) => {
       
        if (data?.data?.code === "404") {
          if (name[0]?.value === "legal_entity_tax_number") {
            survey.clearValue("legal_entity_official_name");
          }

          if (name[0]?.value === "establishment_data_tax_number") {
            survey.clearValue("establishment_data_official_name");
            survey.clearValue("establishment_data_commercial_name");
          }

          if (name[0]?.value === "legal_entity_reservation_number_commercial") {
            survey.clearValue("legal_entity_commercial_name");
          }
          if (
            name[0]?.value ===
            "establishment_data_reservation_number_commercial"
          ) {
            survey.clearValue("establishment_data_commercial_name");
          }
          setLoader(false)
          return notification.error({
            message: "Erreur",
            description: "Numéro d’identifiant fiscal / RNE invalide",
            duration: 0,
          });
        }
        survey.setValue(
          "legal_entity_official_name",
          data?.data?.nomAssociationLatin
        );
        survey.setValue(
          "establishment_data_official_name",
          data?.data?.nomAssociationLatin
        );
        survey.setValue(
          "legal_entity_legal_form",
          data?.data?.formeJuridiqueDesc || ""
        );
        survey.setValue(
          "establishment_data_commercial_name",
          data?.data?.nomCommercialLatin || ""
        );
        setLoader(false);
        // nomCommercialLatin
        // survey.setValue(
        //   "legal_entity_reservation_expiration_date",
        //   dayjs(data?.data?.date_EXPIRATION).format("YYYY-MM-DD")
        // );
        // survey.setValue("establishment_data_trade_name", data?.nomination_LATIN);
      })
      .catch((err) => {


        setLoader(false);
        notification.error({
          message:
            "L'API est temporairement indisponible. Veuillez réessayer dans quelques instants.",
        });
      });
  };

  const checkDirectorIdentity = (value) => {
    if (value[0] === "المستثمر نفسه") {
      const page = survey.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      page.questions.forEach((question) => {
        if (
          question.name !== "establishment_director_data_identity" &&
          question.name !== "establishment_director_data_experience_years" &&
          question.name !== "establishment_director_data_ministerial_auth_date"
        )
          question.readOnly = true;
      });
      survey.setValue(
        "establishment_director_data_last_name",
        survey.data.investor_last_name_ar
      );
      survey.setValue(
        "establishment_director_data_date_delivrance",
        survey.data.investor_date_delivrance
      );
      survey.setValue(
        "establishment_director_data_genre",
        survey.data.investor_civility
      );
      survey.setValue(
        "establishment_director_data_passeport",
        survey.data.investor_passport
      );
      survey.setValue(
        "establishment_director_data_passeport_copie",
        survey.data.investor_copie_passeport
      );

      survey.setValue(
        "establishment_director_data_cin_copie",
        survey.data.investor_copie_cin
      );
      survey.setValue(
        "establishment_director_data_director_folder",
        survey.data.investor_copie_cv
      );
      survey.setValue(
        "establishment_director_data_first_name",
        survey.data.investor_first_name_ar
      );

      survey.setValue(
        "establishment_director_data_email",
        survey.data.investor_email
      );
      survey.setValue(
        "establishment_director_data_education_level",
        survey.data.investor_eduction_level
      );
      survey.setValue(
        "establishment_director_data_nationality",
        survey.data.investor_nationality
      );
      survey.setValue(
        "establishment_director_data_birth_date",
        survey.data.investor_birth_date
      );
      survey.setValue(
        "establishment_director_data_birth_place",
        survey.data.investor_birth_place
      );
      survey.setValue(
        "establishment_director_data_specific_needs",
        survey.data.investor_specific_needs
      );
      survey.setValue(
        "establishment_director_data_cin_number",
        survey.data.investor_cin
      );
      survey.setValue(
        "establishment_director_data_passport",
        survey.data.investor_passport
      );
      survey.setValue(
        "establishment_director_data_address",
        survey.data.investor_residence_address
      );
      survey.setValue(
        "establishment_director_data_governorate",
        survey.data.investor_residence_governorate
      );
      survey.setValue(
        "establishment_director_data_delegation",
        survey.data.investor_residence_delegation
      );
      survey.setValue(
        "establishment_director_data_phone_number",
        survey.data.investor_phone_number
      );
    } else {
      const page = survey.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      if (page) {
        page.questions.forEach((question) => {
          if (question.name !== "establishment_director_data_identity") {
            survey.clearValue(question.name);
          }
          question.readOnly = false;
        });
      }
    }
  };

  const checkDNCINDirector = async (param) => {
    const dn = param[1];
    const cin = param[0];
    setLoader(true);

    let data;

    data = await checkCitizenStatusByCinDn(dn, cin);
    survey.setValue("establishment_director_data_last_name", data?.nomAr);

    survey.setValue("establishment_director_data_first_name", data?.prenomAr);
    survey.setValue(
      "establishment_director_data_genre",
      data?.genre === "1" ? "ذكر" : "أنثى"
    );

    if (data?.commune) {
      Object.keys(codes_communes).map((e) => {
        Object.keys(codes_communes[e].communes).map((elem) => {
          if (codes_communes[e].communes[elem] === data?.commune) {
            survey.setValue("establishment_director_data_birth_place", elem);
          }
        });
      });
    }

    setLoader(false);
  };

  useEffect(() => {
    async function fetchData() {
      setLoader(true);
      let data;
      if (user?.nationality === "تونسية") {
        data = await checkCitizenStatusByCinDn(
          user?.user?.date_naiss_user,
          user?.cin
        );
      }

      if (sessionStorage.getItem("surveyData")) {
        survey.data = JSON.parse(sessionStorage.getItem("surveyData"));
        survey.currentPageNo = parseInt(sessionStorage.getItem("surveyStep"));
      }
      if (
        survey.getQuestionByName("establishment_director_data_identity") ===
        "self"
      ) {
        const page = survey.getPageByName(
          "Données de Directeur de l’Établissement"
        );
        page.questions.forEach((question) => {
          if (
            question.name !== "establishment_director_data_identity" &&
            question.name !== "establishment_director_data_experience_years" &&
            question.name !==
              "establishment_director_data_ministerial_auth_date"
          )
            question.readOnly = true;
        });
      }

      if (data?.prenomAr) {
        survey.setValue("investor_first_name_ar", data?.prenomAr);
        const name_ar = survey.getQuestionByName("investor_first_name_ar");
        name_ar.readOnly = true;
      }
      if (data?.nomAr) {
        survey.setValue("investor_last_name_ar", data?.nomAr);
        const prenom_ar = survey.getQuestionByName("investor_last_name_ar");
        prenom_ar.readOnly = true;
      }
      if (data?.commune) {
        Object.keys(codes_communes).map((e) => {
          Object.keys(codes_communes[e].communes).map((elem) => {
            if (codes_communes[e].communes[elem] === data?.commune) {
              survey.setValue("investor_birth_place", elem);
            }
          });
        });
      }
      if (data?.prenomFr) {
        survey.setValue("investor_last_name_fr", data?.prenomFr || user?.nom);
        const name_fr = survey.getQuestionByName("investor_last_name_fr");
        name_fr.readOnly = true;
      }
      if (data?.nomFr) {
        survey.setValue("investor_first_name_fr", data?.nomFr || user?.prenom);
        const prenom_fr = survey.getQuestionByName("investor_first_name_fr");
        prenom_fr.readOnly = true;
      }

      survey.setValue("investor_email", user?.email);
      survey.setValue("investor_phone_number", user?.mobile);
      survey.setValue("investor_nationality", user?.nationality);
      survey.setValue(
        "investor_civility",
        data?.genre === "1" ? "ذكر" : "أنثى"
      );
      survey.setValue("investor_cin", user?.cin);
      survey.setValue(
        "investor_birth_date",
        dayjs(user?.user?.date_naiss_user).format("YYYY-MM-DD")
      );

      setLoader(false);
    }

    fetchData();
    populateDelegationOnLoad()

    if (!sessionStorage.getItem("id_affaire")) {
      const payload = {
        ligne_produit: "f90f2aca5c640289d0a29417bcb63a37",
        id_opp: null,
        nom_produit: "THAMM PRODUCT (  )",
        id_produit: "d728587fff2d87201eae0a158507d814",
        cmp: "987654300",
        user_id: user.id_user,
        date_effet: "2024-01-02",
        souscription: "2024-01-02",
        id_fractionnement: 11,
        affected: "1002237",
        data_form_prod: JSON.stringify({
          ...survey.data,
        }),
      };
      const getIdAffaire = async () => {
        const affaire = await addAffaire(payload);
        sessionStorage.setItem("id_affaire", affaire?.id_affaire);
      };

      getIdAffaire();
    }
  }, [survey]);

  FunctionFactory.Instance.register(
    "getDemandeReservDenomination",
    getDemandeReservDenomination
  );

  FunctionFactory.Instance.register(
    "getExtraitRegistreEntrepriseParId",
    getExtraitRegistreEntrepriseParId
  );

  FunctionFactory.Instance.register(
    "checkDirectorIdentity",
    checkDirectorIdentity
  );

  FunctionFactory.Instance.register("checkDNCINDirector", checkDNCINDirector);
  FunctionFactory.Instance.register("today", function () {
    return new Date().toISOString().split("T")[0];
  });
  survey.onComplete.add(async (sender, options) => {
    options.allowComplete = false;
    setOpenModal(true);
  });

  const populateDelegationOnLoad =()=> {
    survey.getAllQuestions().forEach((question) => {
      if (question.name.includes("_governorate")) {
        const delegateKey = question.name.replace("_governorate", "_delegation");
        const selectedGouvernorat = question.value;
        const selectedDelegate = survey.getQuestionByName(delegateKey);
  
        if (selectedGouvernorat && selectedDelegate) {
          Object.keys(gouvernorats).map((e) => {
            if (e === selectedGouvernorat) {
              selectedDelegate.choices = gouvernorats[e]["delegations"] || [];
            }
          });
        }
      }
    });
  }

  survey.onValueChanged.add(async function (sender, options) {
    sessionStorage.setItem("surveyData", JSON.stringify(survey.data));
    sessionStorage.setItem("surveyStep", survey.currentPageNo);

    if (
      options.name === "establishment_data_training_type" &&
      options.value === "أولية ومستمرة"
    ) {
      const trainingPanel = survey.getQuestionByName("training_nature_data");
      trainingPanel.panelCount = 2;
    }

    if (
      options.name === "establishment_director_data_nationality" &&
      options.value !== "تونسية"
    ) {
      notification.info({
        message:
          "Vous devez obtenir une autorisation du Ministre chargé de la formation professionnelle pour assurer la direction de l’établissement privée de formation professionnelle.",
        duration: 0,
      });
    }

    if (options.name.includes("_governorate")) {
      const delegateKey = options.name.replace("_governorate", "_delegation");
      const selectedGouvernorat = options.value;
      const selectedDelegate = sender.getQuestionByName(delegateKey);

      Object.keys(gouvernorats).map((e) => {
        if (e === selectedGouvernorat) {
          selectedDelegate.choices = gouvernorats[e]["delegations"] || [];
        }
      });
    }
    if (
      options.name === "legal_entity_tax_number" ||
      options.name === "establishment_data_tax_number"
    ) {
      const rneValue = options.value;
      const rneRegex = /^[0-9]{7}[A-Z]$/;

      const question = sender.getQuestionByName(options.name);
      question.clearErrors();

      if (!rneRegex.test(rneValue)) {
        question.addError(
          "Le numéro RNE doit être au format 7 chiffres suivis d'une lettre majuscule (ex: 1234567A)."
        );
      }
    }
    if (options.name.includes("_email")) {
      const emailValue = options.value;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const question = sender.getQuestionByName(options.name);
      question.clearErrors();
      if (!emailRegex.test(emailValue)) {
        question.addError("Merci d'entrer une adresse mail valide.");
      }
    }
  });

  survey.onDynamicPanelItemValueChanged.add(async function (sender, options) {
    const language = i18next.language;
    if (options.question.name === "trainer_data") {
      const panelIndex = options.panelIndex;
      const changedData = options.itemValue;

      if (
        changedData.trainer_data_cin_number ||
        changedData.trainer_data_passport
      ) {
        try {
          setLoader(true);
          console.log("hihihi");

          const data = await getTrainerByNationalityAndIdentity({
            nationality: changedData.trainer_data_nationality,
            cin:
              changedData.trainer_data_nationality === "تونسية"
                ? changedData.trainer_data_cin_number
                : null,
            passport:
              changedData.trainer_data_nationality !== "تونسية"
                ? changedData.trainer_data_passport
                : null,
          });

          // const excludedQuestions = [
          //   "trainer_data_nationality",
          //   "trainer_data_passport",
          //   "trainer_data_cin_number","isVisible",
          //   "investor_copie_passeport",
          //   "investor_copie_cin"
          // ];

          const panel = options.panel;

          if (!data) {
            setLoader(false);
            notification.error({
              message: t("Error !"),
              description: t(
                "Le numéro de CIN du formateur indiqué n'est pas associé à un compte sur la plateforme SIGAF. Veuillez vérifier le numéro de CIN fourni ou inviter le formateur à créer un compte sur la plateforme."
              ),
              duration: 0,
            });

            // panel.getQuestions().forEach((question) => {
            //   if (!excludedQuestions.includes(question.name)) {
            //     question.visible = false;
            //   }
            // });

            // const isVisible = panel.getQuestionByName("isVisible");

            // isVisible.value = false;
            // return;
          } else {
            setLoader(false);
            // panel.getQuestions().forEach((question) => {
            //   if (!excludedQuestions.includes(question.name)) {
            //     question.visible = true;
            //   }
            // });

            const isVisible = panel.getQuestionByName("isVisible");

            isVisible.value = true;
          }

          survey.data.trainer_data[panelIndex].trainer_data_last_name =
            data?.nom || "";
          survey.data.trainer_data[panelIndex].trainer_data_first_name =
            data?.prenom || "";
          survey.data.trainer_data[panelIndex].trainer_data_email =
            data?.mail || "";
          survey.data.trainer_data[panelIndex].trainer_data_birth_date =
            dayjs(data?.date_naiss_user).format("YYYY-MM-DD") || "";
          survey.data.trainer_data[panelIndex].trainer_data_civility =
            data?.civility === "M." ? "ذكر" : "أنثى";
          survey.data.trainer_data[panelIndex].trainer_data_phone_number =
            data?.mobile || "";
          survey.data.trainer_data[panelIndex].trainer_data_address =
            data?.adresse || "";
          survey.data.trainer_data[panelIndex].trainer_data_birth_place =
            data?.birthplace || "";
          survey.data.trainer_data[panelIndex].trainer_data_governorate =
            data?.goverment || "";
          survey.data.trainer_data[panelIndex].trainer_data_postal_code =
            data?.code_postal || "";
          survey.data.trainer_data[panelIndex].trainer_data_specialty_domain =
            data?.domaines_specialite || "";
          survey.data.trainer_data[panelIndex].trainer_data_education_level =
            data?.niveau_scolaire || "";
          survey.data.trainer_data[
            panelIndex
          ].trainer_data_cin_date_delivrance = data?.delivrance_date || "";
          survey.data.trainer_data[panelIndex].trainer_data_specific_needs =
            data?.besoins_specifiques;

          const delegationQuestion = panel.getQuestionByName(
            "trainer_data_delegation"
          );

          Object.keys(gouvernorats).map((e) => {
            if (e === data?.goverment) {
              delegationQuestion.choices = gouvernorats[e]["delegations"] || [];
            }
          });
          // Object.keys(gouvernorats).map((e) => {
          //   if (gouvernorats[e][language] === data?.goverment) {
          //     delegationQuestion.choices =
          //       gouvernorats[e]["delegations"][language] || [];
          //   }
          // });

          // FEHA ERREUR LEZEM NCHUFUHA BAED
          // delegationQuestion.choices =
          //   gouvernorats[data?.goverment][language] || [];

          survey.data.trainer_data[panelIndex].trainer_data_delegation =
            data?.delegation || "";

          // Update the panel's data
          sender.getQuestionByName("trainer_data").value =
            survey.data.trainer_data;
        } catch (error) {
          notification.error({
            message: t("Error !"),
            description:
              error?.response?.data?.message ||
              "Une erreur est survenue lors de la récupération des données du formateur",
            duration: 0,
          });
        }
      }
    }

    if (options.question.name === "management_data") {
      if (options.name === "management_data_staff_residence_governorate") {
        const panel = options.panel;

        const delegationQuestion = panel.getQuestionByName(
          "management_data_staff_residence_delegation"
        );

        if (delegationQuestion) {
          Object.keys(gouvernorats).map((e) => {
            if (e === options.value) {
              delegationQuestion.choices = gouvernorats[e]["delegations"] || [];
            }
          });
        }
      }
    }

    if (options.question.name === "training_nature_data") {
      if (options.name === "training_type_data_sector") {
        const panel = options.panel;

        const sousSecteurQuestion = panel.getQuestionByName(
          "training_type_data_sub_sector"
        );

        const selectedSector = options.value;

        // Set sub-sector choices based on the selected sector
        if (sousSecteur[selectedSector]) {
          sousSecteurQuestion.choices = sousSecteur[selectedSector];
        } else {
          sousSecteurQuestion.choices = []; // Clear choices if no sub-sectors available
        }
      }

      if (options.name === "training_type_data_sub_sector") {
        const panel = options.panel;
        const selectedSubSector = options.value;
        const specialtyQuestion = panel.getQuestionByName(
          "training_type_data_specialty"
        );

        // Set specialty choices based on the selected sub-sector
        if (spetialite_diplome[selectedSubSector]) {
          specialtyQuestion.choices = spetialite_diplome[selectedSubSector].map(
            (item) => item.spetialite
          );
        } else {
          specialtyQuestion.choices = [];
        }
      }

      // Handle the diploma population when a specialty is selected
      if (options.name === "training_type_data_specialty") {
        const panel = options.panel;
        const selectedSpecialty = options.value;
        const diplomaQuestion = panel.getQuestionByName(
          "training_type_data_diploma"
        );

        // Find the matching specialty and set the diploma
        const selectedSubSector = panel.getQuestionByName(
          "training_type_data_sub_sector"
        ).value;
        const specialtyData = spetialite_diplome[selectedSubSector]?.find(
          (item) => item.spetialite === selectedSpecialty
        );

        if (specialtyData) {
          diplomaQuestion.value = specialtyData.diplome; // Automatically set the diploma
        } else {
          diplomaQuestion.value = "";
        }
      }
    }
  });

  survey.onErrorCustomText.add(function (sender, options) {
    if (options.name == "required") {
      options.text = t("Veuillez remplir ce champ.");
    }
    if (options.name === "custom") {
      if (options.obj.jsonObj?.name?.includes("_date")) {
        options.text = t(
          "La date sélectionnée ne peut pas être dans le futur. Veuillez choisir une date valide."
        );
      }
    }
  });

  survey.onCurrentPageChanging.add(async function (sender, options) {
    const currentPage = sender.currentPage;

    const currentPageName = currentPage.name;

    if (currentPageName === "page5") {
      options.allowChanging = false;

      const validation = survey.data.trainer_data.map((element) => {
        return element.isVisible;
      });
      if (validation.includes(false)) {
        options.allowChanging = false;
        notification.error({
          message: t("Error !"),
          description:
            "Veuillez remplir les informations des formateurs avant de continuer",
          duration: 0,
        });
      } else {
        options.allowChanging = true;
      }
    }

    if (sender.currentPage.name === "page4") {
      const establishment_data_training_type = sender.getQuestionByName(
        "establishment_data_training_type"
      ).value;

      if (establishment_data_training_type === "أولية ومستمرة") {
        const passConditionValues = [];
        const training_nature_data = sender.getQuestionByName(
          "training_nature_data"
        ).value;

        training_nature_data.forEach((element) => {
          if (
            !passConditionValues.includes(element.training_type_data_nature)
          ) {
            passConditionValues.push(element.training_type_data_nature);
          }
        });

        if (
          passConditionValues.includes("أولية") &&
          passConditionValues.includes("مستمرة")
        ) {
          options.allowChanging = true;
        } else {
          if (options.isNextPage) {
            notification.error({
              message: t("Error !"),
              description:
                "Veuillez selections 2 types de formation (Initiale et Continue)",
              duration: 0,
            });
            options.allowChanging = false;
          } else {
            options.allowChanging = true;
          }
        }
      }
    }

    console.log(options);

    // if (options.name.includes("_date")) {
    //   debugger
    //   const today = new Date().toISOString().split("T")[0];
    //   const birthDate = new Date(options.value);
    //   const currentDate = new Date(today);

    //   if (birthDate > currentDate) {
    //     options.error = `La date de naissance ne peut pas être postérieure à ${today}`;
    //   }
    // }
  });

  const handleFileChange = (info, document) => {
    const { file } = info;
    const newFileList = [...fileList];
    newFileList.push({ file, document });
    setFileList(newFileList);
  };

  const handleSubmit = async () => {
    setLoader(true);
    const formData = new FormData();
    if (params?.state?.id_affaire) {
      formData.append("id_affaire", params?.state?.id_affaire);
      formData.append("id_pros", "");
      formData.append("id_contrat", "f90f2aca5c640289d0a29417bcb63a37");
      formData.append("id_type_doc", "006f52e9102a8d3be2fe5614f42ba989");
      formData.append("id_opp", null);
      formData.append("name_doc", "Complement de dossier");

      fileList.forEach(({ file, document }) => {
        formData.append("file[]", file);
      });

      try {
        await uploadDocumentAffaire(formData);
        setLoader(false);
        navigate("/demandes");
      } catch (error) {
        setLoader(false);
      }
    }
  };

  return (
    <div>
      <Row className={classes.rowButtonCreate}>
        <Space>
          <Button
            className={classes.buttonCreate}
            onClick={async () => {
              setLoader(true);
              const payload = {
                additional_form: "{}",
                json_prod: JSON.stringify({
                  ...survey.data,
                }),
              };

              await saveAffaire(payload, "Brouillon");
              setLoader(false);
              navigate("/brouillon");
            }}
          >
            <SaveOutlined />
            {t("Sauvegarder au brouillon")}
          </Button>
          <Button
            className={classes.buttonCreate}
            onClick={() => navigate("/etablissements")}
          >
            <UnorderedListOutlined />
            {t("Liste de mes EPFs")}
          </Button>
        </Space>
      </Row>

      <Survey model={survey} locale={i18n.language} />

      <Modal
        open={openModal}
        width="80%"
        title={
          <div style={{ textAlign: "center", width: "100%" }}>
            {t(
              "Veuillez parcourir le cahier des charges jusqu'à la fin afin de pouvoir confirmer sa lecture complète."
            )}
          </div>
        }
        icon={<ExclamationCircleOutlined />}
        onOk={async () => {
          try {
            if (!confirm) {
              notification.error({
                message: t("Error !"),
                description: t(
                  "Merci de cocher la case pour attester que vous avez pris connaissance des termes du CDC et que vous les acceptez concernant la création d'un EPF."
                ),
                duration: 0,
              });
              return;
            }

            setLoader(true);
            setOpenModal(false);

            const payload = {
              additional_form: "{}",
              json_prod: JSON.stringify({
                ...survey.data,
              }),
            };

            await saveAffaire(payload, "soumission");

            notification.success({
              message: "Succès",
              description: t(
                "Votre demande de création d'un nouvel EPF a été soumise avec succès. Vous recevrez un email vous informant des prochaines étapes à suivre."
              ),
              duration: 0,
            });

            sessionStorage.removeItem("surveyData");
            sessionStorage.removeItem("surveyStep");
            sessionStorage.removeItem("id_affaire");
            setLoader(false);
            navigate("/demandes");
          } catch (error) {
            setLoadingSave(false);
            notification.error({
              message: "Erreur",
              description: "Une erreur est survenue lors de l'enregistrement",
              duration: 0,
            });
          }
        }}
        onCancel={() => {
          setOpenModal(false);
        }}
        okText={t("Soumettre la demande")}
        okButtonProps={{
          disabled: loadingSave,
        }}
        cancelText={t("Annuler")}
        cancelButtonProps={{
          disabled: loadingSave,
        }}
      >
        <PdfViewer setConfirm={setConfirm} />
      </Modal>

      {porteDoc?.length > 0 && (
        <div style={{ marginInline: "2rem" }}>
          <h2>{t("Liste des documents à fournir")}</h2>
          <Collapse accordion>
            {porteDoc.map((document) => (
              <Panel
                header={
                  <span>
                    {document.titre}
                    {/* Uncomment if you want to show required label */}
                    {/* {document.required && <span style={{ color: "red" }}>(*)</span>} */}
                  </span>
                }
                key={document.name}
                style={{
                  border:
                    document.required && !document.uploaded
                      ? "1px solid red"
                      : "1px solid #f0f0f0",
                  backgroundColor:
                    document.required && !document.uploaded
                      ? "#f8d7da"
                      : "#ffffff",
                }}
                extra={<MoreOutlined />}
              >
                <Card bordered={false}>
                  <Upload
                    showUploadList={{ showRemoveIcon: false }}
                    beforeUpload={() => false} // Prevent automatic upload
                    onChange={(info) => handleFileChange(info, document)}
                  >
                    <Button icon={<UploadOutlined />}>
                      {document?.description || "Upload"}
                    </Button>
                  </Upload>
                </Card>
              </Panel>
            ))}
          </Collapse>

          <Button
            type="primary"
            onClick={handleSubmit}
            style={{ marginTop: "1rem" }}
          >
            {t("Soumettre")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreateEpf;
