import axios from "axios";
import dayjs from "dayjs";

export const signUp = async (payload) => {
  try {
    const { data } = await axios.post(
      process.env.REACT_APP_GEOPROD_API + "/admin/create_user",
      payload
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    const auth = btoa(`${email}:${password}`);

    const { data } = await axios.post(
      process.env.REACT_APP_GEOPROD_API + "/login",
      null,
      {
        headers: {
          Authorization: `Basic ${auth}`,
        },
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const addAffaire = async (payload) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_GEOPROD_API}/add_affaire`,
    payload
  );
  return data;
};

export const saveAffaire = async (payload, type) => {
  console.log(type);

  try {
    const id_affaire = sessionStorage.getItem("id_affaire");
    const data = await updateAffaire(id_affaire, payload).then(async (res) => {
      if (type === "soumission") {
        await updateActionAffaire({
          id_affaire: id_affaire,
          id_status: "170c944978496731ba71f34c25826a34",
        }).then(async (res) => {
          await axios.post(
            process.env.REACT_APP_GEOPROD_API +
              "/print_document?id_affaire=" +
              id_affaire,
            // {
            //   id_affaire: data.id_affaire,
            //   id_user: payload.user_id,
            // },
            {
              headers: {
                Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                  .Acces_Token,
              },
            }
          );
        });
      }
     
    });

    return data;
  } catch (error) {
    console.error("Error in saveAffaire:", error);
    throw new Error("Failed to save affaire. Please try again later.");
  }
};

export const getSchema = async (params) => {
  try {
    const { data } = await axios.get(
      process.env.REACT_APP_GEOPROD_API + "/get_info_specifique/" + params,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .Acces_Token,
        },
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getAffaires = async (params) => {
  try {
    const payloadParams = {
      page: 1,
      limit: 15,
      iSortCol_0: 2,
      sSortDir_0: "desc",
      sSearch: "",
      is_investisseur: true,
      all_filtres: {
        aff: {
          utilisateur: {
            entreprise_call: params.id_entreprise,
            recheche_create_user: [params.id_user],
            recheche_zone: [],
            operateur_recherche: "",
            recherche_groupe: [],
            service: [],
          },
          prospect: {
            prospect_nom_prenom: "",
            prospect_tel: "",
            prospect_mail: "",
            prospect_ville: "",
            RS: "",
            prospect_zone: [],
            tags: [],
            tags_no: [],
            mail_contient: "",
            name: "",
            surname: "",
          },
          affaire: {
            cmp: [],
            operateur_cmp: "=",
            operateur_gamme: "=",
            gamme: [],
            operateur_produit: "=",
            produit: [],
            recheche_num_contrat: "",
            pan_ttc_min: "",
            pan_ttc_max: "",
            pose_du_creation: "2024-08-20",
            pose_au_creation: dayjs().format("YYYY-MM-DD"),
            pose_du_souscription: null,
            pose_au_souscription: null,
            date_effet_dossier_du: null,
            date_effet_dossier_au: null,
            operateur_contrat: "=",
            contrat: [],
            classe: [],
            operateur_recherche_tag: "=",
            tags_aff: [],
            tags_aff_not: [],
            res_env: "",
            pose_du_date_envoi_res: null,
            pose_au_date_envoi_res: null,
            affaire_occ_res: [],
            type_date: "Creation",
          },
          contrat_actuel: {
            deja_assure: "",
            an_cmp: [],
            operateur_pan_ttc: "",
            PAN_TTC: "",
            date_ech: null,
          },
          statut_actuel: {
            status: [],
          },
          qualite: {
            data: {},
          },
          action_user: {
            dernier_action: "",
            date_creation_action: null,
            date_fin_action: null,
            list_user: [],
            enreg_simple: "",
            enreg_avec_action: "",
            status_action: [],
            categorie: [],
          },
        },
        opp: {
          filtre_opp: {
            campagne: [],
            date_debut_creation: null,
            date_fin_creation: null,
            date_debut_rdv: null,
            date_fin_rdv: null,
            cycle_de_vie: [],
            tags_opp: [],
          },
          utilisateur: {
            organismes: [],
            list_createurs: [],
            list_affectation: [],
            groupes_affecte: [],
            groupes_createurs: [],
            zones: [],
            services: [],
            operateur_recherche_group: "",
            operateur_recherche_group_affet: "",
          },
          action_user: {
            dernier_action: "",
            date_creation_action: null,
            date_fin_action: null,
            list_user: [],
            groupes: [],
            enreg_simple: "",
            status_action: [],
            categorie: [],
            operateur: "",
            enreg_avec_action: "",
          },
          rdv: {
            etat_rdv: [],
          },
          telephonie: {
            argumentes_tout: "",
            operateur_telephonie: "",
            value_telephonie: "",
            operateur_duree: "",
            value_duree: "",
          },
        },
      },
    };

    const { data } = await axios.post(
      process.env.REACT_APP_GEOPROD_API + "/list_affaires",
      payloadParams,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .Acces_Token,
        },
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const updateActionAffaire = async (params) => {
  try {
    const payload = {
      commentaire_action: "",
      id_affaire: params.id_affaire,
      id_motif: "",
      id_statut: params?.id_status || "170c944978496731ba71f34c25826a34",
    };

    const { data } = await axios.put(
      process.env.REACT_APP_GEOPROD_API + "/update_action_affaire",
      payload,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .Acces_Token,
        },
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getTrainerByNationalityAndIdentity = async (params) => {  
  try {
    const queryStringParams = `?nationality=${params.nationality}${
      params.cin ? `&cin=${params.cin}` : ""
    }${params.passport ? `&passport=${params.passport}` : ""}`;

    const { data } = await axios.get(
      process.env.REACT_APP_GEOPROD_API +
        "/admin/search_user_by_nationality_cin_passport" +
        queryStringParams,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .Acces_Token,
        },
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getNotifictaions = async () => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_GEOPROD_API + "/get_notifications",
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .Acces_Token,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadDocumentAffaire = async (formData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_GEOPROD_API}/upload_document_affaire`,
      formData,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const updateAffaire = async (id_affaire, payload) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_GEOPROD_API}/update_info_specifique?id_affaire=${id_affaire}`,
      payload,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const getListFormateursByUser = async () => {
  try {
    const id_user = JSON.parse(sessionStorage.getItem("-x-token-user")).id_user;
    const response = await axios.get(
      `${process.env.REACT_APP_GEOPROD_API}/config/list_users_by_role/trainer/${id_user}`,
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .Acces_Token,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};
