import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Card,
  Table,
  Tooltip,
  Tag,
  Button,
  Space,
  notification,
  Modal,
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import classes from "../Etablissements/Etablissements.module.css";
import { useTranslation } from "react-i18next";
import { getAffaires, updateActionAffaire } from "../../api/geoprod";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import GlobalContext from "../../context/GlobalContext";

const Brouillons = (props) => {
  const { t } = useTranslation();
  
  const [loading, setLoading] = useState(true);
  const { setLoader,brouillon, setBrouillon } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState("");
  const { survey } = props;
  const columns = [
    {
      title: t("Date de création"),
      key: "Date de création",
      render: (_, record) =>
        dayjs(record?.affaire_date_creation).format("DD/MM/YYYY"),
    },
    {
      title: t("Date dernière modification"),
      dataIndex: "Date dernière modification",
      key: "Date dernière modification",
      render: (_, record) => record?.last_update ? dayjs(record.last_update).format("DD/MM/YYYY HH:mm:ss") : "Non renseigné",
    },

    {
      title: t("Statut"),
      key: "statut",
      render: (_, record) => {
        return (
          <Tag style={{ cursor: "pointer" }} color={record?.etat_background}>
            {record?.etat}
          </Tag>
        );
      },
    },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Tooltip title={t("Modifier")}>
            <EditOutlined
              onClick={() => {
                navigate(`/etablissements/save`);
                sessionStorage.setItem(
                  "surveyData",
                  record?.data_form
                );
              }}
            />
          </Tooltip>
          <Tooltip title={t("Supprimer")}>
            <DeleteOutlined
              onClick={() => setConfirmModal(record?.affaire_id)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const deleteBrouillon = () => {
    setLoader(true);
    axios
      .delete(
        `${process.env.REACT_APP_GEOPROD_API}/delete_affaire/${confirmModal}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              .Acces_Token,
          },
        }
      )
      .then((res) => {
        setBrouillon((prevDataSource) =>
          prevDataSource.filter((item) => item.affaire_id !== confirmModal)
        );
        setConfirmModal("");
        setLoader(false);
        sessionStorage.removeItem("id_affaire");
        sessionStorage.removeItem("surveyStep");
        let defaultData={inverstor_type: "Personne morale"}
        sessionStorage.setItem("surveyData",JSON.stringify(defaultData));
        survey.data = defaultData;
        survey.currentPageNo=0
        notification.success({
          message: "Votre brouillon a été supprimé avec succès",
          duration: 0,
        });
      })
      .catch(() => {
        setConfirmModal("");
        setLoader(false);
      });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAffaires({
          id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
          id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .user.entreprise_id,
        });
        setBrouillon(
          data?.aaData?.filter((elem) => elem.etat === "Brouillon")
        );
        setLoading(false);
      } catch (error) {
        notification.error({
          message: t("Error !"),
          description:
            error?.response?.data?.message ||
            "Une erreur s'est produite lors de la récupération des données",
          duration: 0,
        });
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      <Modal
        title={t("Changer le mot de passe")}
        visible={confirmModal !== ""}
        footer={null}
        width={600}
        onCancel={() => setConfirmModal("")}
      >
        <h4>Êtes-vous sûr de vouloir supprimer ce brouillon ?</h4>
        <Button type="primary" onClick={deleteBrouillon}>
          Oui
        </Button>
        <Button
          type="primary"
          style={{ marginLeft: "1rem" }}
          onClick={() => setConfirmModal("")}
        >
          Non
        </Button>
      </Modal>

      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={t("Liste de mes brouillons")}
        >
          <Table
            loading={loading}
            columns={columns}
            dataSource={brouillon}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={{
              pageSize: 5,
            }}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Brouillons;
