import dayjs from "dayjs";

export function getDateParts(date) {
  const parsedDate = dayjs(date);
  return {
    day: parsedDate.date().toString(),
    month: (parsedDate.month() + 1).toString(), // month() returns 0-11, so we add 1
    year: parsedDate.year().toString(),
  };
}

export function generatePassword() {
  const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const specialChars = "*/@-#$";
  const allChars = letters + numbers + specialChars;

  let password = "";

  // Ensure at least one letter, one number, and one special character
  password += letters[Math.floor(Math.random() * letters.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  // Fill the remaining characters to make it 12 characters long
  for (let i = 3; i < 12; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  // Shuffle the password to avoid predictable positions
  password = password
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");

  return password;
}
