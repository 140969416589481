import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  Form,
  Input,
  Card,
  Select,
  notification,
  Modal,
  Spin,
  Space,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import signin from "../../assets/images/imagee.svg";
import GlobalContext from "../../context/GlobalContext";
import logo from "../../assets/images/sigaf.svg";
import republique from "../../assets/images/tunisia.png";
import { useTranslation } from "react-i18next";
import { login } from "../../api/geoprod";
import axios from "axios";
import classes from "./Signin.module.css";

const SignIn = (props) => {
  const navigate = useNavigate();
  const { form, globalData, setGlobalData, setIsRtl } =
    useContext(GlobalContext);
  const { setToken, setRole } = props;
  const { Option } = Select;
  const [openModalPassword, setOpenModalPassword] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loaderChange, setLoaderChange] = useState(false);
  const [loaderForget, setLoaderForget] = useState(false);
  const [openModalForgetPassword, setOpenModalForgetPassword] = useState(false);
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);
    setIsRtl(value === "ar");
  };

  const role = new URLSearchParams(window.location.search).get("role");
  sessionStorage.setItem("role",role)

  const saveLoginCredentials = (data) => {
    setToken(data.Acces_Token);
    setRole(
      data.id_role === process.env.REACT_APP_ID_ROLE_FORMATEUR
        ? "formateur"
        : "investisseur"
    );
    sessionStorage.setItem(
      "role",
      data.id_role === process.env.REACT_APP_ID_ROLE_FORMATEUR
        ? "formateur"
        : "investisseur"
    );
    sessionStorage.setItem("-x-token-user", JSON.stringify(data));
    navigate(
      data.id_role === process.env.REACT_APP_ID_ROLE_FORMATEUR
        ? "/profile"
        : "/etablissements"
    );
  };

  const handleFinish = async (values) => {
    setLoader(true);
    try {
      const data = await login(values.email, values.password);
      if (data?.first_login === 1) {
        setOpenModalPassword(true);
        setUserData(data);
      } else {
        saveLoginCredentials(data);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      notification.error({
        message: t("Error !"),
        description:
          error?.response?.data?.message ||
          t("Une erreur s'est produite lors de la connexion"),
          duration: 0,

      });
    }
  };

  const changePassword = async () => {
    setLoaderChange(true);
    try {
      const newPassword = form.getFieldValue("password_change");
      await axios.put(
        `${process.env.REACT_APP_GEOPROD_API}/change_password_user`,
        {
          id_user: userData?.id_user,
          password: newPassword,
        },
        {
          headers: {
            Idsession: userData?.Acces_Token,
          },
        }
      );
      setOpenModalPassword(false);
      notification.success({
        message: t("Mot de passe changé avec succès !"),
        duration: 0,

      });
      setLoaderChange(false);

      saveLoginCredentials(userData);
    } catch (error) {
      setLoaderChange(false);
      notification.error({
        message: t("Erreur lors du changement de mot de passe"),
        description: error.message || t("Veuillez réessayer."),
        duration: 0,

      });
    }
  };

  const forgetPassword = (values) => {
    setLoaderForget(true);

    axios
      .put(
        `${process.env.REACT_APP_GEOPROD_API}/forget_password?mail=${values.email_oublie}`
      )
      .then((res) => {
        setLoaderForget(false);
        notification.success({
          message: "Votre mot de passe a été réinitialisé avec succès. Veuillez consulter votre boîte mail.",
          duration: 0,

        });
        setOpenModalForgetPassword(false)
      })
      .catch(() => {

        setLoaderForget(false);
      });
  };

  return (
    <Row gutter={24} style={{ display: "flex", justifyContent: "center" }}>
      <Col
        lg={24}
        style={{
          marginBottom: "4rem",
          display: "flex",
          justifyContent: "space-around",
          backgroundColor: "white",
          width: "100%",
          padding: "1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            alignItems: "center",
          }}
        >
          <img src={republique} style={{ height: "86px" }} />
          <img src={logo} />
          <Select
            style={{ width: "8rem", display: "flex", alignItems: "center" }}
            onChange={handleLanguageChange}
            value={localStorage.getItem("language") || "fr"}
          >
            <Option value="fr">Français</Option>
            <Option value="ar">العربية</Option>
          </Select>
        </Row>
      </Col>

      <Col lg={9}>
        <Card
          className="p-4 p-sm-7"
          style={{
            direction:
              localStorage.getItem("language") === "ar" ? "rtl" : "ltr",
          }}
        >
          <h2 className="mb-2">{t("Se connecter")}</h2>

          <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            disabled={loader}
          >
            <Form.Item
              label="Adresse e-mail - البريد الإلكتروني"
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t("Veuillez vérifier ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Mot de passe - كلمة المرور"
              name="password"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="recaptcha"
              rules={[
                {
                  required: true,
                  message: t("Veuillez vérifier ce champ."),
                },
              ]}
            >
              <ReCAPTCHA sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" />
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item>
                <Space>
                  {loader && <Spin />}
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#21A8A8",
                      width: "7rem",
                      color: "white",
                    }}
                    htmlType="submit"
                  >
                    {t("Se connecter")}
                  </Button>
                </Space>
              </Form.Item>
              <p style={{ textAlign: "right" }}>
                {t("Vous n'avez pas de compte ?")}{" "}
                <span
                  className={classes.seConnecter}
                  onClick={() => navigate(`/signup?role=${role}`)}
                >
                  {t("Créez-en un")}
                </span>
              </p>
            </div>
            <p
             style={{ textAlign: "left" }}
              className={classes.seConnecter}
              onClick={() => setOpenModalForgetPassword(true)}
            >
              {t("Mot de passe oublié ?")}{" "}
            </p>
          </Form>
        </Card>
      </Col>

      <Modal
        title={t("Changer le mot de passe")}
        visible={openModalPassword}
        footer={null}
        width={600}
        onCancel={() => setOpenModalPassword(false)}
      >
        <Form
          form={form}
          layout="vertical"
          style={{ maxWidth: "600px", margin: "auto", paddingTop: "20px" }}
          onFinish={changePassword}
          disabled={loaderChange}
        >
          <Form.Item
            label={t("Mot de passe")}
            name="password_change"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              {
                pattern:
                  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[*/.@-])[A-Za-z\d*/.@-]{6,}$/,
                message: t(
                  "Le mot de passe doit contenir 6 caractères alphanumériques (A-Z, a-z, 0-9) et des caractères spéciaux (*/.@-)"
                ),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={t("Confirmer le mot de passe")}
            name="confirmPassword"
            dependencies={["password_change"]}
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password_change") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      t(
                        "Le mot de passe et la confirmation doivent être identiques."
                      )
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <div style={{ textAlign: "center" }}>
            <Space>
              {loaderChange && <Spin />}
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  backgroundColor: "#214082",
                  borderColor: "#214082",
                  marginRight: "10px",
                  width: "7rem",
                  marginTop: "1rem",
                }}
              >
                Enregistrer
              </Button>
            </Space>
           
          </div>
        </Form>
      </Modal>

      <Modal
        title={t("Veuillez entrer votre adresse e-mail")}
        visible={openModalForgetPassword}
        footer={null}
        width={600}
        onCancel={() => setOpenModalForgetPassword(false)}
      >
        <Form
          // form={form}
          layout="vertical"
          style={{ maxWidth: "600px", margin: "auto", paddingTop: "20px" }}
          onFinish={forgetPassword}
          disabled={loaderForget}
        >
          <Form.Item
            name="email_oublie"
            label="Adresse e-mail - البريد الإلكتروني"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              {
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t("Veuillez vérifier ce champ."),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div style={{ textAlign: "center" }}>
            <Space>
              {loaderForget && <Spin />}
              <Button
                htmlType="submit"
                type="primary"
                style={{
                  backgroundColor: "#214082",
                  borderColor: "#214082",
                  marginRight: "10px",
                  width: "7rem",
                  marginTop: "1rem",
                }}
              >
                {t("Valider")}
              </Button>
            </Space>
          </div>
        </Form>
      </Modal>

      {/* <Modal
        
        visible={openModalForgetPassword}
        footer={null}
        width={600}
        onCancel={() => setOpenModalForgetPassword(false)}
      >
        <Form onFinish={forgetPassword} form={form} layout="vertical">
          <Form.Item
            name="email_oublie"
            label="Adresse e-mail - البريد الإلكتروني"
            rules={[
              {
                required: true,
                message: t("Veuillez remplir ce champ."),
              },
              {
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t("Veuillez vérifier ce champ."),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Valider
          </Button>
        </Form>
      </Modal> */}
    </Row>
  );
};

export default SignIn;
