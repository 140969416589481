import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Card,
  Checkbox,
  notification,
  Radio,
} from "antd";
import { useTranslation } from "react-i18next";
import GlobalContext from "../context/GlobalContext";
import PhoneInput from "antd-phone-input";
import dayjs from "dayjs";
import i18next from "i18next";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Profile = (props) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const { form, gouvernorats, setLoader } = useContext(GlobalContext);
  const [nationalité, setNationalité] = useState("Tunisienne");
  const [delegations, setDelegations] = useState([]);
  const handleGouvernoratChange = (value) => {
    setDelegations(gouvernorats[value].delegations || []);
  };
  const validator = (_, { valid }) => {
    // if (valid(true)) return Promise.resolve(); // strict validation
    if (valid()) return Promise.resolve(); // non-strict validation
    return Promise.reject(t("Veuillez valider le numéro de téléphone."));
  };
  const { gouvernoratOptions, nationalityOptions } = props;
  const initialValues = JSON.parse(sessionStorage.getItem("-x-token-user"));
  const role = sessionStorage.getItem("role");
  const navigate = useNavigate();
  const niveauScolaire = [
    {
      value: "إبتدائي",
      label: "Primaire - إبتدائي",
    },
    {
      value: "ثانوي",
      label: "Secondaire - ثانوي",
    },
    {
      value: "جامعي",
      label: "Universitaire - جامعي",
    },
    {
      value: "تكوين مهني",
      label: "Formation professionnelle - تكوين مهني",
    },
  ];
  useEffect(() => {
    setLoader(true);
    axios
      .get(`${process.env.REACT_APP_GEOPROD_API}/get_user_with_id`, {
        headers: {
          Idsession: initialValues?.Acces_Token,
        },
      })
      .then((res) => {
        setLoader(false);
        form.setFieldsValue({
          email: res?.data?.user?.mail,
          lastName: res?.data?.user?.nom,
          lastName_ar: res?.data?.user?.nom_ar,
          firstName: res?.data?.user?.prenom,
          firstName_ar: res?.data?.user?.prenom_ar,
          gender: res?.data?.user?.civilite,
          nationality: res?.data?.user?.nationality,
          cin: res?.data?.user?.cin,
          passportNumber: res?.data?.user?.passport,
          birthPlace: res?.data?.user?.birthplace,
          birthDate: dayjs(res?.data?.user?.date_naiss_user),
          residenceGovernorate: res?.data?.user?.goverment,
          residenceDelegation: res?.data?.user?.delegation,
          adresse: res?.data?.user?.adresse,
          phone: res?.data?.user?.mobile,
          domaines_spetialite: res?.data?.user?.domaines_specialite,
          postal_code: res?.data?.user?.code_postal,
          niveau_scolaire: res?.data?.user?.niveau_scolaire,
          besoins_specifiques: res?.data?.user?.besoins_specifiques,
          delivrance_date: dayjs(
            res?.data?.user?.delivrance_date,
            "DD/MM/YYYY"
          ),
        });
      });
  }, []);

  const handleEdit = (values) => {
    setLoader(true);
    const phoneNumber =
      "+" +
      values.phone.countryCode +
      values.phone.areaCode +
      values.phone.phoneNumber;
    const payload = {
      adresse: {
        geom: "",
      },
      user: {
        acc_affaire: [],
        acc_opportunite: [],
        acces_avenant: 2,
        acces_communiques: 2,
        acces_courrier_entrant: 0,
        acces_distrib: 0,
        acces_edit_maileva: 2,
        acces_ett: true,
        acces_fusion: 2,
        acces_maileva: 2,
        acces_qualite: 2,
        acces_reclamation_ticket: 1,
        acces_signature: 0,
        act_grp_communication: 2,
        act_grp_negociateur: 2,
        act_grp_pubpostage: 2,
        act_grp_statut: 2,
        act_grp_tags: 2,
        active: 1,
        admin_restreint: 0,
        admin_tpv: 2,
        adresse: values.adresse,
        adresse_auto: "*",
        api_key: "",
        assistant: 0,
        beta: 0,
        birthplace: null,
        bloc_env: 0,
        cin: values.cin,
        civilite: values.gender,
        code: null,
        comparateur: 0,
        date_integration: null,
        date_naiss_user: dayjs(values.birthDate).format("YYYY-MM-DD"),
        delegation: values.residenceDelegation,
        ecrit_comp_aff: 0,
        ecriture_transaction_financiere_client: 2,
        ecriture_transaction_financiere_distribution: 2,
        entreprise_id:
          role === "investisseur"
            ? process.env.REACT_APP_ID_Entreprise_INVESTISSEUR
            : process.env.REACT_APP_ID_Entreprise_FORMATEUR,
        espace_distrib_access: '{"acces_info_spec": 2, "changement_statut": 2}',
        delivrance_date: dayjs(values.delivrance_date).format("DD/MM/YYYY"),
        execution_tpv: 2,
        exp_aff: null,
        exp_maileva: 2,
        exp_opp: null,
        export_aff_det: 0,
        export_maileva_det: 2,
        export_opp_det: 0,
        fiche_dec: 0,
        filtre: 0,
        first_login: null,
        goverment: values?.residenceGovernorate,
        groupe_commission: null,
        groupe_user: [],
        groupe_user_alert: [],
        groupe_user_alert_nom: [],
        heure_debut: "0:00:00",
        heure_fin: null,
        hist: 2,
        hist_comm: 2,
        id: 1002234,
        identite_reelle: null,
        lect_comp_aff: 0,
        lecture_tpv: 2,
        lecture_transaction_financiere_client: 2,
        lecture_transaction_financiere_distribution: 2,
        limit_listing_aff: "100",
        limit_listing_maileva: "2",
        limit_listing_opp: "100",
        location: null,
        mail: values.email,
        manager: null,
        manager_de: [],
        manager_de_nom: [],
        manager_list: [],
        manager_nom: [],
        matricule_rh: null,
        mobile: phoneNumber,
        nationality: values.nationality,
        nom: values.lastName,
        nom_ar: values.lastName_ar,
        nom_groupe_commission: null,
        nom_organisme: "",
        nom_producteur: null,
        nom_role: "",
        nom_zone: null,
        num_interne: null,
        orias_producteur: null,
        passport: null,
        password: "",
        photo: null,
        pointage: 0,
        prenom: values.firstName,
        prenom_ar: values.firstName_ar,

        prenom_producteur: null,
        proprietaire: true,
        qualite_producteur: null,
        role:
          role === "investisseur"
            ? process.env.REACT_APP_ID_ROLE_INVESTISSEUR
            : process.env.REACT_APP_ID_ROLE_FORMATEUR,
        role_auto: "[]",
        role_auto_nom: [],
        samedi_off: 0,
        signature: null,
        skype: null,
        ssl_mail_data: 0,
        super_admin: true,
        tab_code: [],
        tarificateur: 2,
        tel2: null,
        tel_urgence: null,
        telephone: phoneNumber,
        transaction_financiere: 2,
        type_user: "c81e728d9d4c2f636f067f89cc14862c",
        user_groupe_nom: [],
        user_pwd_data: null,
        validate_paiement: 2,
        domaines_specialite: values.domaines_spetialite,
        code_postal: values.postal_code,
        niveau_scolaire: values.niveau_scolaire,
        besoins_specifiques: values.besoins_specifiques,
        zone: null,
      },
      ville: {},
    };
    axios
      .put(
        `${process.env.REACT_APP_GEOPROD_API}/admin/update_user/${initialValues.id_user}`,
        payload,
        {
          headers: {
            Idsession: initialValues?.Acces_Token,
          },
        }
      )
      .then(() => {
        setLoader(false);
        notification.success({
          message: "Votre compte a été mis à jour avec succes",
          duration: 0,
        });
        navigate("/acceuil");
      })
      .catch(() => {
        setLoader(false);
        notification.error({
          message: "Erreur lors de la mise à jour du compte",
          duration: 0,
        });
      });
  };

  return (
    <Form
      layout="vertical"
      style={{ display: "flex", justifyContent: "center" }}
      form={form}
      onFinish={(values) => handleEdit(values)}
    >
      <Card style={{ width: "90%" }}>
        <Row gutter={16}>
          <Col lg={12} md={24}>
            <Form.Item
              label="Adresse e-mail - البريد الإلكتروني"
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  type: "email",
                  message: t("Veuillez vérifier ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Nom (En arabe)  - اللقب (بالعربية)"
              name="lastName_ar"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Prénom (En arabe) - الإسم (بالعربية)"
              name="firstName_ar"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Nom (En français)  - اللقب (بالفرنسية)"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input
                disabled={
                  form.getFieldValue("lastName") &&
                  form.getFieldValue("lastName") !== ""
                }
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Prénom (En français) - الإسم (بالفرنسية)"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input
                disabled={
                  form.getFieldValue("firstName") &&
                  form.getFieldValue("firstName") !== ""
                }
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Genre - الجنس"
              name="gender"
              rules={[
                {
                  required: true,
                  message: t("Veuillez sélectionner un genre."),
                },
              ]}
            >
              <Select
                disabled
                options={[
                  {
                    value: "ذكر",
                    label: "Masculin - ذكر",
                  },
                  {
                    value: "أنثى",
                    label: "Féminin - أنثى",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              disabled
              label="Nationalité - الجنسية"
              name="nationality"
              rules={[
                {
                  required: true,
                  message: t("Veuillez sélectionner une nationalité."),
                },
              ]}
            >
              <Select
                disabled
                onChange={(e) => setNationalité(e)}
                options={nationalityOptions}
              />
            </Form.Item>
          </Col>
          {nationalité === "Tunisienne" && (
            <Col lg={12} md={24}>
              <Form.Item
                label="Numéro CIN - رقم بطاقة التعريف الوطنية"
                name="cin"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input
                  maxLength={8}
                  onChange={(e) =>
                    form.setFieldValue(
                      "cin",
                      e.target.value.replace(/[^+0-9]/g, "")
                    )
                  }
                  disabled
                />
              </Form.Item>
            </Col>
          )}
          {nationalité !== "Tunisienne" && (
            <Col lg={12} md={24}>
              <Form.Item
                label={t("N° passeport")}
                name="passportNumber"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input
                  disabled
                  onChange={(e) =>
                    form.setFieldValue(
                      "passportNumber",
                      e.target.value.replace(/[^+0-9]/g, "")
                    )
                  }
                />
              </Form.Item>
            </Col>
          )}
          {nationalité !== "Tunisienne" && (
            <Col lg={12} md={24}>
              <Form.Item
                label={t("Date d’expiration du passeport")}
                name="passportExpiry"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  placeholder=""
                  disabledDate={(current) => {
                    // Disable future dates by checking if the current date is after today
                    return current && current > dayjs().endOf("day");
                  }}
                />
              </Form.Item>
            </Col>
          )}
          <Col lg={12} md={24}>
            <Form.Item
              label="Date de naissance - تاريخ الولادة"
              name="birthDate"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <DatePicker
                disabled
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                placeholder=""
                disabledDate={(current) => {
                  // Disable future dates by checking if the current date is after today
                  return current && current > dayjs().endOf("day");
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية"
              name="delivrance_date"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                placeholder=""
                disabledDate={(current) => {
                  return current && current > dayjs().endOf("day");
                }}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Adresse- العنوان"
              name="adresse"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label="Gouvernorat de résidence - ولاية الإقامة"
              name="residenceGovernorate"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select
                onChange={handleGouvernoratChange}
                options={gouvernoratOptions}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Délégation de résidence - معتمدية الإقامة"
              name="residenceDelegation"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select>
                {delegations?.map((delegation) => (
                  <Option key={delegation} value={delegation}>
                    {delegation}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Code postal - الترقيم البريدي"
              name="postal_code"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input maxLength={4} />
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label="Numéro de téléphone - رقم الهاتف"
              name="phone"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <PhoneInput
                country="tn"
                style={{
                  width: "100%",
                  direction:
                    localStorage.getItem("language") === "fr" ? "rtl" : "",
                }}
              />
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label="Niveau scolaire - المستوى الدراسي"
              name="niveau_scolaire"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select options={niveauScolaire} />
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label="Domaines de spécialité - مجالات التخصص"
              name="domaines_spetialite"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={8} md={24}>
            <Form.Item
              label="Personne à besoin spécifique - شخص ذو إحتياجات خصوصية "
              name="besoins_specifiques"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Radio.Group>
                <Radio value="نعم">Oui - نعم</Radio>
                <Radio value="لا">Non - لا</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Button type="primary" htmlType="submit">
              {t("Mettre à jours mes données")}
            </Button>
          </Col>

          {/* <Col lg={12} md={24}>
            <Form.Item
              label={t("Lieu de naissance")}
              name="birthPlace"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col> */}

          {/* <Col lg={12} md={24}>
            <Form.Item
              label={t("Adresse")}
              name="address"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Gouvernorat de résidence")}
              name="residenceGovernorate"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select onChange={handleGouvernoratChange}>
                {Object.keys(gouvernorats).map((gouvernorat) => (
                  <Option key={gouvernorat} value={gouvernorat}>
                    {gouvernorat}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Délégation de résidence")}
              name="residenceDelegation"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select>
                {delegations.map((delegation) => (
                  <Option key={delegation} value={delegation}>
                    {delegation}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Code postal")}
              name="postalCode"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label={t("Numéro de téléphone")}
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <PhoneInput
                country="tn" // Default country code
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Mot de passe")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Confirmer le mot de passe")}
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("Les mots de passe ne correspondent pas."))
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Personne à besoin spécifique")}
              name="specialNeeds"
              valuePropName="checked"
            >
              <Checkbox>{t("Aucun Personne à besoin spécifique")}</Checkbox>
            </Form.Item>
          </Col> */}
        </Row>
      </Card>
    </Form>
  );
};

export default Profile;
