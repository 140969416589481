import { Button, Checkbox } from "antd";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GlobalContext from "../context/GlobalContext";

const PdfViewer = ({ setConfirm }) => {
  const [scrollCompleted, setScrollCompleted] = useState(false);
  const {isRtl}=useContext(GlobalContext)
  const iframeRef = useRef();
  const { t } = useTranslation();
  // Function to detect scroll on iframe itself
  const handleIframeScroll = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      const { scrollTop, scrollHeight, clientHeight } = iframe;
      if (scrollTop + clientHeight >= scrollHeight) {
        setScrollCompleted(true);
      }
    }
  };

  return (
    <div 
    style={{ direction :isRtl ? 'rtl' : '' }}

    >
      <div
        onScroll={handleIframeScroll}
        style={{ height: "501px", overflowY: "scroll", marginBottom: "10px" }}
      >
        <iframe
          ref={iframeRef}
          border="0"
          src={`${process.env.PUBLIC_URL}/1404906613.pdf`}
          style={{ width: "100%", height: "500px", border: "none" }}
          title="PDF Viewer"
        />
      </div>
      <div>
        {/* * */}
        <Checkbox

          // type="primary"
          disabled={!scrollCompleted}
          onChange={(e) => {
            
            setConfirm(e.target.checked);
          }}
        >
          {t(
            "J'ai pris connaissance et accepté les termes du CDC relatifs à la création d'un EPF."
          )}
          {/* {t("Je confirme")} */}
        </Checkbox>
      </div>
    </div>
  );
};

export default PdfViewer;
