import React, { useEffect } from "react";
import { Survey } from "survey-react-ui";
import i18n from "../../../i18n";

const EditEpf = (props) => {
  const { surveyEdit } = props;

  useEffect(() => {
    surveyEdit.data = JSON.parse(sessionStorage.getItem("surveyData"));
    console.log(surveyEdit.data);
    
  }, []);

  return <Survey model={surveyEdit} locale={i18n.language} />;
};

export default EditEpf;
