import axios from "axios";
import { getDateParts } from "../utils";
import { notification } from "antd";
import i18n from "i18next";
import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";

export const checkCitizenByCinDn = async (birthDate, cin) => {
  try {
    const dateParts = getDateParts(birthDate);

    let payload = {
      cin,
      jourNaiss: dateParts.day,
      moisNaiss: dateParts.month,
      anneeNaiss: dateParts.year,
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_GEOPROD_API}/config/proxy/checkCitizenByCinDn`,
      {
        ...payload,
      }
    );

    if (data.codeR !== "1") {
      notification.error({
        message: i18n.t("Error !"),
        description: i18n.t("Le Couple CIN/Date de naissance est erroné"),
        duration: 0,
      });

      return false;
    }

    return true;
  } catch (error) {
    notification.error({
      message: i18n.t("Error !"),
      description: i18n.t(
        "L'API est temporairement indisponible. Veuillez réessayer dans quelques instants."
      ),
      duration: 0,
    });
    // throw error;
  }
};

export const checkCitizenStatusByCinDn = async (birthDate, cin) => {
  try {
    const isAlive = await checkCitizenByCinDn(birthDate, cin);

    if (!isAlive) {
      return isAlive;
    }

    const dateParts = getDateParts(birthDate);

    let payload = {
      cin,
      jourNaiss: dateParts.day,
      moisNaiss: dateParts.month,
      anneeNaiss: dateParts.year,
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_GEOPROD_API}/config/proxy/checkCitizenStatusByCinDn`,
      {
        ...payload,
      }
    );

    if (data.codeR !== "1") {
      notification.error({
        message: i18n.t("Error !"),
        description: i18n.t("Le Couple CIN/Date de naissance est erroné"),
        duration: 0,
      });

      return false;
    }

    return data;
  } catch (error) {
    notification.error({
      message: i18n.t("Error !"),
      description: i18n.t(
        "L'API est temporairement indisponible. Veuillez réessayer dans quelques instants."
      ),
      duration: 0,
    });
  }
};
