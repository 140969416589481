import { useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Select,
  Badge,
  Dropdown,
  Menu,
  Tooltip,
  Modal,
  List,
  Spin,
} from "antd";
import { BellOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../../context/GlobalContext";
import tunisie from "../../../assets/images/tunisia.png";
import classes from "./Header.module.css";
import { getNotifictaions, getSchema } from "../../../api/geoprod";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ subName, onPress,survey }) {
  const { Option } = Select;
  const { globalData, setGlobalData, setIsRtl, setLoader,setPorteDocs ,setPageN} =
    useContext(GlobalContext);
  const { i18n, t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const location = useLocation();

  // const [porteDoc, setPorteDocs] = useState([]);

  const navigate = useNavigate();
  // Fetch notifications when the component mounts
  useEffect(() => {
    const fetchNotifications = async () => {
      const data = await getNotifictaions();
      setNotifications(data.notifications);
      setUnreadCount(data.nbr_not_viewed);
    };
    fetchNotifications();
  }, []);

  // Handle language change
  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);

    if (value === "ar") {
      setIsRtl(true);
    } else {
      setIsRtl(false);
    }
  };

  const handleNotificationClick = async (notification) => {
//     setLoader(true);

//     const id_affaire = notification?.url?.split("/").pop();
//     const affaire = await getSchema(id_affaire);

//     if (location.pathname !== "/etablissements/save") {
//       navigate("/etablissements/save",{
//         state:{
//           id_affaire:id_affaire
//         }
//       });
//     }

//     axios
//       .get(
//         `${process.env.REACT_APP_GEOPROD_API}/list_taches_by_entity?id_entity=${id_affaire}&entity=A&Search=&limit=9&page=1&key=ENCOURS
// `,
//         {
//           headers: {
//             Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
//               .Acces_Token,
//           },
//         }
//       )
//       .then((res) => {
       
//         setPorteDocs(res?.data?.list_taches)
//         survey.data = JSON.parse(affaire?.response?.data);
//         survey.currentPageNo = survey.pages.length - 1;
//         setPageN(survey.pages.length - 1)
//         survey.pages.forEach((page) => {
//           page.questions.forEach((question) => {
//             question.readOnly = true;
//           });
//         });
//         survey.render();

//         setLoader(false);



//       })
//       .catch(() => {
//         setLoader(false);
//       });
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedNotification(null);
  };

  const notificationMenu = (
    <Menu style={{ width: "300px" }}>
      <h3 style={{ padding: "10px 16px" }}>{t("Notifications")}</h3>
      {notifications?.length > 0 ? (
        <List
          className="header-notifications-dropdown"
          itemLayout="vertical"
          dataSource={notifications}
          renderItem={(item) => (
            <Menu.Item
              key={item.id}
              style={{ marginBottom: "1rem", padding: "0.5rem",alignItems:"center",display:"flex",with:"100%" }}
            >
              <div style={{ display: "flex", flexDirection: "column",alignItems:"center",textAlign:"center",justifyContent:"center",with:"100%" }}>
                <Badge
                  status={item.nb_view === 0 ? "processing" : "default"}
                  text={<span >{t("Nouvelle notification")}</span>}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent:"center"
                  }}
                >
                  <div
                    style={{
                      marginTop: "5px",
                      color: "#1890ff",
                      fontSize: "12px",
                    }}
                  >
                    {item.date_notification} {item.heure_notification}
                  </div>
                  <Button
                    style={{
                      marginTop: "10px",
                      height: "1rem",
                      fontSize: "0.8rem",
                    }}
                    type="primary"
                    size="small"
                    onClick={() => handleNotificationClick(item)}
                  >
                    {/* {item.titre} */} {t("Accéder")}
                  </Button>
                </div>
              </div>
            </Menu.Item>
          )}
        />
      ) : (
        <Menu.Item>
          <div>{t("Aucune notification")}</div>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Row gutter={[24, 0]} className={classes.headerClass}>
        <Col span={24} md={6}>
          <Breadcrumb>
            <Breadcrumb.Item>{t("Page")}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="ant-page-header-heading">
            <span className="ant-page-header-heading-title">
              {subName === "etablissements/save"
                ? t("Création d'un nouvel EPF")
                : subName === "etablissements"
                ? t("Liste de mes EPFs")
                : subName === "demandes"
                ? t("Mes demandes")
                : subName === "etablissements/consult"
                ? t("Etablissement") + " EPF001"
                : subName === "brouillon"
                ? t("Brouillon")
                : subName === "formateurs"
                ? t("formateurs")
                : subName === "profile"
                ? t("profile")
                : subName === "etablissements/editEpf"
                ? t("Modifier Epf")
                : subName}
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          <img src={tunisie} style={{ height: "70px", marginInline: "1rem" }} />

          <Dropdown overlay={notificationMenu} trigger={["click"]}>
            <Badge count={unreadCount} overflowCount={99}>
              <Tooltip title={t("Notifications")}>
                <BellOutlined
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    marginInline:"1rem"

                  }}
                />
              </Tooltip>
            </Badge>
          </Dropdown>

          <Select
            style={{
              width: "8rem",
              display: "flex",
              alignItems: "center",
            }}
            onChange={handleLanguageChange}
            value={localStorage.getItem("language") || "fr"}
          >
            <Option value="fr">Français</Option>
            <Option value="ar">العربية</Option>
          </Select>
        </Col>
      </Row>

      {/* Notification Modal */}
      {selectedNotification && (
        <Modal
          title={selectedNotification.titre}
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
        >
          <div
            dangerouslySetInnerHTML={{ __html: selectedNotification.msg }}
          ></div>
          <small>
            {selectedNotification.date_notification} -{" "}
            {selectedNotification.heure_notification}
          </small>
        </Modal>
      )}
    </>
  );
}

export default Header;
