import { Form, notification } from "antd";
import GlobalContext from "./GlobalContext";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { getNotifictaions } from "../api/geoprod";
import { Model } from "survey-core";
import i18next from "i18next";

const AppContext = (props) => {
  const [globalData, setGlobalData] = useState(
    sessionStorage.getItem("globalData")
      ? JSON.parse(sessionStorage.getItem("globalData"))
      : {}
  );
  const [isRtl, setIsRtl] = useState(localStorage.getItem("language") === "ar");
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [porteDoc, setPorteDocs] = useState([]);
  const [pageN, setPageN] = useState();
  const [brouillon, setBrouillon] = useState([]);
  const gouvernorats = {
    "تونس - Tunis": {
      delegations: [
        " قرطاج - Carthage",
        "المدينة - La Medina",
        "باب البحر - Bab El Bhar",
        "باب سويقة - Bab Souika",
        "العمران - El Omrane",
        "العمران الأعلى - El Omrane Supérieur",
        "التحرير - Ettahrir",
        "المنزه - El Menzah",
        "حي الخضراء - Cité El Khadhra",
        "باردو - Le Bardo",
        "السيجومي - Sijoumi",
        "الزهور - Ezzouhour",
        "الحرائرية - El Hrairia",
        "سيدي حسين - Sidi Hassine",
        "الوردية - El Ouardia",
        "الكبارية - El Kabaria",
        "سيدي البشير - Sidi El Béchir",
        "جبل الجلود - Djebel Djelloud",
        "حلق الوادي - La Goulette",
        "الكرم - Le Kram",
        "المرسى - La Marsa",
      ],
    },
    "أريانة - Ariana": {
      delegations: [
        " أريانة المدينة - L'Ariana Ville",
        "سكرة - Soukra",
        "رواد - Raoued",
        "قلعة الأندلس - Kalaât El Andalous",
        "سيدي ثابت - Sidi Thabet",
        "حي التضامن - Cité Ettadhamen",
        "المنيهلة - El Mnihla",
      ],
    },
    "باجة - Bèja": {
      delegations: [
        " باجة الشمالية - Béja Nord",
        "باجة الجنوبية - Béja Sud",
        "عمدون - Amdoun",
        "نفزة - Nefza",
        "تبرسق - Teboursouk",
        "تيبار - Tibar",
        "تستور - Testour",
        "قبلاط - Goubellat",
        "مجاز الباب - Medjez El Bab",
      ],
    },

    "بن عروس - Ben Arous": {
      delegations: [
        "بن عروس - Ben Arous",
        "المدينة الجديدة - La Nouvelle Medina",
        "المروج - El Mourouj",
        "حمام الأنف - Hammam Lif",
        "حمام الشط - Hammam Chôtt",
        "بومهل البساتين - Bou Mhel El Bassatine",
        "الزهراء - Ezzahra",
        "رادس - Radès",
        "مقرين - Megrine",
        "المحمدية - Mohamedia",
        "فوشانة - Fouchana",
        "مرناق - Mornag",
      ],
    },
    "بنزرت - Bizerte": {
      delegations: [
        " بنزرت الشمالية - Bizerte Nord",
        "جرزونة - Zarzouna",
        "بنزرت الجنوبية - Bizerte Sud",
        "سجنان - Sedjnane",
        "جومين - Djoumine",
        "ماطر - Mateur",
        "غزالة - Ghezala",
        "منزل بورقيبة - Menzel Bourguiba",
        "تينجة - Tinja",
        "أوتيك - Utique",
        "غار الملح - Ghar El Meleh",
        "منزل جميل - Menzel Djemil",
        "العالية - El Alia",
        "رأس الجبل - Ras Djebel",
      ],
    },

    "قابس - Gabès": {
      delegations: [
        " قابس المدينة - Gabes Medina",
        "قابس الغربية - Gabes Ouest",
        "قابس الجنوبية - Gabes Sud",
        "غنوش - Ghanouch",
        "المطوية - El Metouia",
        "منزل الحبيب - Menzel El Habib",
        "الحامة - El Hamma",
        "مطماطة - Matmata",
        "مطماطة الجديدة - Nouvelle Matmata",
        "مارث - Mareth",
      ],
    },

    "جندوبة - Jendouba": {
      delegations: [
        " جندوبة - Jendouba",
        "جندوبة الشمالية - Jendouba Nord",
        "بوسالم - Bou Salem",
        "طبرقة - Tabarka",
        "عين دراهم - Ain Draham",
        "فرنانة - Fernana",
        "غارالدماء - Ghardimaou",
        "وادي مليز - Oued Meliz",
        "بلطة بوعوان - Balta - Bou Aouane",
      ],
    },
    "القيروان - Kairouan": {
      delegations: [
        " القيروان الشمالية - Kairouan Nord",
        "القيروان الجنوبية - Kairouan Sud",
        "الشبيكة - Echebika",
        "السبيخة - Sbikha",
        "الوسلاتية - EL Ouslatia",
        "حفوز - Haffouz",
        "العلاء - El Alâa",
        "حاجب العيون - Hajeb el Ayoun",
        "نصر الله - Nasrallah",
        "الشراردة - Echrarda",
        "بوحجلة - Bouhajla",
      ],
    },
    "القصرين - Kasserine": {
      delegations: [
        " القصرين الشمالية - Kasserine Nord",
        "القصرين الجنوبية - Kasserine Sud",
        "الزهور - Ezzouhour",
        "حاسي الفريد - Hassi Ferid",
        "سبيطلة - Sbeitla",
        "سبيبة - Sbiba",
        "جدليان - Djedeliane",
        "العيون - El Ayoun",
        "تالة - Thala",
        "حيدرة - Hidra",
        "فوسانة - Foussana",
        "فريانة - Feriana",
        "ماجل بلعباس - Majel Bel Abbès",
      ],
    },
    "قبلي - Kébili": {
      delegations: [
        " دوز الجنوبية - Kebili Sud",
        "دوز الشمالية - Kebeli Nord",
        "فوار - Souk El Ahed",
        "دوز الشمالية - Douz Nord",
        "دوز الجنوبية - Douz Sud",
        "سوق الاحد - Faouar",
      ],
    },
  
    "المهدية - Mahdia": {
      delegations: [
        " المهدية - Mahdia",
        "بومرداس - Bou Merdès",
        "أولاد شامخ - Ouled Chamekh",
        "شربان - Chorbane",
        "هبيرة - Hebira",
        "السواسي - Essouassi",
        "الجم - El Djem",
        "الشابة - Chebba",
        "ملولش - Melloulech",
        "سيدي علوان - Sidi Alouane",
        "قصورالساف - Ksour Essef",
      ],
    },

    "منوبة - Mannouba": {
      delegations: [
        " منوبة - Mannouba",
        "دوار هيشر - Douar Hicher",
        "وادي الليل - Oued Ellil",
        "المرناقية - Mornaguia",
        "برج العامري - Borj Amri",
        "الجديدة - Djedeida",
        "طبربة - Tebourba",
        "البطان - El Battane",
      ],
    },
    "نابل - Nabeul": {
      delegations: [
        " نابل - Nabeul",
        "دار شعبان - Dar Chaabane",
        "بني خيار - Beni khiar",
        "قربة - Korba",
        "منزل تميم - Menzel Temime",
        "الميدة - El Mida",
        "قليبية - Kelibia",
        "حمام الأغزاز - Hammam El Guezaz",
        "الهوارية - El Haouaria",
        "تاكلسة - Takelsa",
        "سليمان - Soliman",
        "منزل بوزلفة - Menzel Bouzelfa",
        "بني خلاد - Beni Khalled",
        "قرمبالية - Grombalia",
        "بوعرقوب - Bou Argoub",
        "الحمامات - Hammamet",
      ],
    },
    "زغوان - Zaghouan": {
      delegations: [
        " زغوان - Zaghouan",
        "الزريبة - Ez-Zeriba",
        "بئر مشارقة - Bir Mchergua",
        "الفحص - El Fahs",
        "الناظور - En-Nadhour",
        "صواف - Saouaf",
      ],
    },
    "الكاف - El Kef": {
      delegations: [
        " الكاف الغربية - Kef Ouest",
        "الكاف الشرقية - Kef Est",
        "نبر - Nebeur",
        "ساقية سيدي يوسف - Sakiet Sidi Youssef",
        "تاجروين - Tajerouine",
        "قلعة سنان - Kalâat Snan",
        "القلعة الخصباء - Kalâat Khasbah",
        "الجريصة - Djerissa",
        "القصور - El Ksour",
        "الدهماني - Dahmani",
        "السرس - Es-Sers",
      ],
    },
    "سليانة - Siliana": {
      delegations: [
        " سليانة الشمالية - Siliana Nord",
        "سليانة الجنوبية - Siliana Sud",
        "بوعرادة - Bou Arada",
        "ڨعفور - Gaâfour",
        "الكريب - El Krib",
        "بورويس - Bourouis",
        "مكثر - Makthar",
        "الروحية - Er-Rouhia",
        "كسرى - Kesra",
        "برقو - Bargou",
        "العروسة - El Aroussa",
      ],
    },
    "سوسة - Sousse": {
      delegations: [
        " سوسة المدينة - Sousse Medina",
        "سوسة الرياض - Sousse Riadh",
        "سوسة جوهرة - Sousse Jawhara",
        "سوسة سيدي عبد الحميد - Sousse Sidi Abdelhamid",
        "حمام سوسة - Hammam Sousse",
        "أكودة - Akouda",
        "القلعة الكبرى - Kalaâ Kebira",
        "سيدي بوعلي - Sidi Bou Ali",
        "هرقلة - Hergla",
        "النفيضة - Enfidha",
        "بوفيشة - Bouficha",
        "كندار - Kondar",
        "سيدي الهاني - Sidi El Héni",
        "مساكن - M’saken",
        "القلعة الصغرى - Kalaâ Seghira",
        "الزاوية - القصيبة - الثريات - Zaouia - Ksiba - Thrayet",
      ],
    },
    "المنستير - Monastir": {
      delegations: [
        " المنستير - Monastir",
        "الوردانين - Ouerdanine",
        "الساحلين - Sahline",
        "زرمدين - Zermadine",
        "بني حسان - Beni Hassen",
        "جمال - Jammel",
        "بنبلة - Bembla",
        "المكنين - Moknine",
        "البقالطة - Bekalta",
        "طبلبة - Teboulba",
        "قصر هلال - Ksar Helal",
        "قصيبة المديوني - Ksibet El Mediouni",
        "صيادة - لمطة - بوحجر - Sayada-Lamta Bou-Hjar",
      ],
    },
    "صفاقس - Sfax": {
      delegations: [
        " صفاقس المدينة - Sfax Ville",
        "صفاقس الغربية - Sfax Ouest",
        "ساقية الزيت - Sakiet Ezzit",
        "ساقية الدائر - Sakiet Eddaïer",
        "صفاقس الجنوبية - Sfax Sud",
        "طينة - Tina",
        "عقارب - Agareb",
        "جبنيانة - Djebeniana",
        "العامرة - El Amra",
        "الحنشة - El Hencha",
        "منزل شاكر - Menzel Chaker",
        "الغريبة - Ghraiba",
        "بئر علي بن خليفة - Bir ali Ben Kelifa",
        "الصخيرة - Skhira",
        "المحرس - Mahres",
        "قرقنة - Kerkenah",
      ],
    },
    "سيدى بوزيد - Sidi Bouzid": {
      delegations: [
        " سيدي بوزيد الغربية - Sidi Bouzid Ouest",
        "سيدي بوزيد الشرقية - Sidi Bouzid Est",
        "جلمة - Jilma",
        "سبالة أولاد عسكر - Cebalet Ouled Asker",
        "بئر الحفي - Bir El Hafey",
        "سيدي علي بن عون - Sidi Ali Ben Aoûn",
        "منزل بوزيان - Menzel Bouzaïenne",
        "المكناسي - Meknassy",
        "سوق الجديد - Souk Jedid",
        "المزونة - Mezzouna",
        "الرقاب - Regueb",
        "أولاد حفوز - Ouled Haffouz",
      ],
    },
    "مدنين - Medenine": {
      delegations: [
        " مدنين الشمالية - Medenine Nord",
        "مدنين الجنوبية - Medenine Sud",
        "بني خداش - Beni Khedech",
        "بنقردان - Ben Guerdane",
        "جرجيس - Zarzis",
        "جربة حومة السوق - Djerba Houmet Souk",
        "جربة ميدون - Djerba Midoun",
        "جربة أجيم - Djerba Ajim",
        "سيدي مخلوف - Sidi Makhloulf",
      ],
    },
    "تطاوين - Tataouine": {
      delegations: [
        " تطاوين الشمالية - Tataouine Nord",
        "تطاوين الجنوبية - Tataouine Sud",
        "الصمار - Smâr",
        "بئر الأحمر - Bir Lahmar",
        "غمراسن - Ghomrassen",
        "ذهيبة - Dhehiba",
        "رمادة - Remada",
      ],
    },
    "قفصة - Gafsa": {
      delegations: [
        " قفصة الشمالية - Gafsa Nord",
        "سيدي عيش - Sidi Aïch",
        "القصر - El Ksar",
        "قفصة الجنوبية - Gafsa Sud",
        "ام العرائس - Oum El Araies",
        "الرديف - Redeyef",
        "المتلوي - Metlaoui",
        "المضيلة - Mdhila",
        "القطار - EL Guetar",
        "بلخير - Belkhir",
        "السند - Sned",
      ],
    },

    "توزر - Tozeur": {
      delegations: [
        " توزر - Tozeur",
        "دقاش - Degach",
        "تمغزة - Tameghza",
        "نفطة - Nefta",
        "حزوة - Hazoua",
      ],
    },
  };

  const sousSecteur = {
    "الفلاحة - AGRICULTURE": [
      "البستنة - HORTICULTURE",
      "الزراعات الكبرى  - GRANDES CULTURES ",
      "خدمات فلاحية - SERVICES  AGRICOLES",
      "تربية الحيوانات  - ELEVAGE",
    ],
    "الصيد البحري وتربية الأحياء المائية - PECHE ET AQUACULTURE": [
      "الصيد البحري  - PECHE",
      "تربية الأحياء المائية - AQUACULTURE",
    ],
    "البناء والأشغال العمومية و توابعه  - BATIMENT, TRAVAUX PUBLICS ET ANNEXES":
      [
        "البناء      - BATIMENT",
        "الخشـــــــب و الأثـــــــــاث - BOIS ET AMEUBLEMENT",
        "التجــهيز الصحـي والتبريد وتكـييف الهـــواء      - SANITAIRE FROID ET CLIMATISATION",
        "الأشغال العمومية     - TRAVAUX PUBLICS ",
        "الأشغال العمومية     - TRAVAUX PUBLICS ",
        "المناجم والمقاطع     - MINES ET CARRIERES",
      ],
    "النسيج  والإكسـاء  - TEXTILE ET HABILLEMENT": [
      "النســـــــيج     - TISSAGE",
      "الخياطة  - CONFECTION",
      "الزرد - BONNETERIE",
    ],

    "الجلود و الأحذية  - CUIR ET CHAUSSURES": [
      "الأحذيـــــــة     - CHAUSSURES",
      "السكـــــــــافة     - MAROQUINERIE",
    ],
    "الآلية العامة و التركيب المعدني - MECANIQUE GENERALE ET CONSTRUCTION METALLIQUE":
      [
        "الصنـــــــــاعة الآلــــــية     - FABRICATION MECANIQUE ",
        "الصيـــــــانة الآليـــــــة  - MECANIQUE D'ENTRETIEN",
        "اللحــــــــــام      - SOUDURE",
        "التركـــــــيب المعـــــدني      - CONSTRUCTION METALLIQUE",
        "صنـــــــــاعة البلاستيـــــــــك      - PLASTURGIE ",
        "التنقـــــيـــــــب         - FORAGE ",
        "الآلية الدقيقة  - Mécanique de précision",
      ],
    "الكهرباء والإلكترونيك - ELECTRICITE ET ELECTRONIQUE": [
      "الكهرباء التقنية     - ELECTRO- TECHNIQUE",
      "الإلكترونيك - ELECTRONIQUE ",
      "الإتصـــــــــالات       - TELECOMMUNICATIONS",
    ],
    "الصناعات الغــــذائية  - AGRO-ALIMENTAIRE": [
      "الصناعات الغذائية - INDUSTRIES ALIMENTAIRES",
      "التغذية - ALIMENTATION",
    ],
    "النقل وسياقة وصيانة العربات ومعدات الأشغال العمومية والفلاحية - TRANSPORT, CONDUITE ET MAINTENANCE DES VEHICULES ET DES ENGINS DE TRAVAUX PUBLICS ET AGRICOLES":
      [
        "سيــــاقـة معــدات الحضـائـر     - CONDUITE DES ENGINS DE CHANTIER",
        "صيانة العربـــات والمحـركـات    - MAINTENANCE DES VEHICULES ET DES MOTEURS ",
        "صيانة معدات الحضائر والفلاحية     - MAINTENANCE DES ENGINS DE CHANTIERS ET AGRICOLES",
        "النقل      - TRANSPORT",
      ],
    "السياحة و الفندقة - TOURISME ET HOTELLERIE": [
      "السياحة والتـنـشـيـط     - TOURISME ET ANIMATION",
      "الفـنـدقــــة  والمطاعم     - HOTELLERIE ET RESTAURATION",
    ],
    "الحرف الفنية والتقليدية - METIERS D'ART ET DE L'ARTISANAT": [
      "حرف الجلود والأحذية - METIERS DU CUIR ET CHAUSSURES",
      "حرف الخشب - METIERS DU BOIS",
      "حرف الألياف النباتية - METIERS DES FIBRES VEGETALES",
      "اللباس التقليدي والتطريز - HABITS TRADITIONNELS ET BRODERIE",
      " حرف المصوغ  والفضيات- METIERS D'ORFEVRERIE ET D'ARGENTERIE",
      "حرف المعادن - METIERS DES METAUX",
      "حرف الطين والحجارة  - METIERS DE L'ARGILE ET DE LA PIERRE ",
      "حرف الزجاج - METIERS DE VERRERIE",
      "حرف مختلفة - METIERS DIVERS",
    ],
    "الوظائف الإدارية والتجارة والإعلامية - EMPLOIS DE BUREAU, COMMERCE ET INFORMATIQUE":
      [
        "الكــتـابة والمكتبية-SECRETARIAT ET BUREAUTIQUE",
        "التجارة  و المحـــــــاسبة    - COMMERCE ET COMPTABILITE",
        "الاعلامية  والوسائط المتعددة - INFORMATIQUE ET MULTIMEDIA",
      ],
    "الخدمات والصناعات المختلفة - SERVICES ET INDUSTRIES DIVERS": [
      "فنون الطباعة  - ARTS GRAPHIQUES",
      "الإختـصـاصات شـــبه الطــــبيّة والصناعات الصيدلية - SPÉCIALITES PARA - MEDICALES ET INDUSTRIES PHARMACEUTIQUES",
      "الحلاقة والتجميل - COIFFURE ET ESTHETIQUE",
      "خدمــــــــــات متــفـرقـة - SERVICES DIVERS",
      "مهن الثقافة - METIERS DE LA CULTURE",
      "صناعات أخرى - AUTRES INDUSTRIES",
    ],
  };

  const spetialite_diplome = {
    "البستنة - HORTICULTURE": [
      {
        spetialite:
          "عامل  في  العناية بالحدائق - Ouvrier dans l'entretien des jardins",
        diplome: "Certificat de compétence (CC) - شهادة المهارة",
      },
      {
        spetialite:
          "عامل في انتاج الزهور - Ouvrier dans la production des fleurs coupées",
        diplome: "Certificat de compétence (CC) - شهادة المهارة",
      },
      {
        spetialite:
          "عامل في زراعة نباتات الزينة - Ouvrier en cultures ornementales",
        diplome: "Certificat de compétence (CC) - شهادة المهارة",
      },
      {
        spetialite:
          "عامل في زراعة الخضروات الحقلية - Ouvrier en cultures maraîchères de plein champ",
        diplome: "Certificat de compétence (CC) - شهادة المهارة",
      },
      {
        spetialite:
          "عامل في زراعة الخضروات المحمية - Ouvrier en cultures maraîchères protégées",
        diplome: "Certificat de compétence (CC) - شهادة المهارة",
      },
      {
        spetialite:
          "عامل في إنتاج الشتلات النباتية - Ouvrier dans la production des plants Horticoles",
        diplome: "Certificat de compétence (CC) - شهادة المهارة",
      },
      {
        spetialite:
          "عامل في العناية ببستان أشجار مثمرة - Ouvrier dans l'entretien des vergers",
        diplome: "Certificat de compétence (CC) - شهادة المهارة",
      },
      {
        spetialite:
          "عامل في إنتاج وصيانة العشب - Ouvrier dans la production et l'entretien des gazons",
        diplome: "Certificat de compétence (CC) - شهادة المهارة",
      },
      {
        spetialite: "عامل في انتاج الفطريات - Ouvrier en myciculture",
        diplome: "Certificat de compétence (CC) - شهادة المهارة",
      },
      {
        spetialite:
          "عون تعهد وتهيئة المساحات الخضراء - Agent d'entretien et d'aménagement des espaces verts",
        diplome:
          "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
      },
      {
        spetialite:
          "عامل فلاحي في زراعة نباتات الزينة وتهيئة المناطق الخضراء  - Ouvrier agricole en cultures ornementales et travaux paysagers",
        diplome:
          "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
      },
      {
        spetialite:
          "عامل فلاحي في زراعة وإنتاج الأشجار المثمرة - Ouvrier agricole en arboriculture fruitière",
        diplome:
          "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
      },
      {
        spetialite:
          "عامل فلاحي  في الزراعات الواحاتية - Ouvrier agricole en culture oasienne",
        diplome:
          "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
      },
      {
        spetialite:
          "عامل فلاحي  في زراعة الخضروات - Ouvrier agricole en cultures maraîchères",
        diplome:
          "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
      },
      {
        spetialite:
          "تقني في زراعة الخضروات - Technicien en cultures maraîchères",
        diplome:
          "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني",
      },
      {
        spetialite:
          "تقني في زراعة وإنتاج الأشجار المثمرة - Technicien en arboriculture fruitière",
        diplome:
          "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني",
      },
      {
        spetialite:
          "تقني في الزراعات الواحاتية - Technicien en cultures oasiennes",
        diplome:
          "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني",
      },
      {
        spetialite:
          "تقني في زراعة نباتات الزينة وتهيئة المناطق الخضراء - Technicien en cultures ornementales et travaux paysagers",
        diplome:
          "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني",
      },
    ],
    "الزراعات الكبرى  - GRANDES CULTURES ": [
      {
        spetialite:
          "عامل في إنتاج الحبوب والأعلاف - Ouvrier en cultures céréalières et fourragères",
        diplome: "Certificat de compétence (CC) - شهادة المهارة",
      },
      {
        spetialite:
          "عامل فلاحي في الزراعات الكبرى - Ouvrier agricole en grandes cultures",
        diplome:
          "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
      },
      {
        spetialite: "تقني في الزراعات الكبرى - Technicien en grandes cultures",
        diplome:
          "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني",
      },
    ],
  };

  const codes_communes = {
    "تونس - Tunis": {
      communes: {
        "تونس العاصمة - Tunis Ville": "1111",
        "باردو - Le Bardo": "1112",
        "الكرم - Le Kram": "1113",
        "حلق الوادي - La Goulette": "1114",
        " قرطاج - Carthage": "1115",
        "سيدي بوسعيد - Sidi Bou Said": "1116",
        "المرسى - La Marsa": "1117",
        "سيدي حسين - Sidi Hassine": "1118",
      },
    },
    "أريانة - Ariana": {
      communes: {
        "أريانة - Ariana": "1211",
        "سكرة - Soukra": "1212",
        "رواد - Raoued": "1213",
        "قلعة الأندلس - Kalaât El Andalous": "1214",
        "سيدي ثابت - Sidi Thabet": "1215",
        "التضامن - Ettadhamen": "1216",
        "المنيهلة - El Mnihla": "1217",
      },
    },
    "باجة - Bèja": {
      communes: {
        "باجة - Bèja": "2111",
        "المعقولة - El Mâagoula": "2112",
        "زهرة مدين - Zahret Medien": "2113",
        "نفزة - Nefza": "2114",
        "تبرسق - Teboursouk": "2115",
        "تستور - Testour": "2116",
        "قبلاط - Goubellat": "2117",
        "مجاز الباب - Medjez El Bab": "2118",
        "تيبار - Tibar": "2119",
        "وشتاتة - الجميلة - Ouechtata - El Jamila": "2120",
        "سيدي إسماعيل - Sidi Ismail": "2121",
        "السلوقية - Slouguia": "2122",
      },
    },

    "بن عروس - Ben Arous": {
      communes: {
        "بن عروس - Ben Arous": "1311",
        "المروج - El Mourouj": "1312",
        "حمام الأنف - Hammam Lif": "1313",
        "حمام الشط - Hammam Chôtt": "1314",
        "بومهل البساتين - Bou Mhel El Bassatine": "1315",
        "الزهراء - Ezzahra": "1316",
        "رادس - Radès": "1317",
        "مقرين - Megrine": "1318",
        "المحمدية - Mohamedia": "1319",
        "مرناق - Mornag": "1320",
        "الخليدية - Khelidia": "1321",
        "فوشانة - Fouchana": "1322",
        "نعسان - Naassen": "1323",
      },
    },
    "بنزرت - Bizerte": {
      communes: {
        "بنزرت - Bizerte": "1711",
        "سجنان - Sedjnane": "1712",
        "ماطر - Mateur": "1713",
        "منزل بورقيبة - Menzel Bourguiba": "1714",
        "تينجة - Tinja": "1715",
        "غار الملح - Ghar El Meleh": "1716",
        "عوسجة - Ousja": "1717",
        "منزل جميل - Menzel Djemil": "1718",
        "منزل عبد الرحمان - Menzel Abderrahmen": "1719",
        "العالية - El Alia": "1720",
        "راس الجبل - Ras El Djebel": "1721",
        "الماتلين - Metline": "1722",
        "رفراف - Rafraf": "1723",
        "أوتيك - Utique": "1724",
        "جومين - Joumine": "1725",
        "غزالة - Ghzala": "1726",
        "الحشاشنة - El Hchachna": "1727",
      },
    },

    "قابس - Gabès": {
      communes: {
        "قابس - Gabes": "5111",
        "شنني النحال - Chenini Nahal": "5112",
        "غنوش - Ghanouch": "5113",
        "المطوية - El Metouia": "5114",
        "وذرف - Ouedhref": "5115",
        "الحامة - El Hamma": "5116",
        "مطماطة - Matmata": "5117",
        "مطماطة الجديدة - Nouvelle Matmata": "5118",
        "مارث - Mareth": "5119",
        "الزرات - Ezzarat": "5120",
        "منزل الحبيب - Menzel El Habib": "5121",
        "بوشمة - Bou-chemma": "5122",
        "تبلبو - Teboulbou": "5123",
        "الحامة الغربية(الحبيب ثامر بوعطوش) - El Hamma Ouest(habib thameur bou attouch)":
          "5124",
        "كتانة - Kattana": "5125",
        "دخيلة توجان - Dkhilet Toujane": "5126",
      },
    },
    "جندوبة - Jendouba": {
      communes: {
        "جندوبة - Jendouba": "2211",
        "بوسالم - Bou Salem": "2212",
        "طبرقة - Tabarka": "2213",
        "عين دراهم - Ain Draham": "2214",
        "فرنانة - Fernana": "2215",
        "بني مطير - Beni M'tir": "2216",
        "غار الدماء - Ghardimaou": "2217",
        "وادي مليز - Oued Meliz": "2218",
        "بلطة بوعوان - Balta -Bouaouene": "2219",
        "سوق السبت - Souk Es-Sabet": "2220",
        "القلعة - المعدن - فرقصان - El Golaa - El Maaden -Forgassen": "2221",
        "الجواودة - Jaouaouada": "2222",
        "طبرقة الجديدة (عين الصبح - الناظور) - Tabarka jadida (Ain Es-Sobh - Nadhour)":
          "2223",
        "حمام بورقيبة (الخمايرية) - Hammam Bourguiba (Khemairia)": "2224",
      },
    },

    "القصرين - Kasserine": {
      communes: {
        "القصرين - Kasserine": "4211",
        "سبيطلة - Sbeitla": "4212",
        "سبيبة - Sbiba": "4213",
        "جدليان - Djedeliane": "4214",
        "تالة - Thala": "4215",
        "حيدرة - Hidra": "4216",
        "فوسانة - Foussana": "4217",
        "فريانة - Feriana": "4218",
        "تلابت - Telept": "4219",
        "ماجل بالعباس - Majel Bel Abbes": "4220",
        "العيون - El Ayoun": "4221",
        "حاسي الفريد - Hassi El Farid": "4222",
        "الزهور - Ez-Zouhour": "4223",
        "النور - En-Nour": "4224",
        "الشرائع - مشرق الشمس - Ech-Charayaa- Machrek Ech-Chams": "4225",
        "الرخمات - Er-Rakhmet": "4226",
        "عين الخمايسية - Ain El Khemaissia": "4227",
        "خمودة - Khmouda": "4228",
        "بوزقام - Bouzguam": "4229",
      },
    },
    "القيروان - Kairouan": {
      communes: {
        "القيروان - Kairouan": "4111",
        "الشبيكة - Echbika": "4112",
        "السبيخة - Sbikha": "4113",
        "الوسلاتية - El Oueslatia": "4114",
        "عين جلولة - Aïn Djeloula": "4115",
        "حفوز - Haffouz": "4116",
        "العلا - El Alâa": "4117",
        "حاجب العيون - Hajeb El Ayoun": "4118",
        "نصر اللّة - Nasrallah": "4119",
        "منزل مهيري - Menzel Mehiri": "4120",
        "الشراردة - Echrarda": "4121",
        "بوحجلة - Bouhajla": "4122",
        "سيسب - الذريعات - Sisseb-Ed-Dheriaat": "4123",
        "جهينة - Jehina": "4124",
        "رقادة - Rakada": "4125",
        "عبيدة - Abida": "4126",
        "الشوايحية (الشرايطية القصور) - Chwayhia (Cheraitia El Ksour)": "4127",
        "العين البيضاء - El Aîn El Beidha": "4128",
        "الشواشي - Chaouachi": "4129",
      },
    },
    "المهدية - Mahdia": {
      communes: {
        "المهدية - Mahdia": "3311",
        "رجيش - Réjiche": "3312",
        "بومرداس - Bou Merdes": "3313",
        "أولاد الشامخ - Ouled Chamekh": "3314",
        "شربان - Chorbane": "3315",
        "هبيرة - Hebira": "3316",
        "السواسي - Essouassi": "3317",
        "الجم - El Djem": "3318",
        "كركر - Kerker": "3319",
        "الشابة - Chebba": "3320",
        "ملوش - Melloulech": "3321",
        "سيد علوان - Sidi Alouane": "3322",
        "قصور الساف - Ksour Essef": "3323",
        "البرادعة - El Bradâa": "3324",
        "سيدي زيد - أولاد مولاهم - Sidi Zid-Ouled Moulahem": "3325",
        "الحكايمة - El Hekaima": "3326",
        "التلالسة - Talalsa": "3327",
        "زالبة - Zalba": "3328",
      },
    },

    "منوبة - Mannouba": {
      communes: {
        "منوبة - Mannouba": "1411",
        "الدندان - Denden": "1412",
        "دوار هيشر - Douar Hicher": "1413",
        "وادي الليل - Oued Ellil": "1414",
        "المرناقية - Mornaguia": "1415",
        "برج العامري - Borj Amri": "1416",
        "الجديدة - Djedeida": "1417",
        "طبربة - Tebourba": "1418",
        "البطان - El Battane": "1419",
        "البساتين - El Bassatine": "1420",
      },
    },

    "نابل - Nabeul": {
      communes: {
        " نابل - Nabeul": "1511",
        "دارشعبان الفهري - Dar Châabane El Fehri": "1512",
        "بني خيار - Beni khiar": "1513",
        "المعمورة - El Mâamoura": "1514",
        "الصمعة - Somaâ": "1515",
        "قربة - Korba": "1516",
        "تازركة - Tazeka": "1517",
        "منزل تميم - Menzel Temime": "1518",
        "منزل حر - Menzel Horr": "1519",
        "الميدة - El Mida": "1520",
        "قليبية - Kelibia": "1521",
        "أزمور - Azmour": "1522",
        "حمام الأغزاز - Hammam El Guezaz": "1523",
        "دار علوش - Dar Allouch": "1524",
        "الهوارية - El Haouaria": "1525",
        "تاكلسة - Takelsa": "1526",
        "سليمان - Soliman": "1527",
        "قربص - Korbous": "1528",
        "منزل بوزلفة - Menzel Bouzelfa": "1529",
        "بني خلاد - Beni Khalled": "1530",
        "زاوية الجديدي - Zaouiet Djedidi": "1531",
        "قرنبالية - Grombalia": "1532",
        "بوعرقوب - Bou Argoub": "1533",
        "الحمامات - Hammamet": "1534",
        "فندق الجديد - سلتان - Fondek Ejedid - Es Selten": "1535",
        "تزغران بوكريم زاوية المقايز - Tazegrane Boukrim Zaouiet Mgaiez":
          "1536",
        "سيدي الجديدي - Sidi Jedidi": "1537",
        "الشريفات بوشراي - Echerifet Boucharray": "1538",
      },
    },
    "زغوان - Zaghouan": {
      communes: {
        " زغوان - Zaghouan": "1611",
        "الزريبة - Ez-Zeriba": "1612",
        "بئر مشارقة - Bir Mchergua": "1613",
        "جبل الوسط - Jebel Oust": "1614",
        "الفحص - El Fahs": "1615",
        "الناظور - En-Nadhour": "1616",
        "صواف - Saouaf": "1617",
        "العمايم - El Amaiem": "1618",
      },
    },

   
    "الكاف - El Kef": {
      communes: {
        "الكاف - Le Kef": "2311",
        "نبر - Nebeur": "2312",
        "الطويرف - Touiref": "2313",
        "ساقية سيدي يوسف - Sakiet Sidi Youssef": "2314",
        "تاجروين - Tajerouine": "2315",
        "منزل سالم - Menzel Salem": "2316",
        "قلعة السنان - Kalâat Snan": "2317",
        "القلعة الخصباء - Kalâat Khasba": "2318",
        "الجريصة - Djerissa": "2319",
        "القصور - El Ksour": "2320",
        "الدهماني - Dahmani": "2321",
        "السرس - Es-Sers": "2322",
        "الزعفران - دير الكاف - Zâafrane - Dyr El Kef": "2323",
        "بهرة - Bahra": "2324",
        "المرجى - El Marja": "2325",
      },
    },
    "سليانة - Siliana": {
      communes: {
        "سليانة - Siliana": "2411",
        "بوعرادة - Bou Arada": "2412",
        "قعفور - Gaâfour": "2413",
        "الكريب - El Krib": "2414",
        "بورويس - Bourouis": "2415",
        "مكثر - Makthar": "2416",
        "الروحية - Er-Rouhia": "2417",
        "كسرى - Kesra": "2418",
        "برقو - Bargou": "2419",
        "العروسة - El Aroussa": "2420",
        "سيدي مرشد - Sidi Morched": "2421",
        "الحبابسة - El Hababsa": "2422",
      },
    },
    "سوسة - Sousse": {
      communes: {
        "سوسة - Sousse": "3111",
        "قصيبة سوسة الثريات - Ksibet Sousse Thrayet": "3112",
        "الزهور - Ezzouhour": "3113",
        "زاوية سوسة - Zaouiet Sousse": "3114",
        "حمام سوسة - Hammam Sousse": "3115",
        "أكودة - Akouda": "3116",
        "القلعة الكبيرة - Kalâa Kébira": "3117",
        "سيدي بو علي - Sidi Bou Ali": "3118",
        "هرقلة - Hergla": "3119",
        "النفيضة - Enfidha": "3120",
        "بوفيشة - Bouficha": "3121",
        "سيدي الهاني - Sidi El Heni": "3122",
        "مساكن - M'Saken": "3123",
        "القلعة الصغيرة - Kalâa Seghira": "3124",
        "المسعدين - Messâdine": "3125",
        "كندار - Kandar": "3126",
        "القريمات هيشر - Gerimet Hicher": "3127",
        "شط مريم - Chott Meriam": "3128",
      },
    },
    "المنستير - Monastir": {
      communes: {
        "المنستير - Monastir": "3211",
        "خنيس - Khenis": "3212",
        "الوردانين - Ouerdanine": "3213",
        "الساحلين معتمر - Sahline Môotmar": "3214",
        "سيدي عامر - Sidi Ameur": "3215",
        "زرمدين - Zermadine": "3216",
        "بني حسان - Beni Hassen": "3217",
        "غنادة - Ghnada": "3218",
        "جمال - Jamel": "3219",
        "منزل كامل - Menzel Kamel": "3220",
        "زاوية قنطش - Zouiet Kontoch": "3221",
        "بنبلة والمنارة - Bembla et Manara": "3222",
        "منزل نور - Menzel En-Nour": "3223",
        "المصدور - EL Masdour": "3224",
        "مكنين - Moknine": "3225",
        "سيدي بنور - Sidi Bennour": "3226",
        "منزل فارسي - Menzel Farsi": "3227",
        "عميرة الفحول - Amiret El Fhoul": "3228",
        "عميرة الطوازرة - Amiret Touazra": "3229",
        "عميرة الحجاج - Amirat Hojjaj": "3230",
        "الشراحيل - Cherahil": "3231",
        "البقالطة - Bekalta": "3232",
        "طبلبة - Teboulba": "3233",
        "قصر هلال - Ksar Helal": "3234",
        "قصيبة المديوني - Ksibt El Mediouni": "3235",
        "بنان بوضر - Benen Bodher": "3236",
        "طوزة - Touza": "3237",
        "صيادة - Sayada": "3238",
        "لمطة - Lamta": "3239",
        "بوحجر - Bou-Hajar": "3240",
        "منزل حياة - Menzel Hayet": "3241",
      },
    },
    "صفاقس - Sfax": {
      communes: {
        "صفاقس - Sfax": "3411",
        "ساقية الزيت - Sakiet Ezzit": "3412",
        "الشيحية - Chihia": "3413",
        "ساقية الدائر - Sakiet Eddaier": "3414",
        "قرمدة - Gremda": "3415",
        "العين - El ain": "3416",
        "طينة - Tina": "3417",
        "عقارب - Agareb": "3418",
        "جبنيانة - Djebeniana": "3419",
        "الحنشة - El Hencha": "3420",
        "منزل شاكر - Menzel Chaker": "3421",
        "الغريبة - El Gheraiba": "3422",
        "بئر علي بن خليفة - Bir Ali Ben Khelifa": "3423",
        "الصخيرة - Skhira": "3424",
        "المحرس - Mahrès": "3425",
        "قرقنة - Kerkennah": "3426",
        "العامرة - El Amra": "3427",
        "العوابد - الخزانات - El Aouabed-Khazanet": "3428",
        "بئر علي الشمالية (الناظور - سيدي علي بالعابد) - Bir Ali Nord (En-Nadhour-Sidi Ali Belabed)":
          "3429",
        "الحاجب - El Hajeb": "3430",
        "الحزق - اللوزة - Hzag-Ellouza": "3431",
        "النور(بوجربوع العوادنة ماجل الدرج) - En-Nour(boujarbou-el awadna-majel dorj)":
          "3432",
        "النصر - En-Nacer": "3433",
      },
    },
    "سيدى بوزيد - Sidi Bouzid": {
      communes: {
        "سيدي بوزيد - Sidi Bouzid": "4311",
        "جلمة - Jilma": "4312",
        "سبالة - Cebalet": "4313",
        "بئر الحفي - Bir El Hafey": "4314",
        "سيدي علي بن عون - Sidi Ali Ben Aoun": "4315",
        "منزل بوزيان - Menzel Bouzaine": "4316",
        "المكناسي - Meknassy": "4317",
        "المزونة - Mezzouna": "4318",
        "الرقاب - Regueb": "4319",
        "أولاد حفوز - Ouled Haffouz": "4320",
        "السعيدة - Essaaida": "4321",
        "سوق الجديد - Souk Jedid": "4322",
        "الأسودة - EL Assouada": "4323",
        "الفائض بنور - Faiedh Bennour": "4324",
        "باطن الغزال - Batten El Ghézel": "4325",
        "رحال - Rahal": "4326",
        "المنصورة - El Mansoura": "4327",
      },
    },
    "مدنين - Medenine": {
      communes: {
        "مدنين - Mednine": "5211",
        "بني خداش - Beni Khedache": "5212",
        "بن قردان - Ben Guerdane": "5213",
        "جرجيس - Zarzis": "5214",
        "جربة حومة السوق - Djerba Houmet Souk": "5215",
        "جربة ميدون - Djerba Midoun": "5216",
        "جربة أجيم - Djerba Ajim": "5217",
        "سيدي مخلوف - Sidi Makhlouf": "5218",
        "جرجيس الشمالية - Zarzis Nord": "5219",
        "بوغرارة - Bou-Ghrara": "5220",
      },
    },
    "تطاوين - Tataouine": {
      communes: {
        "تطاوين - Tataouine": "5311",
        "بئر الأحمر - Bir Lahmar": "5312",
        "غمراسن - Ghomrassen": "5313",
        "ذهيبة - Dhehiba": "5314",
        "رمادة - Remada": "5315",
        "الصمار - Smar": "5316",
        "تطاوين الجنوبية - Tataouine Sud": "5317",
      },
    },
    "قفصة - Gafsa": {
      communes: {
        "قفصة - Gafsa": "6111",
        "القصر - El Ksar": "6112",
        "أم العرائس - Oum El Araies": "6113",
        "الرديف - Redeyef": "6114",
        "المتلوي - Metlaoui": "6115",
        "المظيلة - Mdhila": "6116",
        "القطار - Guetar": "6117",
        "السند - Sned": "6118",
        "بلخير - Belkhir": "6119",
        "سيدي عيش - Sidi Aïch": "6120",
        "لالة - Lala": "6121",
        "سيدي بوبكر - Sidi Boubaker": "6122",
        "زانوش - Zanouch": "6123",
      },
    },
    "توزر - Tozeur": {
      communes: {
        "توزر - Tozeur": "6211",
        "دقاش - De guech": "6212",
        "حامة الجريد - Hamet Jerid": "6213",
        "نفطة - Nefta": "6214",
        "تمغزة - Tameghza": "6215",
        "حزوة - Hazoua": "6216",
      },
    },
    "قبلي - Kébili": {
      communes: {
        "قبلي - Kebili": "6311",
        "جمنة - Jemna": "6312",
        "دوز - Douz": "6313",
        "القلعة - El Golâa": "6314",
        "سوق الأحد - Souk El Ahad": "6315",
        "الفوار - Faouar": "6316",
        "رجيم معتوق - Rejim Maatoug": "6317",
        "بشلي - جرسين - البليدات - Bechelli-Jersine-El Blidete": "6318",
        "بشري - فطناسة - Bèchri-Fatnassa": "6319",
      },
    },
  };

  useEffect(() => {
    const language = localStorage.getItem("Language")
      ? localStorage.getItem("Language")
      : "fr";
    localStorage.setItem("language", language);
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        globalData,
        setGlobalData,
        form,
        isRtl,
        setIsRtl,
        gouvernorats,
        loader,
        setLoader,
        porteDoc,
        setPorteDocs,
        pageN,
        setPageN,
        sousSecteur,
        spetialite_diplome,
        codes_communes,
        brouillon, setBrouillon
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
