import { Button, Col, Form, Input, Modal, Radio, Row } from "antd";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import { useTranslation } from "react-i18next";

const SelectionModal = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { isModalVisible, setIsModalVisible } = props;
  const {t}=useTranslation()
const {form}=useContext(GlobalContext)
  const navigate = useNavigate();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        navigate("/etablissements/save", {
          state: {
            selectedOption: values.EPFType, 
          },
        });
        setIsModalVisible(false);
      })
      .catch((errorInfo) => {
      });
  };

  return (
    <Modal
      title={t("Sélectionnez la situation d'EPF")}
      visible={isModalVisible}
      onOk={handleOk}
      footer={false}
      onCancel={handleCancel}
      okButtonProps={{ disabled: !selectedOption }} // Disable OK button if no option is selected
      style={{
        direction: localStorage.getItem("language") === "ar" ? "rtl" : "ltr",
      }}
    >
      <Form form={form} layout="vertical" >
        <Form.Item
          name="EPFType"
          rules={[
            { required: true, message: t("Veuillez remplir ce champ.") },
          ]}
        >
          <Radio.Group onChange={handleOptionChange} value={selectedOption}>
            <Radio value="new">{t("Nouvel EPF")}</Radio>
            <Radio value="existing">{t("EPF existant")}</Radio>
          </Radio.Group>
        </Form.Item>

        {selectedOption === "existing" && (
          <Form.Item label={t("Numéro d'enregistrement")} required>
            <Row gutter={8}>
              <Col span={6}>
                <Form.Item
                  name="part1"
                  rules={[{ required: true, message: "Field is required" }]}
                >
                  <Input maxLength={2} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="part2"
                  rules={[{ required: true, message: "Field is required" }]}
                >
                  <Input maxLength={2} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="part3"
                  rules={[{ required: true, message: "Field is required" }]}
                >
                  <Input maxLength={4} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="part4"
                  rules={[{ required: true, message: "Field is required" }]}
                >
                  <Input maxLength={2} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        )}
      </Form>
      <div style={{ textAlign: "center" }}>
        
            <Button
             onClick={handleOk}
              type="primary"
              style={{
                backgroundColor: "#214082",
                borderColor: "#214082",
                marginRight: "10px",
                width: "7rem",
              }}
            >
              {t("Valider")}
            </Button>
          
          <Button
            style={{
              backgroundColor: "#E2E9F7",
              color: "#214082",
              border: "#9BACCF 1px solid",
              marginInline: "0.5rem",
              width: "7rem",
            }}
            onClick={handleCancel}
          >
            {t("Annuler")}
          </Button>
        </div>
    </Modal>
  );
};

export default SelectionModal;
