import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import { StrictMode } from "react";

import Rtl from "./pages/Formateurs";
import Profile from "./pages/Profile";
import SignUp from "./pages/Signup/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import Main from "./components/layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import AppContext from "./context/AppContext";
import Etablissements from "./pages/Etablissements/Etablissements";
import CreateEpf from "./pages/Etablissements/CreateEpf/CreateEpf";
import { useState } from "react";
import Demandes from "./pages/Demandes/Demandes";
import Brouillons from "./pages/Brouillons/Brouillons";
import PdfViewer from "./pages/PdfViewer";
import i18next, { t } from "i18next";
import { Model } from "survey-core";
import { uploadDocumentAffaire } from "./api/geoprod";
import i18n from "./i18n";
import $ from "jquery";
import * as SurveyCore from "survey-core";
import { jqueryuidatepicker } from "surveyjs-widgets";
import "jquery-ui-dist/jquery-ui.css";
import dayjs from "dayjs";
import EditEpf from "./pages/Etablissements/EditEpf/EditEpf";

window["$"] = window["jQuery"] = $;
require("jquery-ui-dist/jquery-ui.js");

jqueryuidatepicker(SurveyCore);

function App() {
  const [role, setRole] = useState(sessionStorage.getItem("role"));
  const [token, setToken] = useState(localStorage.getItem("token"));

  const gouvernoratList = [
    "تونس - Tunis",
    "أريانة - Ariana",
    "بن عروس - Ben Arous",
    "منوبة - Mannouba",
    "نابل - Nabeul",
    "زغوان - Zaghouan",
    "بنزرت - Bizerte",
    "باجة - Bèja",
    "جندوبة - Jendouba",
    "الكاف - El Kef",
    "سليانة - Siliana",
    "سوسة - Sousse",
    "المنستير - Monastir",
    "المهدية - Mahdia",
    "صفاقس - Sfax",
    "القيروان - Kairouan",
    "القصرين - Kairouan",
    "سيدى بوزيد - Sidi Bouzid",
    "قابس - Gabès",
    "مدنين - Medenine",
    " تطاوين - Tataouine",
    "قفصة - Gafsa",
    "توزر - Tozeur",
    "قبلي - Kébili",
  ];

  const nationalityList = [
    { label: "أفغانية – afghane", value: "أفغانية" },
    { label: "جنوب أفريقية - sud-africaine", value: "جنوب أفريقية" },
    { label: "ألبانية - albanaise", value: "ألبانية" },
    { label: "جزائرية - algérienne", value: "جزائرية" },
    { label: "ألمانية - allemande", value: "ألمانية" },
    { label: "أندورية - andorrane", value: "أندورية" },
    { label: "أنغولية - angolaise", value: "أنغولية" },
    { label: "أنتيغوية - antiguane", value: "أنتيغوية" },
    { label: "سعودية - saoudienne", value: "سعودية" },
    { label: "أرجنتينية - argentine", value: "أرجنتينية" },
    { label: "أرمينية - arménienne", value: "أرمينية" },
    { label: "مقدونية - macédonienne", value: "مقدونية" },
    { label: "أسترالية - australienne", value: "أسترالية" },
    { label: "نمساوية - autrichienne", value: "نمساوية" },
    { label: "أذربيجانية - azerbaïdjanaise", value: "أذربيجانية" },
    { label: "بهامية - bahamienne", value: "بهامية" },
    { label: "بحرينية - bahreïnienne", value: "بحرينية" },
    { label: "بنغلاديشية - bangladaise", value: "بنغلاديشية" },
    { label: "باربادوسية - barbadienne", value: "باربادوسية" },
    { label: "بيلاروسية - bélarussienne", value: "بيلاروسية" },
    { label: "بلجيكية - belge", value: "بلجيكية" },
    { label: "بليزيّة - bélizienne", value: "بليزيّة" },
    { label: "بنينية - béninoise", value: "بنينية" },
    { label: "بوتانية - bhoutanaise", value: "بوتانية" },
    { label: "بوليفية - bolivienne", value: "بوليفية" },
    { label: "بوسنية - bosniaque", value: "بوسنية" },
    { label: "بوتسوانية - botswanaise", value: "بوتسوانية" },
    { label: "برازيلية - brésilienne", value: "برازيلية" },
    { label: "بروناي - bruneien", value: "بروناي" },
    { label: "بلغارية - bulgare", value: "بلغارية" },
    { label: "بوركينية - burkinabè", value: "بوركينية" },
    { label: "بوروندية - burundaise", value: "بوروندية" },
    { label: "كمبودية - cambodgienne", value: "كمبودية" },
    { label: "كاميرونية - camerounaise", value: "كاميرونية" },
    { label: "كندية - canadienne", value: "كندية" },
    { label: "كاب فيردية - cap-verdienne", value: "كاب فيردية" },
    { label: "تشيلية - chilienne", value: "تشيلية" },
    { label: "صينية - chinoise", value: "صينية" },
    { label: "قبرصية - chypriote", value: "قبرصية" },
    { label: "كولومبية - colombienne", value: "كولومبية" },
    { label: "جزر القمرية - comorienne", value: "جزر القمرية" },
    { label: "كونغولية - congolaise", value: "كونغولية" },
    { label: "كورية (شمالية) - nord-coréenne", value: "كورية (شمالية)" },
    { label: "كورية (جنوبية) - sud-coréenne", value: "كورية (جنوبية)" },
    { label: "كوستاريكية - costaricienne", value: "كوستاريكية" },
    { label: "إيفوارية - ivoirienne", value: "إيفوارية" },
    { label: "كرواتية - croate", value: "كرواتية" },
    { label: "كوبية - cubaine", value: "كوبية" },
    { label: "دانيكية - danoise", value: "دانيكية" },
    { label: "جيبوتية - djiboutienne", value: "جيبوتية" },
    { label: "دومينيكية - dominiquaise", value: "دومينيكية" },
    { label: "مصرية - égyptienne", value: "مصرية" },
    { label: "سالفادورية - salvadorienne", value: "سالفادورية" },
    { label: "إماراتية - des Émirats arabes unis", value: "إماراتية" },
    { label: "إكوادورية - équatorienne", value: "إكوادورية" },
    { label: "إريتيرية - érythréenne", value: "إريتيرية" },
    { label: "إسبانية - espagnole", value: "إسبانية" },
    { label: "إستونية - estonienne", value: "إستونية" },
    { label: "أمريكية - américaine", value: "أمريكية" },
    { label: "إثيوبية - éthiopienne", value: "إثيوبية" },
    { label: "فيجية - des Îles Fidji", value: "فيجية" },
    { label: "فنلندية - finlandaise", value: "فنلندية" },
    { label: "فرنسية - française", value: "فرنسية" },
    { label: "غابونية - gabonaise", value: "غابونية" },
    { label: "غامبية - gambienne", value: "غامبية" },
    { label: "جورجية - géorgienne", value: "جورجية" },
    { label: "غانية - ghanéenne", value: "غانية" },
    { label: "يونانية - grecque", value: "يونانية" },
    { label: "جزر غرينادينية - grenadine", value: "جزر غرينادينية" },
    { label: "غواتيمالية - guatémaltèque", value: "غواتيمالية" },
    { label: "غينية - guinéenne", value: "غينية" },
    { label: "غينية-بيساو - bissau-guinéenne", value: "غينية-بيساو" },
    { label: "غينية الاستوائية - équato-guinéenne", value: "غينية الاستوائية" },
    { label: "غيانية - guyanienne", value: "غيانية" },
    { label: "هايتيّة - haïtienne", value: "هايتيّة" },
    { label: "هندوراسية - hondurienne", value: "هندوراسية" },
    { label: "هنغارية - hongroise", value: "هنغارية" },
    { label: "هندية - indienne", value: "هندية" },
    { label: "إندونيسية - indonésienne", value: "إندونيسية" },
    { label: "إيرانية - iranienne", value: "إيرانية" },
    { label: "عراقية - iraquienne", value: "عراقية" },
    { label: "إيرلندية - irlandaise", value: "إيرلندية" },
    { label: "آيسلندية - islandaise", value: "آيسلندية" },
    { label: "إيطالية - italienne", value: "إيطالية" },
    { label: "ليبية - libyenne", value: "ليبية" },
    { label: "جامايكية - jamaïquaine", value: "جامايكية" },
    { label: "يابانية - japonaise", value: "يابانية" },
    { label: "أردنية - jordanienne", value: "أردنية" },
    { label: "قازاخية - kazakhe", value: "قازاخية" },
    { label: "كينية - kényane", value: "كينية" },
    { label: "قيرغيزية - kirghize", value: "قيرغيزية" },
    { label: "كيريباسية - kiribatienne", value: "كيريباسية" },
    { label: "كويتية - koweïtienne", value: "كويتية" },
    { label: "لاوسية - laotienne", value: "لاوسية" },
    { label: "لوسوتو - lesothan", value: "لوسوتو" },
    { label: "لاتيفية - lettone", value: "لاتيفية" },
    { label: "لبنانية - libanaise", value: "لبنانية" },
    { label: "ليبيرية - libérienne", value: "ليبيرية" },
    { label: "ليختنشتاينية - liechtensteinoise", value: "ليختنشتاينية" },
    { label: "ليتوانية - lituanienne", value: "ليتوانية" },
    { label: "لوكسمبورغية - luxembourgeoise", value: "لوكسمبورغية" },
    { label: "ملغاشية - malgache", value: "ملغاشية" },
    { label: "ماليزية - malaisienne", value: "ماليزية" },
    { label: "مالاوية - malawienne", value: "مالاوية" },
    { label: "مالديفية - maldivienne", value: "مالديفية" },
    { label: "مالية - malienne", value: "مالية" },
    { label: "مالطية - maltaise", value: "مالطية" },
    { label: "مغربية - marocaine", value: "مغربية" },
    { label: "مارشالية - marshallaise", value: "مارشالية" },
    { label: "موريشيوس - mauricienne", value: "موريشيوس" },
    { label: "موريتانية - mauritanienne", value: "موريتانية" },
    { label: "مكسيكية - mexicaine", value: "مكسيكية" },
    { label: "ميكرونيزية - micronésienne", value: "ميكرونيزية" },
    { label: "مولدوفية - moldove", value: "مولدوفية" },
    { label: "موناكية - monégasque", value: "موناكية" },
    { label: "منغولية - mongole", value: "منغولية" },
    { label: "موزمبيقية - mozambicaine", value: "موزمبيقية" },
    { label: "ميانمار - du Myanmar", value: "ميانمار" },
    { label: "ناميبية - namibienne", value: "ناميبية" },
    { label: "نواروانية - nauruane", value: "نواروانية" },
    { label: "نيبالية - népalaise", value: "نيبالية" },
    { label: "نيكاراغوانية - nicaraguayenne", value: "نيكاراغوانية" },
    { label: "نيجيرية - nigérienne", value: "نيجيرية" },
    { label: "نيجيرانية - nigériane", value: "نيجيرانية" },
    { label: "نرويجية - norvégienne", value: "نرويجية" },
    { label: "نيوزيلندية - néo-zélandaise", value: "نيوزيلندية" },
    { label: "عمانية - omanaise", value: "عمانية" },
    { label: "أوغندية - ougandaise", value: "أوغندية" },
    { label: "أوزبكية - ouzbèke", value: "أوزبكية" },
    { label: "باكستانية - pakistanaise", value: "باكستانية" },
    { label: "بالوانية - palauane", value: "بالوانية" },
    { label: "بنمية - panaméenne", value: "بنمية" },
    { label: "بابوية - papouasien", value: "بابوية" },
    { label: "بارغوانية - paraguayenne", value: "بارغوانية" },
    { label: "هولندية - néerlandaise", value: "هولندية" },
    { label: "بيروفية - péruvienne", value: "بيروفية" },
    { label: "فلبينية - philippine", value: "فلبينية" },
    { label: "بولندية - polonaise", value: "بولندية" },
    { label: "برتغالية - portugaise", value: "برتغالية" },
    { label: "قطرية - qatarienne", value: "قطرية" },
    { label: "وسط إفريقية - centrafricaine", value: "وسط إفريقية" },
    { label: "كونغولية - congolaise", value: "كونغولية" },
    { label: "دومينيكية - dominicaine", value: "دومينيكية" },
    { label: "تشيكية - tchèque (République tchèque)", value: "تشيكية" },
    { label: "رومانية - roumaine", value: "رومانية" },
    { label: "بريطانية - britannique (Royaume-Uni)", value: "بريطانية" },
    { label: "روسية - russe", value: "روسية" },
    { label: "رواندية - rwandaise", value: "رواندية" },
    {
      label: "سانت كريستوفر ونيفس - de Saint-Christophe-et-Nevis",
      value: "سانت كريستوفر ونيفس",
    },
    { label: "سانت لوسية - saint-lucienne", value: "سانت لوسية" },
    { label: "سانت مارينية - saint-marinaise", value: "سانت مارينية" },
    { label: "الفاتيكانية - du Saint-Siège", value: "الفاتيكانية" },
    {
      label: "سانت فنسنت والغرينادين - de Saint-Vincent-et-les-Grenadines",
      value: "سانت فنسنت والغرينادين",
    },
    { label: "سليمانية - salomonaise", value: "سليمانية" },
    { label: "ساموا - samoane", value: "ساموا" },
    {
      label: "ساو تومي وبرينسيب - de São Tomé e Príncipe",
      value: "ساو تومي وبرينسيب",
    },
    { label: "سنغالية - sénégalaise", value: "سنغالية" },
    { label: "سيشيلية - seychelloise", value: "سيشيلية" },
    { label: "سيراليونية - de Sierra Leone", value: "سيراليونية" },
    { label: "سنغافورية - singapourienne", value: "سنغافورية" },
    { label: "سلوفاكية - slovaque", value: "سلوفاكية" },
    { label: "سلوفينية - slovène", value: "سلوفينية" },
    { label: "صومالية - somalienne", value: "صومالية" },
    { label: "سودانية - soudanaise", value: "سودانية" },
    { label: "سريلانكية - sri-lankaise", value: "سريلانكية" },
    { label: "سويدية - suédoise", value: "سويدية" },
    { label: "سويسرية - suisse", value: "سويسرية" },
    { label: "سورينامية - surinamaise", value: "سورينامية" },
    { label: "سوازية - swazie", value: "سوازية" },
    { label: "سورية - syrienne", value: "سورية" },
    { label: "طاجيكية - tadjike", value: "طاجيكية" },
    { label: "تايوانية - taïwanaise", value: "تايوانية" },
    { label: "تنزانية - tanzanienne", value: "تنزانية" },
    { label: "تشادية - tchadienne", value: "تشادية" },
    { label: "تايلندية - thaïlandaise", value: "تايلندية" },
    { label: "تيمورية - timorais", value: "تيمورية" },
    { label: "توغولية - togolaise", value: "توغولية" },
    { label: "تونغية - tongane", value: "تونغية" },
    { label: "ترينيدادية - trinidadienne", value: "ترينيدادية" },
    { label: "تونسية - tunisienne", value: "تونسية" },
    { label: "تركيّة - turque", value: "تركيّة" },
    { label: "توفالية - tuvaluane", value: "توفالية" },
    { label: "أوكرانية - ukrainienne", value: "أوكرانية" },
    { label: "أوروغوانية - uruguayenne", value: "أوروغوانية" },
    { label: "فانواتية - vanuatuane", value: "فانواتية" },
    { label: "فنزويلية - vénézuélienne", value: "فنزويلية" },
    { label: "فيتنامية - vietnamienne", value: "فيتنامية" },
    { label: "يمنية - yéménite", value: "يمنية" },
    { label: "يوغسلافية - yougoslave", value: "يوغسلافية" },
    { label: "زامبية - zambienne", value: "زامبية" },
    { label: "زيمبابوية - zimbabwéenne", value: "زيمبابوية" },
  ];

  const nationalityOptions = nationalityList?.map((item) => ({
    label: item.label,
    value: item.value,
  }));

  const gouvernoratOptions = gouvernoratList.map((item) => ({
    label: item,
    value: item,
  }));

  const secteurData = [
    "الفلاحة - AGRICULTURE",
    "الصيد البحري وتربية الأحياء المائية - PECHE ET AQUACULTURE",
    "البناء والأشغال العمومية و توابعه  - BATIMENT, TRAVAUX PUBLICS ET ANNEXES",
    "النسيج  والإكسـاء  - TEXTILE ET HABILLEMENT",
    "الجلود و الأحذية  - CUIR ET CHAUSSURES",
    "الآلية العامة و التركيب المعدني - MECANIQUE GENERALE ET CONSTRUCTION METALLIQUE",
    "الكهرباء والإلكترونيك - ELECTRICITE ET ELECTRONIQUE",
    "الصناعات الغــــذائية  - AGRO-ALIMENTAIRE",
    "النقل وسياقة وصيانة العربات ومعدات الأشغال العمومية والفلاحية - TRANSPORT, CONDUITE ET MAINTENANCE DES VEHICULES ET DES ENGINS DE TRAVAUX PUBLICS ET AGRICOLES",
    "السياحة و الفندقة - TOURISME ET HOTELLERIE",
    "الحرف الفنية والتقليدية - METIERS D'ART ET DE L'ARTISANAT",
    "الوظائف الإدارية والتجارة والإعلامية - EMPLOIS DE BUREAU, COMMERCE ET INFORMATIQUE",
    "الخدمات والصناعات المختلفة - SERVICES ET INDUSTRIES DIVERS",
  ];

  const surveyJson = {
    logoPosition: "right",
    locale: i18n.language,
    pages: [
      {
        name: "page0",
        title: "Type de l'établissement - الصبغة القانونية",
        minWidth: "800px",
        elements: [
          {
            type: "panel",
            name: "TypeInvestisseur",
            elements: [
              {
                type: "radiogroup",
                name: "inverstor_type",
                title: "Type de l'établissement - الصبغة القانونية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                renderAs: "div",
                choices: [
                  {
                    value: "Personne physique",
                    text: "Personne physique - شخص طبيعي",
                  },
                  {
                    value: "Personne morale",
                    text: "Personne morale - شخص معنوي",
                  },
                ],
                defaultValue: "Personne morale",
                choicesOrder: "asc",
                colCount: 4,
              },
              {
                type: "radiogroup",
                name: "investor_RNE",
                title:
                  "Quel est l'état actuel de votre entreprise ? - ما هو الوضع الحالي لمؤسستكم؟ ",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value:
                      "الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)",
                    text: "Réservation RNE uniquement (Entreprise en cours de création) - الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)",
                  },
                  {
                    value:
                      "رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)",
                    text: "Numéro de Patente disponible (Entreprise déjà créée) - رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)",
                  },
                ],
                colCount: 2,
              },
            ],
          },
        ],
      },

      {
        name: "page1",
        title: "Données de l'investisseur - معطيات المستثمر ",

        elements: [
          {
            type: "panel",
            name: "InvestisseurData",
            elements: [
              {
                type: "text",
                name: "investor_last_name_ar",
                title:
                  "Nom de l'investisseur (En arabe) - لقب المسثمر (بالعربية)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "text",
                name: "investor_first_name_ar",
                startWithNewLine: false,
                title:
                  "Prénom de l'investisseur (En arabe) - إسم المسثمر (بالعربية)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "text",
                name: "investor_last_name_fr",
                title:
                  "Nom de l'investisseur (Français) - لقب المسثمر (بالفرنسية)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default:
                        "Veuillez entrer uniquement des lettres latines.",
                      ar: "يرجى إدخال أحرف فرنسية فقط.",
                    },

                    regex: "^[A-Za-zÀ-ÿs-]+$",
                  },
                ],
              },
              {
                type: "text",
                name: "investor_first_name_fr",
                startWithNewLine: false,
                title:
                  "Prénom de l'investisseur (En français) - إسم المسثمر (بالفرنسية)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default:
                        "Veuillez entrer uniquement des lettres latines.",
                      ar: "يرجى إدخال أحرف فرنسية فقط.",
                    },
                    regex: "^[A-Za-zÀ-ÿs-]+$",
                  },
                ],
              },
              {
                type: "text",
                name: "investor_email",
                title: "Adresse e-mail - البريد الإلكتروني",

                hideNumber: true,
                enableIf: "{investor_email} empty",
                startWithNewLine: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "email",
              },
              {
                name: "investor_birth_date",
                type: "datepicker",

                startWithNewLine: false,
                title: "Date de naissance - تاريخ الولادة",
                dateFormat: "dd/mm/yy",
                hideNumber: true,
                readOnly: true,
                isRequired: true,
                errorLocation: "bottom",
                max: new Date(
                  new Date().setFullYear(new Date().getFullYear() - 18)
                )
                  .toISOString()
                  .split("T")[0],

                placeholder: {
                  default: "DD/MM/YYYY",
                  ar: "اليوم / الشهر / السنة",
                },
              },

              {
                type: "dropdown",
                name: "investor_civility",
                title: "Genre - الجنس",

                hideNumber: true,
                enableIf: "{investor_civility} empty",
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "ذكر",
                    text: "Masculin - ذكر",
                  },
                  {
                    value: "أنثى",
                    text: "Féminin - أنثى",
                  },
                ],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
                startWithNewLine: false,
              },
              {
                type: "dropdown",
                name: "investor_nationality",
                startWithNewLine: true,
                title: "Nationalité - الجنسية",
                readOnly: true,
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: nationalityList.map((e) => ({
                  value: e.value,
                  text: e.label,
                })),
                placeholder: "",
                allowClear: false,
                searchEnabled: true,
              },
              {
                type: "text",
                name: "investor_cin",
                visibleIf: "{investor_nationality} = 'تونسية'",
                startWithNewLine: false,
                title: "Numéro CIN - رقم بطاقة التعريف الوطنية",

                hideNumber: true,
                enableIf: "{investor_cin} empty",
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "expression",
                  },
                ],
                maskType: "pattern",
                maskSettings: {
                  pattern: "99999999",
                },
              },

              {
                type: "datepicker",

                name: "investor_date_delivrance",
                visibleIf: "{investor_nationality} = 'تونسية'",
                title:
                  "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية ",
                max: new Date().toISOString().split("T")[0],
                errorLocation: "bottom",
                hideNumber: true,
                startWithNewLine: false,
                isRequired: true,
                dateFormat: "dd/mm/yy",
                placeholder: {
                  default: "DD/MM/YYYY",
                  ar: "اليوم / الشهر / السنة",
                },
              },
              {
                type: "text",
                name: "investor_birth_place",
                title:
                  "Lieu de naissance (En arabe) - مكان الولادة (بالعربية) ",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                enableIf: "{investor_birth_place} empty",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "text",
                name: "investor_passport",
                visibleIf: "{investor_nationality} <> 'تونسية'",
                startWithNewLine: false,
                title: "Numéro du passeport - رقم جواز السفر",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },

              {
                type: "dropdown",
                name: "investor_eduction_level",
                startWithNewLine: false,
                title: "Niveau scolaire - المستوى الدراسي ",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "إبتدائي",
                    text: "Primaire - إبتدائي",
                  },
                  {
                    value: "ثانوي",
                    text: "Secondaire - ثانوي",
                  },
                  {
                    value: "جامعي",
                    text: "Universitaire - جامعي",
                  },
                  {
                    value: "تكوين مهني",
                    text: "Formation professionnelle - تكوين مهني",
                  },
                ],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
              },

              {
                type: "text",
                name: "investor_phone_number",
                startWithNewLine: false,
                title: "Numéro de téléphone - رقم الهاتف",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                readOnly: true,
                maskType: "pattern",
                maskSettings: {
                  pattern: "+216 99 999 999",
                },
              },
              {
                type: "text",
                name: "investor_residence_address",
                title:
                  "Adresse de résidence (En arabe) - عنوان الإقامة (بالعربية)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },

              {
                type: "dropdown",
                name: "investor_residence_governorate",
                title: "Gouvernorat de résidence - ولاية الإقامة",
                choices: gouvernoratList,
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                startWithNewLine: false,
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "investor_residence_delegation",
                startWithNewLine: false,
                title: "Délégation de résidence - معتمدية الإقامة",
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "text",
                name: "investor_postal_code",
                title: "Code postal - الترقيم البريدي",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                maskType: "pattern",
                maskSettings: {
                  pattern: "9999",
                },
              },
              {
                type: "radiogroup",
                name: "investor_specific_needs",
                minWidth: "100px",
                startWithNewLine: false,
                title: "Personne à besoin spécifique - شخص ذو إحتياجات خصوصية ",

                hideNumber: true,
                isRequired: true,
                choices: [
                  {
                    value: "نعم",
                    text: "Oui - نعم",
                  },
                  {
                    value: "لا",
                    text: "Non - لا",
                  },
                ],
                colCount: 5,
              },

              {
                type: "file",
                name: "investor_copie_cin",
                visibleIf: "{investor_nationality} = 'تونسية'",
                maxWidth: "30%",
                startWithNewLine: true,
                title: "Copie de la CIN - نسخة من بطاقة التعريف الوطنية ",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                filePlaceholder:
                  "Téléchargez une copie de la carte d'identité nationale\nقم بتحميل نسخة من بطاقة التعريف الوطنية",
                storeDataAsText: false,
              },
              {
                type: "file",
                name: "investor_copie_passeport",
                visibleIf: "{investor_nationality} <> 'تونسية'",
                maxWidth: "30%",
                startWithNewLine: false,
                title: "Copie du passeport - نسخة من جواز السفر",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                filePlaceholder:
                  "Téléchargez une copie du passeport\nيرجى تحميل نسخة من جواز السفر",
                storeDataAsText: false,
              },
              {
                type: "file",
                name: "investor_copie_cv",
                maxWidth: "30%",
                startWithNewLine: false,
                title: "Copie du CV -  السيرة الذاتية للمستثمر",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                filePlaceholder:
                  "Téléchargez le CV de l'investisseur\nانقر لتحميل السيرة الذاتية للمستثمر",
                storeDataAsText: false,
              },
            ],
          },
        ],
      },
      {
        name: "legal_entity_page",
        title:
          "Données relatives à l'établissement (Personne Morale) - بيانات متعلقة بالمؤسسة (شخص معنوي)",
        visibleIf: "{inverstor_type} = 'Personne morale'",
        elements: [
          {
            type: "panel",
            name: "panel5",
            elements: [
              {
                type: "text",
                name: "legal_entity_reservation_number_official",
                visibleIf:
                  "{investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)'",
                title:
                  "Numéro de réservation de la dénomination officielle - رقم حجز التسمية الرسمية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                maskType: "pattern",
                maskSettings: {
                  pattern: "999999999",
                },
              },
              {
                type: "text",
                name: "legal_entity_tax_number",
                visibleIf:
                  "{investor_RNE} = 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
                title:
                  "Numéro d’identifiant fiscal / RNE - الرقم المعرف الجبائي لدى السجل الوطني للمؤسسات",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "regex",
                    text: "Le numéro RNE doit être au format 7 chiffres suivis d'une lettre majuscule (ex: 1234567A).",
                    regex: "^[0-9]{7}[A-Z]$",
                  },
                ],
              },
              {
                type: "text",
                name: "legal_entity_reservation_number_commercial",

                startWithNewLine: false,
                title:
                  "Numéro de réservation de la dénomination commerciale - رقم حجز التسمية التجارية",

                hideNumber: true,
                errorLocation: "bottom",
                maskType: "pattern",
                maskSettings: {
                  pattern: "999999999",
                },
              },

              {
                type: "text",
                name: "legal_entity_official_name",
                startWithNewLine: true,
                title: "Dénomination officielle - التسمية الرسمية",

                hideNumber: true,
                // enableIf: "{legal_entity_official_name} empty",
                readOnly: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "legal_entity_commercial_name",

                startWithNewLine: false,
                title: "Dénomination commerciale - التسمية التجارية",

                hideNumber: true,

                readOnly: true,
                errorLocation: "bottom",
              },
              {
                type: "datepicker",
                name: "legal_entity_reservation_officielle_expiration_date",
                visibleIf:
                  "{investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)'",
                title:
                  "Date d’expiration de la réservation de la dénomination officielle - تاريخ انتهاء صلاحية حجز التسمية الرسمية",

                hideNumber: true,
                readOnly: true,
                isRequired: true,
                errorLocation: "bottom",
                max: new Date().toISOString().split("T")[0],
                dateFormat: "dd/mm/yy",
                placeholder: {
                  default: "DD/MM/YYYY",
                  ar: "اليوم / الشهر / السنة",
                },
              },
              {
                type: "datepicker",
                name: "legal_entity_reservation_commerciale_expiration_date",
                visibleIf:
                  "{investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)'",
                startWithNewLine: false,
                title:
                  "Date d’expiration de la réservation de la dénomination commerciale - تاريخ انتهاء صلاحية حجز الاسم التجاري",

                hideNumber: true,

                readOnly: true,
                requiredIf: "{legal_entity_commercial_name} <> ''",
                errorLocation: "bottom",
                max: new Date().toISOString().split("T")[0],
                dateFormat: "dd/mm/yy",
                placeholder: {
                  default: "DD/MM/YYYY",
                  ar: "اليوم / الشهر / السنة",
                },
              },
              {
                type: "dropdown",
                name: "legal_entity_legal_form",
                title: "Forme Juridique - الشكل القانوني",

                hideNumber: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "SARL - شركة ذات مسؤولية محدودة",
                    text: "Société à responsabilité limitée (SARL) - شركة ذات مسؤولية محدودة",
                  },
                  {
                    value: "SUARL - شركة الشخص الواحد ذات المسؤولية المحدودة",
                    text: "Société unipersonnelle à responsabilité limitée (SUARL) - شركة الشخص الواحد ذات المسؤولية المحدودة",
                  },
                  {
                    value: "SA - شركة خفية الإسم",
                    text: "Société Anonyme (SA) - شركة خفية الإسم",
                  },
                ],
              },
              {
                type: "text",
                name: "legal_entity_head_office_address",
                startWithNewLine: false,
                title:
                  "Adresse du siège social (En arabe) - عنوان المقر الاجتماعي (بالعربية)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "text",
                name: "legal_entity_sector_activity",
                startWithNewLine: true,
                title: "Secteur d’activité - مجال النشاط",

                hideNumber: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "legal_entity_cnss_registration_number",
                visibleIf:
                  "{investor_RNE} = 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
                startWithNewLine: false,
                title:
                  "Numéro de matricule CNSS - رقم الانخراط في الصندوق الوطني للضمان الاجتماعي",

                hideNumber: true,
                errorLocation: "bottom",
              },
              {
                type: "dropdown",
                name: "legal_entity_governorate",
                title: "Gouvernorat - الولاية",
                choices: gouvernoratList,
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "dropdown",
                name: "legal_entity_delegation",
                startWithNewLine: false,
                title: "Délégation - المعتمدية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
              },
              {
                type: "text",
                name: "legal_entity_phone_number",
                maxWidth: "50%",
                startWithNewLine: false,
                title: "Numéro du téléphone - رقم الهاتف",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                maskType: "pattern",
                maskSettings: {
                  pattern: "+216 99 999 999",
                },
              },
            ],
          },
        ],
      },
      {
        name: "page2",
        title: "Données de l’établissement de formation - معطيات مؤسسة التكوين",

        elements: [
          {
            type: "panel",
            name: "panel1",
            elements: [
              {
                type: "text",
                name: "establishment_data_tax_number",
                visibleIf:
                  "{investor_RNE} = 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
                title:
                  "Numéro d’identifiant fiscal / RNE - رقم المعرف الجبائي لدى السجل الوطني للمؤسسات",

                hideNumber: true,
                defaultValueExpression: "{legal_entity_tax_number}",
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_data_reservation_number",
                visibleIf:
                  "{inverstor_type} = 'Personne morale' and {investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)'",
                title:
                  "Numéro de réservation de la dénomination officielle - رقم حجز التسمية الرسمية",

                hideNumber: true,
                enableIf: "{inverstor_type} <> 'Personne morale'",
                defaultValueExpression:
                  "{legal_entity_reservation_number_official}",
                isRequired: true,
                errorLocation: "bottom",
                // maskType: "pattern",
                // maskSettings: {
                //   pattern: "999999999",
                // },
              },

              {
                type: "text",
                name: "establishment_data_reservation_number_commercial",
                visibleIf:
                  "{investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)'",
                enableIf: "{inverstor_type} <> 'Personne morale'",
                title:
                  "Numéro de réservation de la dénomination commerciale - رقم حجز التسمية التجارية",

                requiredIf: "{inverstor_type} <> 'Personne morale'",
                hideNumber: true,
                defaultValueExpression:
                  "{legal_entity_reservation_number_commercial}",
                errorLocation: "bottom",
                startWithNewLine: false,
                // maskType: "pattern",
                // maskSettings: {
                //   pattern: "999999999",
                // },
              },
              {
                type: "text",
                name: "establishment_data_official_name",
                visibleIf:
                  "{inverstor_type} = 'Personne morale' and {investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)'",
                title: "Dénomination officielle - التسمية الرسمية",
                hideNumber: true,
                isRequired: true,
                // enableIf: "{establishment_data_official_name} empty",
                readOnly: true,
                startWithNewLine: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_data_official_name",
                startWithNewLine: false,
                title: "Dénomination officielle - التسمية الرسمية",

                visibleIf:
                  "{investor_RNE} <> 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)'",

                hideNumber: true,
                // enableIf: "{inverstor_type} <> 'Personne morale' ",
                readOnly: true,
                isRequired: true,
                defaultValueExpression: "{legal_entity_official_name}",
                errorLocation: "bottom",
              },

              {
                type: "text",
                name: "establishment_data_commercial_name",

                title: "Dénomination commerciale - التسمية التجارية",
                // enableIf: "{establishment_data_commercial_name} empty",
                readOnly: true,
                hideNumber: true,
                defaultValueExpression: "{legal_entity_commercial_name}",
                requiredIf:
                  "{investor_RNE} <> 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
                errorLocation: "bottom",
                startWithNewLine: false,
              },
              {
                type: "text",
                name: "establishment_data_cnss_number",
                visibleIf:
                  "{investor_RNE} = 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
                startWithNewLine: false,
                title:
                  "Numéro de matricule CNSS - رقم الانخراط في الصندوق الوطني للضمان الاجتماعي",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_data_phone_number_1",
                title: "Numéro de téléphone 1 -  رقم الهاتف 1 ",

                hideNumber: true,
                isRequired: true,
                // maskType: "pattern",
                // maskSettings: {
                //   pattern: "+216 99 999 999",
                // },
              },
              {
                type: "text",
                name: "establishment_data_phone_number_2",
                startWithNewLine: false,
                title: "Numéro de téléphone 2 - رقم الهاتف 2 ",

                hideNumber: true,
                maskType: "pattern",
                maskSettings: {
                  pattern: "+216 99 999 999",
                },
              },
              {
                type: "text",
                name: "establishment_data_fax_number",
                startWithNewLine: false,
                title: "Numéro de fax - رقم الفاكس",

                hideNumber: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_data_email",
                title: "Adresse e-mail - البريد الإلكتروني",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "email",
              },
              {
                type: "text",
                name: "establishment_data_address",
                startWithNewLine: false,
                title:
                  "Adresse de l’établissement (En Arabe) - عنوان المؤسسة (بالعربية)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "dropdown",
                name: "establishment_data_training_type",
                startWithNewLine: false,
                title: "Nature de formation - طبيعة التكوين",

                hideNumber: true,
                isRequired: true,
                choices: [
                  {
                    value: "أولية",
                    text: "Initiale - أولية",
                  },
                  {
                    value: "مستمرة",
                    text: "Continue - مستمرة",
                  },
                  {
                    value: "أولية ومستمرة",
                    text: "Initiale et continue - أولية ومستمرة",
                  },
                ],
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "establishment_data_governorate",
                title: "Gouvernorat - الولاية",
                choices: gouvernoratList,
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "establishment_data_delegation",
                startWithNewLine: false,
                title: "Délégation - المعتمدية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "text",
                name: "establishment_data_postal_code",
                startWithNewLine: false,
                title: "Code postal - الترقيم البريدي",

                hideNumber: true,
                isRequired: true,
                maskType: "pattern",
                maskSettings: {
                  pattern: "9999",
                },
              },
              {
                type: "file",
                name: "establishment_data_logo",
                maxWidth: "10%",
                title: "Logo de l'établissement - شعار المؤسسة",

                hideNumber: true,
                errorLocation: "bottom",
                acceptedTypes: ".jpg,.jpeg,.png",
                filePlaceholder:
                  "Télécharger le logo de l'établissement\nتحميل شعار الشركة",
                storeDataAsText: false,
              },
              {
                type: "file",
                name: "establishment_data_rne",
                visibleIf:
                  "{investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)' and {inverstor_type} = 'Personne morale'",
                maxWidth: "30%",
                startWithNewLine: false,
                title:
                  "Décharge de la réservation de la dénomination officielle - تحميل وثيقة حجز التسمية الرسمية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                acceptedTypes: ".jpg,.jpeg,.png",
                filePlaceholder:
                  "Télécharger la décharge de la réservation RNE\nحمل وثيقة حجز التسمية الرسمية لدى السجل الوطني للمؤسسات",
                storeDataAsText: false,
              },
              {
                type: "file",
                name: "establishment_data_commercial_name_file",
                visibleIf:
                  "{investor_RNE} = 'الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)'",
                maxWidth: "30%",
                startWithNewLine: false,
                title:
                  "Décharge de la réservation de la dénomination commerciale - تحميل وثيقة حجز التسمية التجارية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                acceptedTypes: ".jpg,.jpeg,.png",
                filePlaceholder:
                  "Télécharger la décharge de la réservation RNE\nحمل وثيقة حجز التسمية الرسمية لدى السجل الوطني للمؤسسات",
                storeDataAsText: false,
              },
              {
                type: "file",
                name: "establishment_data_pattente",
                visibleIf:
                  "{investor_RNE} = 'رقم المعرف الجبائي أو ما يعرف بالباتيندا متاح (شركة قائمة)'",
                maxWidth: "10%",
                startWithNewLine: false,
                title:
                  "Copie de la Patente - نسخة من المعرف الجبائي أو ما يعرف بالباتيندا",

                hideNumber: true,
                maxWidth: "10%",
                errorLocation: "bottom",
                acceptedTypes: ".jpg,.jpeg,.png",
                filePlaceholder:
                  "Téléchargez une copie de la patente\nحمل نسخة من المعرف الجبائي أو ما يعرف بالباتيندا",
                storeDataAsText: false,
              },
            ],
          },
        ],
      },
      {
        name: "Données de Directeur de l’Établissement",
        title: "Données de Directeur de l’Établissement - بيانات مدير المؤسسة",

        elements: [
          {
            type: "panel",
            name: "panel2",
            elements: [
              {
                type: "dropdown",
                name: "establishment_director_data_identity",
                title: "Identité - الهوية",

                hideNumber: true,
                isRequired: true,
                choices: [
                  {
                    value: "مدير غير المستثمر",
                    text: "Directeur Autre que l’Investisseur - مدير غير المستثمر",
                  },
                  {
                    value: "المستثمر نفسه",
                    text: "Investisseur lui-même - المستثمر نفسه",
                  },
                ],
              },
              {
                type: "dropdown",
                name: "establishment_director_data_nationality",
                startWithNewLine: false,
                title: "Nationalité - الجنسية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: nationalityList.map((e) => ({
                  value: e.value,
                  text: e.label,
                })),
                placeholder: "",
                allowClear: false,
                searchEnabled: true,
              },

              {
                type: "text",
                name: "establishment_director_data_cin_number",
                visibleIf:
                  "{establishment_director_data_nationality} = 'تونسية'",
                startWithNewLine: false,
                title: "Numéro de la CIN - رقم بطاقة التعريف الوطنية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                maskType: "pattern",
                maskSettings: {
                  pattern: "99999999",
                },
              },
              {
                type: "datepicker",
                name: "establishment_director_data_birth_date",
                startWithNewLine: true,
                title: "Date de naissance - تاريخ الولادة",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                max: new Date(
                  new Date().setFullYear(new Date().getFullYear() - 18)
                )
                  .toISOString()
                  .split("T")[0],
                dateFormat: "dd/mm/yy",
                placeholder: {
                  default: "DD/MM/YYYY",
                  ar: "اليوم / الشهر / السنة",
                },
              },
              {
                type: "text",
                name: "establishment_director_data_last_name",
                startWithNewLine: false,
                title: "Nom (En arabe) - اللقب (بالعربية)",
                hideNumber: true,
                isRequired: true,
                enableIf: "{establishment_director_data_last_name} empty",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "text",
                name: "establishment_director_data_first_name",
                startWithNewLine: false,
                title: "Prénom (En arabe) - الإسم (بالعربية)",

                hideNumber: true,
                isRequired: true,
                enableIf: "{establishment_director_data_first_name} empty",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "dropdown",
                name: "establishment_director_data_genre",
                title: "Genre - الجنس",

                hideNumber: true,
                isRequired: true,
                choices: [
                  {
                    value: "ذكر",
                    text: "Masculin - ذكر",
                  },
                  {
                    value: "أنثى",
                    text: "Féminin - أنثى",
                  },
                ],
                allowClear: false,
                enableIf: "{establishment_director_data_first_name} empty",
              },

              {
                type: "text",
                name: "establishment_director_data_birth_place",
                title: "Lieu de naissance (En arabe) - مكان الولادة (بالعربية)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                startWithNewLine: false,
                enableIf: "{establishment_director_data_birth_place} empty",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "datepicker",
                name: "establishment_director_data_ministerial_auth_date",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'تونسية'",
                startWithNewLine: false,
                title: "Date d’autorisation de ministre - تاريخ ترخيص الوزير",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",

                max: new Date().toISOString().split("T")[0],
                dateFormat: "dd/mm/yy",
                placeholder: {
                  default: "DD/MM/YYYY",
                  ar: "اليوم / الشهر / السنة",
                },
              },

              {
                type: "datepicker",
                name: "establishment_director_data_date_delivrance",
                visibleIf:
                  "{establishment_director_data_nationality} = 'تونسية'",
                startWithNewLine: false,
                title:
                  "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية",
                max: new Date().toISOString().split("T")[0],
                hideNumber: true,
                isRequired: true,
                dateFormat: "dd/mm/yy",
                placeholder: {
                  default: "DD/MM/YYYY",
                  ar: "اليوم / الشهر / السنة",
                },
              },
              {
                type: "text",
                name: "establishment_director_data_passeport",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'تونسية'",
                startWithNewLine: false,
                title: "Numéro du passeport - رقم جواز السفر",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_director_data_address",
                title: "Adresse (En arabe) - العنوان (بالعربية)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "dropdown",
                name: "establishment_director_data_governorate",
                startWithNewLine: false,
                title: "Gouvernorat - الولاية",
                choices: gouvernoratList,
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "establishment_director_data_delegation",
                startWithNewLine: false,
                title: "Délégation - المعتمدية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "text",
                name: "establishment_director_data_email",
                title: "Adresse e-mail - البريد الإلكتروني",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "email",
              },
              {
                type: "text",
                name: "establishment_director_data_phone_number",
                maxWidth: "50%",
                startWithNewLine: false,
                title: "Numéro de téléphone - رقم الهاتف",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                maskType: "pattern",
                maskSettings: {
                  pattern: "+216 99 999 999",
                },
              },

              {
                type: "dropdown",
                name: "establishment_director_data_education_level",
                title: "Niveau scolaire - المستوى الدراسي",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "إبتدائي",
                    text: "Primaire - إبتدائي",
                  },
                  {
                    value: "ثانوي",
                    text: "Secondaire - ثانوي",
                  },
                  {
                    value: "جامعي",
                    text: "Universitaire - جامعي",
                  },
                  {
                    value: "تكوين مهني",
                    text: "Formation professionnelle - تكوين مهني",
                  },
                ],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
                startWithNewLine: false,
              },

              {
                type: "text",
                name: "establishment_director_data_experience_years",
                startWithNewLine: true,
                title:
                  "Nombre d'années d'expériences professionnelles -  عدد سنوات الخبرة المهنية",
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "number",
                min: 0,
              },
              {
                type: "radiogroup",
                name: "establishment_director_data_specific_needs",
                startWithNewLine: false,
                title: "Personne à besoin spécifique - شخص ذو إحتياجات خصوصية ",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "نعم",
                    text: "Oui - نعم",
                  },
                  {
                    value: "لا",
                    text: "Non - لا",
                  },
                ],
                colCount: 4,
              },
              {
                type: "file",
                name: "establishment_director_data_director_folder",
                maxWidth: "10%",
                title:
                  "Dossier du directeur (CV et autres) - ملف المدير (السيرة الذاتية وغيرها)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                allowMultiple: true,
                fileOrPhotoPlaceholder:
                  "Cliquez ou glissez pour télécharger le CV et le dossier du directeur\nانقر أو اسحب لتحميل السيرة الذاتية وملف المدير",
                photoPlaceholder: "",
                filePlaceholder:
                  "Cliquez ou glissez pour télécharger le CV et le dossier du directeur\nانقر أو اسحب لتحميل السيرة الذاتية وملف المدير",
                storeDataAsText: false,
              },
              {
                type: "file",
                name: "establishment_director_data_cin_copie",
                visibleIf:
                  "{establishment_director_data_nationality} = 'تونسية'",
                maxWidth: "10%",
                startWithNewLine: false,
                title: "Copie de la CIN - نسخة من بطاقة التعريف الوطنية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                allowMultiple: true,
                filePlaceholder:
                  "Télécharger la copie du CIN.\nحمّل نسخة من بطاقة التعريف الوطنية",
                storeDataAsText: false,
              },
              {
                type: "file",
                name: "question15",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'تونسية'",
                maxWidth: "10%",
                startWithNewLine: false,
                title:
                  "Copie de l’autorisation du ministre -  نسخة من ترخيص الوزير",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                filePlaceholder:
                  "Télécharger une copie de l'autorisation du ministre\nحمل نسخة من ترخيص الوزير",
                storeDataAsText: false,
              },
              {
                type: "file",
                name: "establishment_director_data_passeport_copie",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'تونسية'",
                maxWidth: "10%",
                startWithNewLine: false,
                title: "Copie du passeport - نسخة من جواز السفر",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                allowMultiple: true,
                filePlaceholder:
                  "Télécharger la copie du passeport.\nحمل نسخة من جواز السفر",
                storeDataAsText: false,
              },
            ],
          },
        ],
      },
      {
        name: "page4",
        title: "Données sur la nature de formation - بيانات حول طبيعة التكوين",

        elements: [
          {
            type: "panel",
            name: "panel_training",
            elements: [
              {
                type: "paneldynamic",
                name: "training_nature_data",
                title:
                  "Données sur la nature de formation - بيانات حول طبيعة التكوين",

                hideNumber: true,
                isRequired: true,
                templateElements: [
                  {
                    type: "panel",
                    name: "training_type_data",
                    title: "Formation - تكوين {panelIndex}",

                    state: "expanded",
                    elements: [
                      {
                        type: "dropdown",
                        name: "training_type_data_nature",
                        title: "Nature de la formation - طبيعة التكوين",

                        hideNumber: true,
                        enableIf:
                          "{establishment_data_training_type} = 'أولية ومستمرة'",
                        defaultValueExpression:
                          "{establishment_data_training_type}",
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "أولية",
                            text: "Initiale - أولية",
                          },
                          {
                            value: "مستمرة",
                            text: "Continue - مستمرة",
                          },
                        ],
                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_type",
                        visibleIf:
                          "{panel.training_type_data_nature} = 'أولية'",
                        startWithNewLine: false,
                        title: "Type - النوع",

                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "منظرة",
                            text: "Homologuée - منظرة",
                          },
                          {
                            value: "غير منظرة",
                            text: "Non homologuée - غير منظرة",
                          },
                        ],
                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_sector",
                        startWithNewLine: false,
                        title: "Secteur - القطاع",

                        isRequired: true,
                        errorLocation: "bottom",
                        choices: secteurData,
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_sub_sector",
                        startWithNewLine: true,
                        title: "Sous-secteur - القطاع الفرعي",

                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [],
                      },
                      {
                        type: "text",
                        name: "training_type_data_domain",
                        visibleIf:
                          "({panel.training_type_data_type} = 'غير منظرة') or ({panel.training_type_data_nature} = 'مستمرة')",
                        startWithNewLine: false,
                        title: "Domaine (En arabe) - المجال (بالعربية)",

                        hideNumber: true,
                        isRequired: true,
                        errorLocation: "bottom",
                        validators: [
                          {
                            type: "regex",
                            text: {
                              default:
                                "Veuillez entrer uniquement des lettres arabes.",
                              ar: "يرجى إدخال أحرف عربية فقط.",
                            },
                            regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                          },
                        ],
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_specialty",
                        visibleIf: "{panel.training_type_data_type} = 'منظرة'",
                        title: "Spécialité - الإختصاص",

                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [],
                        allowClear: false,
                        startWithNewLine: false,
                      },
                      {
                        type: "text",
                        name: "training_type_data_diploma",
                        visibleIf: "{panel.training_type_data_type} = 'منظرة'",
                        startWithNewLine: true,
                        title: "Diplôme délivré - الشهادة المسلمة ",

                        isRequired: true,
                        errorLocation: "bottom",
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_required_level",
                        visibleIf: "{panel.training_type_data_type} = 'منظرة'",
                        startWithNewLine: false,
                        title: "Niveau requis - المستوى الادنى المطلوب ",

                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "شهادة المهارة (CC)",
                            text: "Certificat de compétence (CC) - شهادة المهارة",
                          },
                          {
                            value: "شهادة الكفاءة المهنية (CAP)",
                            text: "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
                          },
                          {
                            value: "شهادة مؤهل التقني المهني",
                            text: "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني",
                          },
                        ],
                      },
                      {
                        type: "text",
                        name: "training_type_data_duration_hour",
                        title: "Durée en (H) - المدة بالساعات (س)",

                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                        startWithNewLine: true,
                      },
                      {
                        type: "text",
                        name: "training_type_data_duration_month",
                        startWithNewLine: false,
                        title: "Durée en mois - المدة بالاشهر",

                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                      },
                      {
                        type: "text",
                        name: "training_type_data_duration_minimum",
                        visibleIf:
                          "({panel.training_type_data_nature} != 'مستمرة') and ({panel.training_type_data_type} != 'غير منظرة')",
                        startWithNewLine: false,
                        title: "Durée minimale - المدة الدنيا",

                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_mode",

                        visibleIf:
                          "({panel.training_type_data_type} = 'غير منظرة' and {panel.training_type_data_nature} = 'أولية') or {panel.training_type_data_nature} = 'مستمرة'",
                        maxWidth: "50%",
                        startWithNewLine: false,
                        title: "Mode de formation - نمط التكوين",

                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "حضوري",
                            text: "Présentielle - حضوري",
                          },
                          {
                            value: "عن بعد",
                            text: "À distance - عن بعد",
                          },
                          {
                            value: "بالتداول",
                            text: "En alternance - بالتداول",
                          },
                        ],
                        placeholder: "",
                        allowClear: false,
                        searchEnabled: false,
                      },
                    ],
                  },
                ],
                panelCount: 1,
                minPanelCount: 1,
                panelAddText: "Ajouter une formation - إضافة تكوين",

                panelRemoveText: "Supprimer - إزالة",
              },
            ],
          },
        ],
      },
      {
        name: "page5",
        title: "Données des Formateurs - بيانات المكونين",

        elements: [
          {
            type: "panel",
            name: "trainer",
            elements: [
              {
                type: "paneldynamic",
                name: "trainer_data",
                title: "Données des Formateurs - بيانات المكونين",

                hideNumber: true,
                templateElements: [
                  {
                    type: "panel",
                    name: "trainer_obj",
                    title: " Formateur - المكون {panelIndex}",

                    state: "expanded",
                    elements: [
                      {
                        type: "dropdown",
                        name: "trainer_data_nationality",
                        title: "Nationalité - الجنسية",

                        hideNumber: true,
                        isRequired: true,
                        choices: nationalityList.map((e) => ({
                          value: e.value,
                          text: e.label,
                        })),
                        allowClear: false,
                        searchEnabled: true,
                      },
                      // {
                      //   type: "text",
                      //   name: "trainer_data_passport",
                      //   visibleIf:
                      //     "{panel.trainer_data_nationality} <> 'Tunisienne'",
                      //   startWithNewLine: false,
                      //   title: "Numéro du passeport - رقم جواز السفر",

                      //   hideNumber: true,
                      //   isRequired: true,
                      // },
                      // {
                      //   type: "text",
                      //   name: "trainer_data_cin_number",
                      //   visibleIf:
                      //     "{panel.trainer_data_nationality} = 'Tunisienne'",
                      //   startWithNewLine: false,
                      //   title: "Numéro de la CIN - رقم بطاقة التعريف الوطنية",

                      //   hideNumber: true,
                      //   isRequired: true,
                      //   maskType: "pattern",
                      //   maskSettings: {
                      //     pattern: "99999999",
                      //   },
                      // },
                      // {
                      //   type: "datepicker",
                      //   name: "trainer_data_cin_date_delivrance",
                      //   visibleIf:
                      //     "{panel.trainer_data_nationality} = 'Tunisienne'",
                      //   startWithNewLine: false,
                      //   title:
                      //     "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية",
                      //   readOnly: true,
                      //   hideNumber: true,
                      //   isRequired: true,
                      //   dateFormat: "dd/mm/yy",
                      //   placeholder: {
                      //     default: "DD/MM/YYYY",
                      //     ar: "اليوم / الشهر / السنة",
                      //   },
                      // },
                      {
                        type: "text",
                        name: "trainer_data_last_name",
                        title: "Nom (En arabe) - اللقب (بالعربية)",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        validators: [
                          {
                            type: "regex",
                            text: {
                              default:
                                "Veuillez entrer uniquement des lettres arabes.",
                              ar: "يرجى إدخال أحرف عربية فقط.",
                            },
                            regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                          },
                        ],
                      },
                      {
                        type: "text",
                        name: "trainer_data_first_name",
                        startWithNewLine: false,
                        title: "Prénom (En arabe) - الإسم (بالعربية)",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        validators: [
                          {
                            type: "regex",
                            text: {
                              default:
                                "Veuillez entrer uniquement des lettres arabes.",
                              ar: "يرجى إدخال أحرف عربية فقط.",
                            },
                            regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                          },
                        ],
                      },
                      {
                        type: "dropdown",
                        name: "trainer_data_civility",
                        startWithNewLine: false,
                        title: "Genre - الجنس",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        choices: [
                          {
                            value: "ذكر",
                            text: "Masculin - ذكر",
                          },
                          {
                            value: "أنثى",
                            text: "Féminin - أنثى",
                          },
                        ],
                      },
                      {
                        type: "datepicker",
                        name: "trainer_data_birth_date",
                        title: "Date de naissance - تاريخ الولادة",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        max: new Date(
                          new Date().setFullYear(new Date().getFullYear() - 18)
                        )
                          .toISOString()
                          .split("T")[0],
                        dateFormat: "dd/mm/yy",
                        placeholder: {
                          default: "DD/MM/YYYY",
                          ar: "اليوم / الشهر / السنة",
                        },
                      },
                      {
                        type: "text",
                        name: "trainer_data_birth_place",
                        startWithNewLine: false,
                        title:
                          "Lieu de naissance (En arabe) - مكان الولادة (بالعربية)",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        validators: [
                          {
                            type: "regex",
                            text: {
                              default:
                                "Veuillez entrer uniquement des lettres arabes.",
                              ar: "يرجى إدخال أحرف عربية فقط.",
                            },
                            regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                          },
                        ],
                      },
                      {
                        type: "text",
                        name: "trainer_data_address",
                        startWithNewLine: false,
                        title: "Adresse (En arabe) - العنوان (بالعربية)",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        validators: [
                          {
                            type: "regex",
                            text: {
                              default:
                                "Veuillez entrer uniquement des lettres arabes.",
                              ar: "يرجى إدخال أحرف عربية فقط.",
                            },
                            regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                          },
                        ],
                      },
                      {
                        type: "dropdown",
                        name: "trainer_data_governorate",
                        title: "Gouvernorat - الولاية",
                        choices: gouvernoratList,
                        hideNumber: true,
                        isRequired: true,
                        errorLocation: "bottom",
                        readOnly: true,
                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "dropdown",
                        name: "trainer_data_delegation",
                        startWithNewLine: false,
                        title: "Délégation - المعتمدية",

                        hideNumber: true,
                        isRequired: true,
                        errorLocation: "bottom",
                        readOnly: true,
                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "text",
                        name: "trainer_data_postal_code",
                        startWithNewLine: false,
                        title: "Code postal - الترقيم البريدي",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        maskType: "pattern",
                        maskSettings: {
                          pattern: "9999",
                        },
                      },
                      {
                        type: "text",
                        name: "trainer_data_email",
                        title: "Adresse e-mail - البريد الإلكتروني",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        inputType: "email",
                      },
                      {
                        type: "text",
                        name: "trainer_data_phone_number",
                        startWithNewLine: false,
                        title: "Numéro du téléphone - رقم الهاتف",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        maskType: "pattern",
                        maskSettings: {
                          pattern: "+216 99 999 999",
                        },
                      },
                      {
                        type: "dropdown",
                        name: "trainer_data_education_level",
                        startWithNewLine: false,
                        title: "Niveau scolaire - المستوى الدراسي",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        choices: [
                          {
                            value: "إبتدائي",
                            text: "Primaire - إبتدائي",
                          },
                          {
                            value: "ثانوي",
                            text: "Secondaire - ثانوي",
                          },
                          {
                            value: "جامعي",
                            text: "Universitaire - جامعي",
                          },
                          {
                            value: "تكوين مهني",
                            text: "Formation professionnelle - تكوين مهني",
                          },
                        ],
                      },
                      {
                        type: "text",
                        name: "trainer_data_specialty_domain",
                        title: "Domaines de spécialité - مجالات التخصص",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                      },
                      {
                        type: "dropdown",
                        name: "trainer_data_situation",
                        maxWidth: "50%",
                        startWithNewLine: false,
                        title: "Situation - الوضعية",

                        hideNumber: true,
                        isRequired: true,
                        choices: [
                          {
                            value: "قار",
                            text: "Permanent - قار",
                          },
                          {
                            value: "غير قار",
                            text: "Vacataire - غير قار",
                          },
                        ],
                        allowClear: false,
                      },
                      {
                        type: "radiogroup",
                        name: "trainer_data_specific_needs",
                        startWithNewLine: false,
                        title:
                          "Personne à besoin spécifique - شخص ذو إحتياجات خصوصية ",

                        hideNumber: true,
                        isRequired: true,
                        readOnly: true,
                        choices: [
                          {
                            value: "نعم",
                            text: "Oui - نعم",
                          },
                          {
                            value: "لا",
                            text: "Non - لا",
                          },
                        ],
                        colCount: 4,
                      },
                      {
                        type: "file",
                        name: "trainer_data_folder",
                        maxWidth: "30%",
                        title:
                          "Dossier du formateur auprès de l'établissement - ملف المكون لدى المؤسسة ",

                        isRequired: true,
                        filePlaceholder:
                          "Téléchargez le dossier du formateur.\nحمل ملف المكون لدى المؤسسة \n",
                        storeDataAsText: false,
                      },
                      // {
                      //   type: "file",
                      //   name: "trainer_data_cin",
                      //   visibleIf:
                      //     "{panel.trainer_data_nationality} = 'Tunisienne'",
                      //   maxWidth: "30%",
                      //   startWithNewLine: false,
                      //   title:
                      //     "Copie de la CIN - نسخة من بطاقة التعريف الوطنية ",

                      //   isRequired: true,
                      //   filePlaceholder:
                      //     "Téléchargez une copie de la CIN du formateur.\nحمل نسخة من بطاقة التعريف الوطنية للمكون",
                      //   storeDataAsText: false,
                      // },
                      // {
                      //   type: "file",
                      //   name: "trainer_data_passeport",
                      //   visibleIf:
                      //     "{panel.trainer_data_nationality} <> 'Tunisienne'",
                      //   maxWidth: "10%",
                      //   startWithNewLine: false,
                      //   title: "Copie du passeport - نسخة من جواز السفر",

                      //   isRequired: true,
                      //   filePlaceholder:
                      //     "Téléchargez une copie du passeport du formateur.\nيرجى تحميل نسخة من جواز سفر للمكون",
                      //   storeDataAsText: false,
                      // },
                      {
                        type: "expression",
                        name: "isVisible",
                        visible: false,
                        title: "isVisible",
                      },
                    ],
                  },
                ],
                panelCount: 1,
                minPanelCount: 1,
                panelAddText: "Ajouter un formateur - إضافة مكون",

                panelRemoveText: "Supprimer - إزالة",
              },
            ],
          },
        ],
      },
      {
        name: "page6",
        title: "Données des Espaces - بيانات الفضاءات",

        elements: [
          {
            type: "panel",
            name: "panel7",
            elements: [
              {
                type: "paneldynamic",
                name: "space_data",
                title: "Données des Espaces - بيانات الفضاءات",

                hideNumber: true,
                templateElements: [
                  {
                    type: "panel",
                    name: "space_obj",
                    title: "Espace - فضاء {panelIndex}",

                    state: "expanded",
                    elements: [
                      {
                        type: "dropdown",
                        name: "space_data_type",
                        title: "Type - النوع",

                        hideNumber: true,
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "إدارة",
                            text: "Administration - إدارة",
                          },
                          {
                            value: "فضاء مشترك",
                            text: "Espace commun - فضاء مشترك",
                          },
                          {
                            value: "قاعة تدريس",
                            text: "Salle de classe - قاعة تدريس",
                          },
                          {
                            value: "ورشة",
                            text: "Atelier - ورشة",
                          },
                          {
                            value: "مخبر",
                            text: "Laboratoire - مخبر",
                          },
                        ],
                        showOtherItem: true,
                        otherText: "Autre - آخر",

                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "text",
                        name: "space_data_label",
                        startWithNewLine: false,
                        title: "Libellé (En arabe) - التسمية (بالعربية)",

                        isRequired: true,
                        errorLocation: "bottom",
                        validators: [
                          {
                            type: "regex",
                            text: {
                              default:
                                "Veuillez entrer uniquement des lettres arabes.",
                              ar: "يرجى إدخال أحرف عربية فقط.",
                            },
                            regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                          },
                        ],
                      },
                      {
                        type: "text",
                        name: "space_data_surface_area",
                        title: "Superficie en m² - المساحة بالمتر المربع",

                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                      },
                      {
                        type: "text",
                        name: "space_data_capacity",
                        startWithNewLine: false,
                        title:
                          "Capacité :Nombre de personne - طاقة الإستيعاب (عدد الأشخاص)",

                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                      },
                    ],
                  },
                ],
                panelCount: 1,
                minPanelCount: 1,
                panelAddText: "Ajouter un espace - إضافة مساحة",

                panelRemoveText: "Supprimer - إزالة",
              },
            ],
          },
        ],
      },
      {
        name: "page7",
        title:
          "Données des équipements de formation - بيانات التجهيزات التكوينية ",

        elements: [
          {
            type: "panel",
            name: "equipmets",
            elements: [
              {
                type: "paneldynamic",
                name: "equipments_data",
                title:
                  "Données des équipements de formation - بيانات التجهيزات التكوينية",

                hideNumber: true,
                isRequired: true,
                templateElements: [
                  {
                    type: "panel",
                    name: "equipement_obj",
                    title:
                      "Équipement de formation - تجهيز تكويني {panelIndex}",

                    state: "expanded",
                    elements: [
                      {
                        type: "text",
                        name: "equipment_label",
                        title: "Équipement de formation - تجهيز تكويني",

                        isRequired: true,
                        errorLocation: "bottom",
                      },
                      {
                        type: "text",
                        name: "equipment_number",
                        startWithNewLine: false,
                        title: "Nombre - العدد",

                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 1,
                      },
                      {
                        type: "file",
                        name: "equipment_tech_file",
                        maxWidth: "20%",
                        title:
                          "Fiche technique des équipements de formation -  الوثائق الفنية للتجهيزات التكوينية ",

                        isRequired: true,
                        errorLocation: "bottom",
                        allowMultiple: true,
                        filePlaceholder:
                          "Téléchargez les fiches techniques des équipements de formation\nتحميل الوثائق الفنية للتجهيزات التكوينية ",
                        storeDataAsText: false,
                      },
                    ],
                  },
                ],
                panelCount: 1,
                minPanelCount: 1,
                panelAddText: "Ajouter un équipement - إضافة تجهيز",
                panelRemoveText: "Supprimer - إزالة",
              },
            ],
          },
        ],
      },
      {
        name: "page8",
        title:
          "Données du personnel d'encadrement et de gestion - بيانات أعوان الاحاطة والتأطير",

        elements: [
          {
            type: "panel",
            name: "management_staff",
            elements: [
              {
                type: "paneldynamic",
                name: "management_data",
                title:
                  "Données du personnel d'encadrement et de gestion - بيانات أعوان الاحاطة والتأطير",

                hideNumber: true,
                templateElements: [
                  {
                    type: "panel",
                    name: "management_data_obj",
                    title: "Personnel - عون {panelIndex}",

                    state: "expanded",
                    elements: [
                      {
                        type: "dropdown",
                        name: "management_data_staff_category",
                        title: "Catégorie - الفئة",

                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "تأطير",
                            text: "Encadrement - تأطير",
                          },
                          {
                            value: "إدارة",
                            text: "Administration - إدارة",
                          },
                        ],
                        showOtherItem: true,
                        otherText: "Autre catégorie - فئة أخرى",
                      },
                      {
                        type: "text",
                        name: "management_data_staff_email",
                        startWithNewLine: false,
                        title: "Adresse e-mail - البريد الإلكتروني",

                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "email",
                      },
                      {
                        type: "dropdown",
                        name: "management_data_staff_situation",
                        startWithNewLine: false,
                        title: "Situation - الوضعية",

                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "قار",
                            text: "Permanent -قار",
                          },
                          {
                            value: "غير قار",
                            text: "Non permanent - غير قار",
                          },
                        ],
                      },
                      {
                        type: "text",
                        name: "management_data_staff_last_name",
                        title: "Nom (En arabe)  - اللقب (بالعربية)",

                        isRequired: true,
                        errorLocation: "bottom",
                        validators: [
                          {
                            type: "regex",
                            text: {
                              default:
                                "Veuillez entrer uniquement des lettres arabes.",
                              ar: "يرجى إدخال أحرف عربية فقط.",
                            },
                            regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                          },
                        ],
                      },
                      {
                        type: "text",
                        name: "management_data_staff_first_name",
                        startWithNewLine: false,
                        title: "Prénom (En arabe) - الإسم (بالعربية)",

                        isRequired: true,
                        errorLocation: "bottom",
                        validators: [
                          {
                            type: "regex",
                            text: {
                              default:
                                "Veuillez entrer uniquement des lettres arabes.",
                              ar: "يرجى إدخال أحرف عربية فقط.",
                            },
                            regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                          },
                        ],
                      },
                      {
                        type: "dropdown",
                        name: "management_data_staff_civility",
                        startWithNewLine: false,
                        title: "Genre - الجنس",

                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "ذكر",
                            text: "Masculin - ذكر",
                          },
                          {
                            value: "أنثى",
                            text: "Féminin - أنثى",
                          },
                        ],
                      },
                      {
                        type: "dropdown",
                        name: "management_data_staff_nationality",
                        title: "Nationalité - الجنسية",

                        isRequired: true,
                        errorLocation: "bottom",
                        choices: nationalityList.map((e) => ({
                          value: e.value,
                          text: e.label,
                        })),
                      },
                      {
                        type: "datepicker",
                        name: "management_data_staff_birth_date",
                        startWithNewLine: false,
                        title: "Date de naissance - تاريخ الولادة",

                        isRequired: true,
                        errorLocation: "bottom",
                        max: new Date(
                          new Date().setFullYear(new Date().getFullYear() - 18)
                        )
                          .toISOString()
                          .split("T")[0],
                        dateFormat: "dd/mm/yy",
                        placeholder: {
                          default: "DD/MM/YYYY",
                          ar: "اليوم / الشهر / السنة",
                        },
                      },
                      {
                        type: "text",
                        name: "management_data_staff_residence_address",
                        startWithNewLine: false,
                        title:
                          "Adresse de résidence (En arabe) - عنوان الإقامة (بالعربية)",

                        isRequired: true,
                        errorLocation: "bottom",
                        validators: [
                          {
                            type: "regex",
                            text: {
                              default:
                                "Veuillez entrer uniquement des lettres arabes.",
                              ar: "يرجى إدخال أحرف عربية فقط.",
                            },
                            regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                          },
                        ],
                      },
                      {
                        type: "dropdown",
                        name: "management_data_staff_residence_governorate",
                        title: "Gouvernorat de résidence - ولاية الإقامة",
                        choices: gouvernoratList,
                        isRequired: true,
                        errorLocation: "bottom",
                      },
                      {
                        type: "dropdown",
                        name: "management_data_staff_residence_delegation",
                        startWithNewLine: false,
                        title: "Délégation - المعتمدية",

                        hideNumber: true,
                        isRequired: true,
                        errorLocation: "bottom",
                        placeholder: "",
                        allowClear: false,
                      },

                      {
                        type: "dropdown",
                        name: "management_data_staff_education_level",
                        startWithNewLine: false,
                        title: "Niveau scolaire - المستوى الدراسي ",

                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "Primaire",
                            text: "Primaire - إبتدائي",
                          },
                          {
                            value: "Secondaire",
                            text: "Secondaire - ثانوي",
                          },
                          {
                            value: "Universitaire",
                            text: "Universitaire - جامعي",
                          },
                          {
                            value: "Formation professionnelle",
                            text: "Formation professionnelle - تكوين مهني",
                          },
                        ],
                      },
                      {
                        type: "text",
                        name: "management_data_staff_experience_years",
                        title:
                          "Nombre d'années d’expérience professionnelle - عدد سنوات الخبرة المهنية",

                        isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                      },
                      {
                        type: "text",
                        name: "management_data_staff_cin_number",
                        visibleIf:
                          "{panel.management_data_staff_nationality} = 'تونسية'",
                        startWithNewLine: false,
                        title: "Numéro de la CIN - رقم بطاقة التعريف الوطنية ",

                        isRequired: true,
                        errorLocation: "bottom",
                        maskType: "pattern",
                        maskSettings: {
                          pattern: "99999999",
                        },
                      },
                      {
                        type: "datepicker",
                        name: "management_data_staff_date_delivrance",
                        visibleIf:
                          "{panel.management_data_staff_nationality} = 'تونسية'",
                        title:
                          "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية",

                        hideNumber: true,
                        isRequired: true,
                        max: new Date().toISOString().split("T")[0],
                        dateFormat: "dd/mm/yy",
                        placeholder: {
                          default: "DD/MM/YYYY",
                          ar: "اليوم / الشهر / السنة",
                        },
                      },
                      {
                        type: "text",
                        name: "management_data_staff_passport_number",
                        visibleIf:
                          "{panel.management_data_staff_nationality} <> 'تونسية'",
                        title: "Numéro du passeport - رقم جواز السفر",

                        isRequired: true,
                        errorLocation: "bottom",
                      },
                      {
                        type: "file",
                        name: "management_data_staff_personal_folder",
                        maxWidth: "30%",
                        title: "Dossier du personnel - ملف العون",

                        isRequired: true,
                        filePlaceholder:
                          "Téléchargez le dossier du personnel auprès de l'établissement\nتحميل ملف العون لدى المؤسسة",
                        startWithNewLine: false,
                        storeDataAsText: false,
                      },
                      {
                        type: "file",
                        name: "management_data_staff_cin_copy",
                        visibleIf:
                          "{panel.management_data_staff_nationality} = 'تونسية'",
                        maxWidth: "30%",
                        title:
                          "Copie de la CIN - نسخة من بطاقة التعريف الوطنية",

                        isRequired: true,
                        filePlaceholder:
                          "Téléchargez une copie de la CIN\nحمّل نسخة من بطاقة التعريف الوطنية",
                        startWithNewLine: false,
                        storeDataAsText: false,
                      },
                      {
                        type: "file",
                        name: "management_data_staff_passport_copy",
                        visibleIf:
                          "{panel.management_data_staff_nationality} <> 'تونسية'",
                        maxWidth: "30%",
                        startWithNewLine: false,
                        title: "Copie du passeport - نسخة من جواز السفر",

                        isRequired: true,
                        filePlaceholder:
                          "Téléchargez une copie du passeport\nحمل نسخة من جواز السفر",
                        storeDataAsText: false,
                      },
                    ],
                  },
                ],
                panelCount: 1,
                minPanelCount: 1,
                panelAddText:
                  "Ajouter un personnel d'encadrement - أضف عون التأطير",

                panelRemoveText: "Supprimer - إزالة",
              },
            ],
          },
        ],
      },
    ],
    triggers: [
      {
        type: "runexpression",
        expression: "{legal_entity_reservation_number_commercial} <> ''",
        runExpression:
          "getDemandeReservDenomination({legal_entity_reservation_number_commercial},'legal_entity_reservation_number_commercial')",
      },
      {
        type: "runexpression",
        expression: "{legal_entity_reservation_number_official} <> ''",
        runExpression:
          "getDemandeReservDenomination({legal_entity_reservation_number_official},'legal_entity_reservation_number_official')",
      },
      {
        type: "runexpression",
        expression: "{establishment_data_reservation_number_commercial} <> ''",
        runExpression:
          "getDemandeReservDenomination({establishment_data_reservation_number_commercial},'establishment_data_reservation_number_commercial')",
      },
      {
        type: "runexpression",
        expression: "{establishment_data_tax_number} <> ''",
        runExpression:
          "getExtraitRegistreEntrepriseParId({establishment_data_tax_number},'establishment_data_tax_number')",
      },
      {
        type: "runexpression",
        expression: "{legal_entity_tax_number} <> ''",
        runExpression:
          "getExtraitRegistreEntrepriseParId({legal_entity_tax_number},'legal_entity_tax_number')",
      },
      {
        type: "runexpression",
        expression: "{establishment_data_reservation_number} <> ''",
        runExpression:
          "getDemandeReservDenomination({establishment_data_reservation_number},establishment_data_reservation_number)",
      },
      {
        type: "runexpression",
        expression: "{establishment_director_data_identity} <> ''",
        runExpression:
          "checkDirectorIdentity({establishment_director_data_identity})",
      },

      {
        type: "runexpression",
        expression:
          "{establishment_director_data_cin_number} <> '' and {establishment_director_data_birth_date} <> '' and {establishment_director_data_nationality} = 'تونسية' and {establishment_director_data_identity} = 'مدير غير المستثمر'",
        runExpression:
          "checkDNCINDirector({establishment_director_data_cin_number},{establishment_director_data_birth_date})",
      },
    ],

    sendResultOnPageNext: true,
    showCompletedPage: false,
    showProgressBar: "aboveheader",
    progressBarShowPageTitles: true,
    storeOthersAsComment: false,
    pagePrevText: "Précédant - رجوع",

    pageNextText: "Suivant - متابعة",

    completeText: "Soumettre la demande - تقديم المطلب",
    previewText: "Aperçu - عرض",
    editText: "Modifier - تعديل",
    showPreviewBeforeComplete: "showAllQuestions",
  };

  const editJson = {
    logoPosition: "right",
    pages: [
      {
        name: "page0",
        title: "type de modification - نوع التعديل",
        elements: [
          {
            type: "panel",
            name: "modifictaionData",
            elements: [
              {
                type: "radiogroup",
                name: "type_modification",
                title: "type de modification - نوع التعديل",
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                renderAs: "div",
                choices: [
                  {
                    value: "avec_impact",
                    text: "Modifications affectant le bordereau (BO), nécessitant une validation obligatoire par la direction régionale - تعديلات تؤثر على وصل الإيداع وتتطلب موافقة إلزامية من الإدارة الجهوية.",
                  },
                  {
                    value: "sans_impact",
                    text: "Modifications sans impact sur le bordereau (BO), ne nécessitant pas de validation de la direction régionale - تعديلات لا تؤثر على وصل الإيداع ولا تتطلب موافقة من الإدارة الجهوية.",
                  },
                ],
                choicesOrder: "asc",
                colCount: 2,
              },
            ],
          },
        ],
      },

      {
        name: "page1",
        title: "Données de l'investisseur - معطيات المستثمر ",
        elements: [
          {
            type: "panel",
            name: "InvestisseurData",
            elements: [
              {
                type: "radiogroup",
                name: "inverstor_type",
                title: "Type de l'établissement - الصبغة القانونية",
                hideNumber: true,
                defaultValue: "Personne morale",
                isRequired: true,
                errorLocation: "bottom",
                renderAs: "div",
                choices: [
                  {
                    value: "Personne physique",
                    text: "Personne physique - شخص طبيعي",
                  },
                  {
                    value: "Personne morale",
                    text: "Personne morale - شخص معنوي",
                  },
                ],
                choicesOrder: "asc",
                colCount: 3,
                visibleIf: "{type_modification} = 'avec_impact'",
              },
              {
                type: "datepicker",
                name: "investor_date_delivrance",
                visibleIf: "{type_modification} = 'sans_impact'",
                title:
                  "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية ",
                max: new Date().toISOString().split("T")[0],
                errorLocation: "bottom",
                hideNumber: true,
                startWithNewLine: false,
                isRequired: true,
                dateFormat: "dd/mm/yy",
                placeholder: {
                  default: "DD/MM/YYYY",
                  ar: "اليوم / الشهر / السنة",
                },
              },
              {
                type: "dropdown",
                visibleIf: "{type_modification} = 'sans_impact'",
                name: "investor_residence_governorate",
                title: "Gouvernorat de résidence - ولاية الإقامة",
                choices: gouvernoratList,
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                startWithNewLine: true,
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "investor_residence_delegation",
                visibleIf: "{type_modification} = 'sans_impact'",
                startWithNewLine: false,
                title: "Délégation de résidence - معتمدية الإقامة",
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "text",
                name: "investor_postal_code",
                title: "Code postal - الترقيم البريدي",
                visibleIf: "{type_modification} = 'sans_impact'",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                maskType: "pattern",
                maskSettings: {
                  pattern: "9999",
                },
              },
              {
                type: "text",
                name: "investor_phone_number",
                startWithNewLine: false,
                title: "Numéro de téléphone - رقم الهاتف",
                visibleIf: "{type_modification} = 'sans_impact'",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                readOnly: true,
                maskType: "pattern",
                maskSettings: {
                  pattern: "+216 99 999 999",
                },
              },
            ],
          },
        ],
      },
      {
        name: "page2",
        title: "Données de l’établissement de formation - معطيات مؤسسة التكوين",
        visibleIf: "{type_modification} = 'avec_impact'",
        elements: [
          {
            type: "panel",
            name: "panel1",
            elements: [
              {
                type: "text",
                name: "establishment_data_official_name",
                visibleIf: "{inverstor_type} = 'Personne morale'",
                title: "Dénomination officielle - التسمية الرسمية",
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_data_commercial_name",
                visibleIf: "{inverstor_type} <> 'Personne morale'",
                startWithNewLine: false,
                title: "Dénomination commerciale - التسمية التجارية",
                hideNumber: true,
                defaultValueExpression: "{legal_entity_commercial_name}",
                errorLocation: "bottom",
                readOnly: true,
              },
              {
                type: "text",
                name: "establishment_data_address",
                startWithNewLine: false,
                title:
                  "Adresse de l’établissement (En Arabe) - عنوان المؤسسة (بالعربية)",
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                  },
                ],
              },
              {
                type: "dropdown",
                name: "establishment_data_training_type",
                title: "Nature de formation - طبيعة التكوين",
                hideNumber: true,
                isRequired: true,
                choices: [
                  {
                    value: "أولية",
                    text: "Initiale - أولية",
                  },
                  {
                    value: "مستمرة",
                    text: "Continue - مستمرة",
                  },
                  {
                    value: "أولية ومستمرة",
                    text: "Initiale et continue - أولية ومستمرة",
                  },
                ],
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "establishment_data_governorate",
                startWithNewLine: false,
                title: "Gouvernorat - الولاية",
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                readOnly: true,
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "establishment_data_delegation",
                title: "Délégation - المعتمدية",
                hideNumber: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "text",
                name: "establishment_data_postal_code",
                startWithNewLine: false,
                title: "Code postal - الترقيم البريدي",
                hideNumber: true,
                isRequired: true,
                maskType: "pattern",
                maskSettings: {
                  pattern: "9999",
                },
              },
            ],
          },
        ],
      },
      {
        name: "page4",
        title: "Données sur la nature de formation - بيانات حول طبيعة التكوين",
        visibleIf: "{type_modification} = 'avec_impact'",
        elements: [
          {
            type: "panel",
            name: "panel_training",
            elements: [
              {
                type: "paneldynamic",
                name: "training_nature_data",
                title:
                  "Données sur la nature de formation - بيانات حول طبيعة التكوين",

                hideNumber: true,
                // isRequired: true,
                templateElements: [
                  {
                    type: "panel",
                    name: "training_type_data",
                    title: "Formation - تكوين {panelIndex}",

                    state: "expanded",
                    elements: [
                      {
                        type: "dropdown",
                        name: "training_type_data_nature",
                        title: "Nature de la formation - طبيعة التكوين",

                        hideNumber: true,
                        enableIf:
                          "{establishment_data_training_type} = 'أولية ومستمرة'",
                        defaultValueExpression:
                          "{establishment_data_training_type}",
                        isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "أولية",
                            text: "Initiale - أولية",
                          },
                          {
                            value: "مستمرة",
                            text: "Continue - مستمرة",
                          },
                        ],
                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_type",
                        visibleIf:
                          "{panel.training_type_data_nature} = 'أولية'",
                        startWithNewLine: false,
                        title: "Type - النوع",

                        // isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "منظرة",
                            text: "Homologuée - منظرة",
                          },
                          {
                            value: "غير منظرة",
                            text: "Non homologuée - غير منظرة",
                          },
                        ],
                        placeholder: "",
                        allowClear: false,
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_sector",
                        startWithNewLine: false,
                        title: "Secteur - القطاع",

                        // isRequired: true,
                        errorLocation: "bottom",
                        choices: secteurData,
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_sub_sector",
                        startWithNewLine: true,
                        title: "Sous-secteur - القطاع الفرعي",

                        // isRequired: true,
                        errorLocation: "bottom",
                        choices: [],
                      },
                      {
                        type: "text",
                        name: "training_type_data_domain",
                        visibleIf:
                          "({panel.training_type_data_type} = 'غير منظرة') or ({panel.training_type_data_nature} = 'مستمرة')",
                        startWithNewLine: false,
                        title: "Domaine (En arabe) - المجال (بالعربية)",

                        hideNumber: true,
                        // isRequired: true,
                        errorLocation: "bottom",
                        validators: [
                          {
                            type: "regex",
                            text: {
                              default:
                                "Veuillez entrer uniquement des lettres arabes.",
                              ar: "يرجى إدخال أحرف عربية فقط.",
                            },
                            regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                          },
                        ],
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_specialty",
                        visibleIf: "{panel.training_type_data_type} = 'منظرة'",
                        title: "Spécialité - الإختصاص",

                        // isRequired: true,
                        errorLocation: "bottom",
                        choices: [],
                        allowClear: false,
                        startWithNewLine: false,
                      },
                      {
                        type: "text",
                        name: "training_type_data_diploma",
                        visibleIf: "{panel.training_type_data_type} = 'منظرة'",
                        startWithNewLine: true,
                        title: "Diplôme délivré - الشهادة المسلمة ",

                        // isRequired: true,
                        errorLocation: "bottom",
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_required_level",
                        visibleIf: "{panel.training_type_data_type} = 'منظرة'",
                        startWithNewLine: false,
                        title: "Niveau requis - المستوى الادنى المطلوب ",

                        // isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "شهادة المهارة (CC)",
                            text: "Certificat de compétence (CC) - شهادة المهارة",
                          },
                          {
                            value: "شهادة الكفاءة المهنية (CAP)",
                            text: "Certificat d'Aptitude Professionnelle (CAP) - شهادة الكفاءة المهنية",
                          },
                          {
                            value: "شهادة مؤهل التقني المهني",
                            text: "Brevet du Technicien Professionnel (BTP) - شهادة مؤهل التقني المهني",
                          },
                        ],
                      },
                      {
                        type: "text",
                        name: "training_type_data_duration_hour",
                        title: "Durée en (H) - المدة بالساعات (س)",

                        // isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                        startWithNewLine: true,
                      },
                      {
                        type: "text",
                        name: "training_type_data_duration_month",
                        startWithNewLine: false,
                        title: "Durée en mois - المدة بالاشهر",

                        // isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                      },
                      {
                        type: "text",
                        name: "training_type_data_duration_minimum",
                        visibleIf:
                          "({panel.training_type_data_nature} != 'مستمرة') and ({panel.training_type_data_type} != 'غير منظرة')",
                        startWithNewLine: false,
                        title: "Durée minimale - المدة الدنيا",

                        // isRequired: true,
                        errorLocation: "bottom",
                        inputType: "number",
                        min: 0,
                      },
                      {
                        type: "dropdown",
                        name: "training_type_data_mode",

                        visibleIf:
                          "({panel.training_type_data_type} = 'غير منظرة' and {panel.training_type_data_nature} = 'أولية') or {panel.training_type_data_nature} = 'مستمرة'",
                        maxWidth: "50%",
                        startWithNewLine: false,
                        title: "Mode de formation - نمط التكوين",

                        // isRequired: true,
                        errorLocation: "bottom",
                        choices: [
                          {
                            value: "حضوري",
                            text: "Présentielle - حضوري",
                          },
                          {
                            value: "عن بعد",
                            text: "À distance - عن بعد",
                          },
                          {
                            value: "بالتداول",
                            text: "En alternance - بالتداول",
                          },
                        ],
                        placeholder: "",
                        allowClear: false,
                        searchEnabled: false,
                      },
                    ],
                  },
                ],
                allowAddPanel: false,
                allowRemovePanel: false,

                // panelCount: 1,
                // minPanelCount: 1,
                // panelAddText: "Ajouter une formation - إضافة تكوين",

                // panelRemoveText: "Supprimer - إزالة",
              },
            ],
          },
        ],
      },
      {
        name: "Données de Directeur de l’Établissement",
        title: "Données de Directeur de l’Établissement - بيانات مدير المؤسسة",
        visibleIf: "{type_modification} = 'avec_impact'",
        elements: [
          {
            type: "panel",
            name: "panel2",
            elements: [
              {
                type: "dropdown",
                name: "establishment_director_data_identity",
                title: "Identité - الهوية",

                hideNumber: true,
                isRequired: true,
                choices: [
                  {
                    value: "مدير غير المستثمر",
                    text: "Directeur Autre que l’Investisseur - مدير غير المستثمر",
                  },
                  {
                    value: "المستثمر نفسه",
                    text: "Investisseur lui-même - المستثمر نفسه",
                  },
                ],
              },
              {
                type: "dropdown",
                name: "establishment_director_data_nationality",
                startWithNewLine: false,
                title: "Nationalité - الجنسية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: nationalityList.map((e) => ({
                  value: e.value,
                  text: e.label,
                })),
                placeholder: "",
                allowClear: false,
                searchEnabled: true,
              },

              {
                type: "text",
                name: "establishment_director_data_cin_number",
                visibleIf:
                  "{establishment_director_data_nationality} = 'تونسية'",
                startWithNewLine: false,
                title: "Numéro de la CIN - رقم بطاقة التعريف الوطنية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                maskType: "pattern",
                maskSettings: {
                  pattern: "99999999",
                },
              },
              {
                type: "datepicker",
                name: "establishment_director_data_birth_date",
                startWithNewLine: true,
                title: "Date de naissance - تاريخ الولادة",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                max: new Date(
                  new Date().setFullYear(new Date().getFullYear() - 18)
                )
                  .toISOString()
                  .split("T")[0],
                dateFormat: "dd/mm/yy",
                placeholder: {
                  default: "DD/MM/YYYY",
                  ar: "اليوم / الشهر / السنة",
                },
              },
              {
                type: "text",
                name: "establishment_director_data_last_name",
                startWithNewLine: false,
                title: "Nom (En arabe) - اللقب (بالعربية)",
                hideNumber: true,
                isRequired: true,
                enableIf: "{establishment_director_data_last_name} empty",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "text",
                name: "establishment_director_data_first_name",
                startWithNewLine: false,
                title: "Prénom (En arabe) - الإسم (بالعربية)",

                hideNumber: true,
                isRequired: true,
                enableIf: "{establishment_director_data_first_name} empty",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "dropdown",
                name: "establishment_director_data_genre",
                title: "Genre - الجنس",

                hideNumber: true,
                isRequired: true,
                choices: [
                  {
                    value: "ذكر",
                    text: "Masculin - ذكر",
                  },
                  {
                    value: "أنثى",
                    text: "Féminin - أنثى",
                  },
                ],
                allowClear: false,
                enableIf: "{establishment_director_data_first_name} empty",
              },

              {
                type: "text",
                name: "establishment_director_data_birth_place",
                title: "Lieu de naissance (En arabe) - مكان الولادة (بالعربية)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                startWithNewLine: false,
                enableIf: "{establishment_director_data_birth_place} empty",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "datepicker",
                name: "establishment_director_data_ministerial_auth_date",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'تونسية'",
                startWithNewLine: false,
                title: "Date d’autorisation de ministre - تاريخ ترخيص الوزير",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",

                max: new Date().toISOString().split("T")[0],
                dateFormat: "dd/mm/yy",
                placeholder: {
                  default: "DD/MM/YYYY",
                  ar: "اليوم / الشهر / السنة",
                },
              },

              {
                type: "datepicker",
                name: "establishment_director_data_date_delivrance",
                visibleIf:
                  "{establishment_director_data_nationality} = 'تونسية'",
                startWithNewLine: false,
                title:
                  "Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية",
                max: new Date().toISOString().split("T")[0],
                hideNumber: true,
                isRequired: true,
                dateFormat: "dd/mm/yy",
                placeholder: {
                  default: "DD/MM/YYYY",
                  ar: "اليوم / الشهر / السنة",
                },
              },
              {
                type: "text",
                name: "establishment_director_data_passeport",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'تونسية'",
                startWithNewLine: false,
                title: "Numéro du passeport - رقم جواز السفر",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
              },
              {
                type: "text",
                name: "establishment_director_data_address",
                title: "Adresse (En arabe) - العنوان (بالعربية)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                validators: [
                  {
                    type: "regex",
                    text: {
                      default: "Veuillez entrer uniquement des lettres arabes.",
                      ar: "يرجى إدخال أحرف عربية فقط.",
                    },
                    regex: /^[\u0600-\u06FF\s0-9\W]+$/,
                  },
                ],
              },
              {
                type: "dropdown",
                name: "establishment_director_data_governorate",
                startWithNewLine: false,
                title: "Gouvernorat - الولاية",
                choices: gouvernoratList,
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "dropdown",
                name: "establishment_director_data_delegation",
                startWithNewLine: false,
                title: "Délégation - المعتمدية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                placeholder: "",
                allowClear: false,
              },
              {
                type: "text",
                name: "establishment_director_data_email",
                title: "Adresse e-mail - البريد الإلكتروني",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "email",
              },
              {
                type: "text",
                name: "establishment_director_data_phone_number",
                maxWidth: "50%",
                startWithNewLine: false,
                title: "Numéro de téléphone - رقم الهاتف",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                maskType: "pattern",
                maskSettings: {
                  pattern: "+216 99 999 999",
                },
              },

              {
                type: "dropdown",
                name: "establishment_director_data_education_level",
                title: "Niveau scolaire - المستوى الدراسي",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "إبتدائي",
                    text: "Primaire - إبتدائي",
                  },
                  {
                    value: "ثانوي",
                    text: "Secondaire - ثانوي",
                  },
                  {
                    value: "جامعي",
                    text: "Universitaire - جامعي",
                  },
                  {
                    value: "تكوين مهني",
                    text: "Formation professionnelle - تكوين مهني",
                  },
                ],
                placeholder: "",
                allowClear: false,
                searchEnabled: false,
                startWithNewLine: false,
              },

              {
                type: "text",
                name: "establishment_director_data_experience_years",
                startWithNewLine: true,
                title:
                  "Nombre d'années d'expériences professionnelles -  عدد سنوات الخبرة المهنية",
                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                inputType: "number",
                min: 0,
              },
              {
                type: "radiogroup",
                name: "establishment_director_data_specific_needs",
                startWithNewLine: false,
                title: "Personne à besoin spécifique - شخص ذو إحتياجات خصوصية ",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                choices: [
                  {
                    value: "نعم",
                    text: "Oui - نعم",
                  },
                  {
                    value: "لا",
                    text: "Non - لا",
                  },
                ],
                colCount: 4,
              },
              {
                type: "file",
                name: "establishment_director_data_director_folder",
                maxWidth: "10%",
                title:
                  "Dossier du directeur (CV et autres) - ملف المدير (السيرة الذاتية وغيرها)",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                allowMultiple: true,
                fileOrPhotoPlaceholder:
                  "Cliquez ou glissez pour télécharger le CV et le dossier du directeur\nانقر أو اسحب لتحميل السيرة الذاتية وملف المدير",
                photoPlaceholder: "",
                filePlaceholder:
                  "Cliquez ou glissez pour télécharger le CV et le dossier du directeur\nانقر أو اسحب لتحميل السيرة الذاتية وملف المدير",
                storeDataAsText: false,
              },
              {
                type: "file",
                name: "establishment_director_data_cin_copie",
                visibleIf:
                  "{establishment_director_data_nationality} = 'تونسية'",
                maxWidth: "10%",
                startWithNewLine: false,
                title: "Copie de la CIN - نسخة من بطاقة التعريف الوطنية",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                allowMultiple: true,
                filePlaceholder:
                  "Télécharger la copie du CIN.\nحمّل نسخة من بطاقة التعريف الوطنية",
                storeDataAsText: false,
              },
              {
                type: "file",
                name: "question15",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'تونسية'",
                maxWidth: "10%",
                startWithNewLine: false,
                title:
                  "Copie de l’autorisation du ministre -  نسخة من ترخيص الوزير",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                filePlaceholder:
                  "Télécharger une copie de l'autorisation du ministre\nحمل نسخة من ترخيص الوزير",
                storeDataAsText: false,
              },
              {
                type: "file",
                name: "establishment_director_data_passeport_copie",
                visibleIf:
                  "{establishment_director_data_nationality} <> 'تونسية'",
                maxWidth: "10%",
                startWithNewLine: false,
                title: "Copie du passeport - نسخة من جواز السفر",

                hideNumber: true,
                isRequired: true,
                errorLocation: "bottom",
                allowMultiple: true,
                filePlaceholder:
                  "Télécharger la copie du passeport.\nحمل نسخة من جواز السفر",
                storeDataAsText: false,
              },
            ],
          },
        ],
      },
    ],
    triggers: [
      {
        type: "runexpression",
        expression: "{legal_entity_reservation_number_commercial} <> ''",
        runExpression:
          "getDemandeReservDenomination({legal_entity_reservation_number_commercial},'legal_entity_reservation_number_commercial')",
      },
      {
        type: "runexpression",
        expression: "{legal_entity_reservation_number_official} <> ''",
        runExpression:
          "getDemandeReservDenomination({legal_entity_reservation_number_official},'legal_entity_reservation_number_official')",
      },
      {
        type: "runexpression",
        expression: "{establishment_data_reservation_number_commercial} <> ''",
        runExpression:
          "getDemandeReservDenomination({establishment_data_reservation_number_commercial},'establishment_data_reservation_number_commercial')",
      },
      {
        type: "runexpression",
        expression: "{establishment_data_tax_number} <> ''",
        runExpression:
          "getExtraitRegistreEntrepriseParId({establishment_data_tax_number},'establishment_data_tax_number')",
      },
      {
        type: "runexpression",
        expression: "{legal_entity_tax_number} <> ''",
        runExpression:
          "getExtraitRegistreEntrepriseParId({legal_entity_tax_number},'legal_entity_tax_number')",
      },
      {
        type: "runexpression",
        expression: "{establishment_data_reservation_number} <> ''",
        runExpression:
          "getDemandeReservDenomination({establishment_data_reservation_number},establishment_data_reservation_number)",
      },
      {
        type: "runexpression",
        expression: "{establishment_director_data_identity} <> ''",
        runExpression:
          "checkDirectorIdentity({establishment_director_data_identity})",
      },

      {
        type: "runexpression",
        expression:
          "{establishment_director_data_cin_number} <> '' and {establishment_director_data_birth_date} <> '' and {establishment_director_data_nationality} = 'تونسية' and {establishment_director_data_identity} = 'مدير غير المستثمر'",
        runExpression:
          "checkDNCINDirector({establishment_director_data_cin_number},{establishment_director_data_birth_date})",
      },
    ],

    sendResultOnPageNext: true,
    showCompletedPage: false,
    showProgressBar: "aboveheader",
    progressBarShowPageTitles: true,
    storeOthersAsComment: false,
    pagePrevText: "Précédant - رجوع",

    pageNextText: "Suivant - متابعة",

    completeText: "Soumettre la demande - تقديم المطلب",
    previewText: "Aperçu - عرض",
    editText: "Modifier - تعديل",
    showPreviewBeforeComplete: "showAllQuestions",
  };

  const survey = new Model(surveyJson);
  const surveyEdit = new Model(editJson);
  survey.onUploadFiles.add((_, options) => {
    const formData = new FormData();
    const id_affaire = sessionStorage.getItem("id_affaire");

    options.files.forEach((file) => {
      formData.append("id_affaire", id_affaire);
      formData.append("id_type_doc", "006f52e9102a8d3be2fe5614f42ba989");
      formData.append("name_doc", options.name);
      formData.append("file[]", file);
    });

    uploadDocumentAffaire(formData)
      .then((data) => {
        console.log(data);

        options.callback(
          options.files.map((file) => {
            return {
              file: file,
              content: data.data.path[0],
            };
          })
        );
      })
      .catch((error) => {
        console.error("File upload error: ", error);
        options.callback([], ["An error occurred during file upload."]);
      });
  });

  survey.currentPageNo = parseInt(sessionStorage.getItem("surveyStep"));

  return (
    <StrictMode>
      <AppContext>
        <BrowserRouter>
          <Routes>
            {/* {!token ? (
            <> */}
            <Route
              path="/signin"
              element={<SignIn setToken={setToken} setRole={setRole} />}
            />
            <Route
              path="/signup"
              element={
                <SignUp
                  nationalityOptions={nationalityOptions}
                  gouvernoratOptions={gouvernoratOptions}
                />
              }
            />
            <Route path="*" element={<Navigate to="/signin" />} />
            {/* </> */}
            {role !== "formateur" ? (
              <Route element={<Main survey={survey} />}>
                <Route path="/dashboard" element={<Home />} />
                <Route
                  path="/demandes"
                  element={<Demandes survey={survey} />}
                />
                <Route path="/formateurs" element={<Rtl />} />
                <Route path="/etablissements" element={<Etablissements />} />
                <Route
                  path="/brouillon"
                  element={<Brouillons survey={survey} />}
                />
                <Route
                  path="/etablissements/save"
                  element={<CreateEpf survey={survey} />}
                />
                <Route
                  path="/etablissements/edit"
                  element={<EditEpf surveyEdit={surveyEdit} />}
                />
                <Route
                  path="/etablissements/save/:id"
                  element={<CreateEpf />}
                />
                <Route
                  path="/etablissements/view/:id"
                  element={<CreateEpf />}
                />
                <Route path="/pdf/viewer" element={<PdfViewer />} />
                <Route path="*" element={<Navigate to="/demandes" />} />
              </Route>
            ) : (
              <Route element={<Main />}>
                <Route
                  path="/profile"
                  element={
                    <Profile
                      nationalityOptions={nationalityOptions}
                      gouvernoratOptions={gouvernoratOptions}
                    />
                  }
                />
                <Route path="/acceuil" element={<Home />} />
                <Route path="*" element={<Navigate to="/profile" />} />
              </Route>
            )}
          </Routes>
        </BrowserRouter>
      </AppContext>
    </StrictMode>
  );
}

export default App;
